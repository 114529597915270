import moment from "moment";
import { getBuildingRequest, getBuildingRequestLM, homeownerDontShowIntro, uploadEnerGuidePDF, deleteEnerGuidePDF } from "../../actions/buildings";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { homeownerAction, homeownerViewedReport } from '../../actions/buildings';
import { setCurrentBuilding } from "../../actions/auth";
import { withTranslation, Trans } from "react-i18next";
import i18next from 'i18next';
import Themes from "./Themes";
import "./Themes.scss";
import "./CustomerReport.scss";
import CDropdown from "./components/CDropdown";
import CStatBlock from "./components/CStatBlock";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import CNotifBubble from "./components/CNotifBubble";
import FocusLock from 'react-focus-lock';
import CHomeRatingCard from "./components/CHomeRatingCard";
import CRetrofitScenariosCard from "./components/CRetrofitScenariosCard";
import CFAQSection from "./components/CFAQSection";
import Dropzone from 'react-dropzone';
import CRatingBubble from "./components/CRatingBubble";
import { Tooltip } from 'react-tooltip'
import axios from 'axios';
import { toast } from "react-toastify";
import RoleBadge from "../Common/Modules/RoleBadge";
import AssessmentLevel from "../Common/Modules/AssessmentLevel";

const helpers = require('../../helpers/utils');
const ParseFloat2 = helpers.ParseFloat2;
const { listsData } = require('../../helpers/audit-enums');
const AuditStatus = require('../../helpers/audit-status');

/////

let STATUSES, statusEqual, statusGt, statusGtEqual, THEME, ASYNC_STATUSES, ENERGUIDE_REPORT_STATUSES;
// let GRAPH_COLORS = [
// 	"#26C6DA", "#112E51", "#FF7043", "#78909C",
// 	"#2E78D2", "#853A22", "#FFBEA9", "#004851",
// 	"#00BED6", "#205493", "#6DA1ED", "#EAC36F",
// 	"#B51963"
// ];
let GRAPH_COLORS = [
	"#00A0B4", "#112E51", "#F36437", "#78909C",
	"#2E78D2", "#853A22", "#C3826D", "#004851",
	"#009EC4", "#205493", "#5F93DF", "#B38C38",
	"#B51963"
];

const Fragment = React.Fragment;

const FormatDollars = (v) => {
	v = Math.round(v * 100) / 100;
	return v < 0 ?
	  `-$${Math.abs(v)}` :
	  `$${v}`;
};

const RoundCost = (v) => {
	return isNaN(v)?
	  undefined :
	  v < 1000 ?
	  Math.round(v / 100) * 100:
	  Math.round(v / 500) * 500;
};

//

class CustomerReportPage extends PureComponent {
	constructor(props) {
		super(props);

		this.T = props.t;

		this.state = {
			rand: 0,
			buildingInfoLoaded: false,
			modalOpen: null,
			modalY: 0,
			showFloatingCTA: true,
			homeRatingTab: 2,
			upgradePackageSelected: this.props.type2 ? 'energy_advisor_bundle' : 'Best_Package',
			areaSelected: "fsa",
			packageRatingTab: 2,
			introStep: 1,
			shareLevel: 1,
			mpTabSel: 1,
			vcbShow: false,
			rsDropdownOptions: []
		};

		this.modalTitleRef = {};
		this.modalCloseClick = this.modalCloseClick.bind(this);
		this.modalOpenClick = this.modalOpenClick.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.ctaClick = this.ctaClick.bind(this);

		THEME = this.theme = Themes.autodetect();

		const statObj  = AuditStatus(this.theme);
		STATUSES       = statObj.STATUSES;
		statusEqual    = statObj.statusEqual;
		statusGt       = statObj.statusGt;
		statusGtEqual  = statObj.statusGtEqual;
		ASYNC_STATUSES = statObj.ASYNC_STATUSES;
		ENERGUIDE_REPORT_STATUSES = statObj.ENERGUIDE_REPORT_STATUSES;

		this.ratings = listsData.homeRatings;

		this.updateDDOptions();

		this.onDropEnerGuide = this.onDropEnerGuide.bind(this);
		this.dzEnerGuideRef = React.createRef();
	}

	static propTypes = {
		building: PropTypes.object,
	};

	static defaultProps = {
		building: {},
	};

	updateDDOptions() {

		if (!this.state.rsDropdownOptions.find((e) => e.key === this.state.upgradePackageSelected)) {
			if (this.state.rsDropdownOptions.length > 0) {
				if (THEME.allowCustomRetrofitScenarios) {
					this.setState({ upgradePackageSelected: this.state.rsDropdownOptions[this.state.rsDropdownOptions.length-2].key });
				}
				else {
					this.setState({ upgradePackageSelected: this.state.rsDropdownOptions[this.state.rsDropdownOptions.length-1].key });
				}
			}
		}
		
		this.areaDropdownOptions = [
			{ key: "fsa", title: this.T('Neighborhood') },
			{ key: "city", title: this.T('Municipality') },
			{ key: "province", title: this.T('Province') }
		];

		this.upgradesMetricsOptionsFinancial = [
			{ key: "bill-savings-first-year", title: this.T('Energy Bill Savings (1st Year)' )},
			{ key: "impact-10-year", title: this.T('10-Yr Financial Impact' )},
			{ key: "impact-20-year", title: this.T('20-Yr Financial Impact' )},
			{ key: "est-cost-after-rebates", title: this.T('Estimated Costs after Rebates') },
			{ key: "est-cost", title: this.T('Estimated Costs') },
			{ key: "est-rebates", title: this.T('Estimated Rebates') }
			
		];
	}

	getRInfo() {
		if (this.props.type2) {
			return this.props.modelType2 && this.props.modelType2.__report_info;
		} else {
			return this.props.modelType1 && this.props.modelType1.__report_info;
		}
	}

	getModel() {
		if (this.props.type2) {
			return this.props.modelType2;
		} else {
			return this.props.modelType1;
		}
	}

	getRInfoPackage() {
		let rinfo = this.getRInfo();
		if (!rinfo) {
			return undefined;
		}
		return rinfo.bundle_recommendations[this.state.upgradePackageSelected] ?? rinfo.bundle_recommendations["Best_Package"];
	}

	handleScroll(event) {
		if (this.props.isPreview) {
			return;
		}
		let html = document.documentElement,
			cont = document.getElementById('ph-header-cont');
		let height = Math.max(cont.scrollHeight, cont.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
		let vpWidth = window.innerWidth;
		this.setState({
			showFloatingCTA: vpWidth <= 800 ? cont.scrollTop + window.innerHeight < height - 73 : cont.scrollTop + window.innerHeight < height - 73,
		});
	}

	recordView() {
		setTimeout(() => {
			if (!this.reportViewed && !helpers.buildingIsMock(this.props.building?.buildingId)) {
				window.VIEWED_REPORT = true;
				this.props.dispatch(homeownerViewedReport(this.props.building.buildingId));
				this.reportViewed = true;
			}
		}, 1);
	}

	componentDidMount() {
		Highcharts.setOptions({colors: GRAPH_COLORS});

		if (document.getElementById('ph-header-cont')) {
			document.getElementById('ph-header-cont').addEventListener("scroll", this.handleScroll);
		}

		this.recordView();
	}

	componentWillUnmount() {
		if (document.getElementById('ph-header-cont')) {
			document.getElementById('ph-header-cont').removeEventListener("scroll", this.handleScroll);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.buildingLoadError && !prevProps.buildingLoadError) {
			if (helpers.getCurrentPage() === 'report') {
				toast.error(this.T("Unable to display report, please try again later."));
				window.gotoPage('map');
			}
		}
		if (this.props.buildingLoading !== prevProps.buildingLoading) {
			this.setState({ rand: Math.random() });
		}

		if (this.props.hideFinancialData && this.state.mpTabSel === 1) {
			this.setState({ mpTabSel: 2 });
		}

		if (!prevProps.show && this.props.show) {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
			const el = document.getElementById('ph-header-cont');
			if (el) {
				el.scrollTop = 0;
			}
		}

		if ((this.props.vendorUseDefault || !this.props.vendorName) && THEME.defaultVendor) {
			this.vendorOverride = THEME.defaultVendor;
			if (this.state.vendorName !== this.vendorOverride.vendorName) {
				this.setState({ vendorName: this.vendorOverride.vendorName });
			}
		}
		else {
			if (this.state.vendorName !== this.props.vendorName) {
				this.setState({ vendorName: this.props.vendorName });
			}
		}
		
		if (this.state.shareLevel !== this.props.shareLevel) {
			this.setState({ shareLevel: this.props.shareLevel })
		}
		if (this.props.building && Object.keys(this.props.building||{}).length > 0 && !this.state.buildingInfoLoaded) {
			this.setState({
				buildingInfoLoaded: true,
				dontShowIntroAgain: !!this.props.currentUser?.introModalHidden,
				rand: Math.random(),
				modalOpen: !THEME.advisorExperience && !this.props.currentUser?.introModalHidden && localStorage.getItem('crp-intro-show') !== 'no' && sessionStorage.getItem('crp-intro-hidden') !== 'yes' ? 'intro-modal' : null
			});
			//localStorage.setItem('crp-intro-show', 'no');
			sessionStorage.setItem('crp-intro-hidden', 'yes');
			if (helpers.getCurrentPage() === 'report' && THEME.advisorExperience && !this.props.type2 && statusGtEqual(this.props.status, STATUSES.UPG_UPGRADES_COMPLETED) && !this.props.isPreview) {
				window.gotoPage('dashboard');
			}
		}
		
		//this.recordView();
		this.updateDDOptions();
	}

	modalCloseClick(stateName) {
		return () => {
			const element = document.getElementById(stateName+='-trigger');
			setTimeout(()=>{
				element?.focus()
			}, 300);
			this.modalJustOpened = false;
			this.setState({
				modalOpen: null,
				rand: Math.random(),
			});
		};
	}

	modalOpenClick(stateName) {

		if (!this.modalTitleRef[stateName]) {
			this.modalTitleRef[stateName] = React.createRef();
		}

		return () => {
			this.modalJustOpened = true;
			setTimeout(()=>{
				if (this.modalJustOpened && this.modalTitleRef[stateName] && this.modalTitleRef[stateName].current) {
					document.getElementById('crp-modal-close')?.focus();
					this.modalJustOpened = false;
				}
			}, 100);
			this.setState({
				faqExpand: null,
				modalOpen: stateName,
				rand: Math.random(),
				modalY: this.props.isPreview ? document.getElementById('report-preview-cont').scrollTop : document.documentElement.scrollTop,
				genCommentsShow: false,
				recCommentsShow: []
			});
		};
	}

	renderModal(stateName, title, contentFn) {
		return (
			<div
				className="crp-modal-cont"
				style={this.state.modalOpen === stateName ? { display: "block" } : { display: "none" }}
				//onClick={this.modalCloseClick(stateName)}
				aria-label={title + " Modal"}
				role="dialog"
				tabIndex="-1"
				aria-modal="true"
				aria-labelledby="crp-modal-title"
				aria-live="polite"
			>
				{this.state.modalOpen === stateName ? <FocusLock>
					<div
						className="crp-modal"
						style={{ top: this.state.modalY + "px" }}
						onClick={(e) => {
							(e || window.event).stopPropagation();
							return false;
						}}
					>
						<div ref={this.modalTitleRef[stateName]} className="crp-modal-header">
							<h2 className="crp-modal-title">{title}</h2>
							<button id="crp-modal-close" onClick={this.modalCloseClick(stateName)}><img alt="Close Modal" src="/images/x-icon-black.svg" /></button>
						</div>
						<div className="crp-modal-body">{contentFn()}</div>
					</div>
				</FocusLock> : undefined}
			</div>
		);
	}

	renderPackageMeter(isEmissions) {
		if (!this.getRInfoPackage()) {
			return <div />;
		}

		const levels = this.getRInfo().levels;
		const rec_totals = this.getRInfoPackage().levels;
		const ratingType = isEmissions ? 'ghg_per_m2_level' : 'gj_per_m2_level';

		const activeRating = rec_totals[ratingType].national_rating_placement||0;
		const curRating = levels[ratingType].national_rating_placement||0;
		let activeRatingVal = isEmissions ? rec_totals.ghg_per_m2 : rec_totals.gj_per_m2;
		let curRatingVal = isEmissions ? levels.ghg_per_m2 : levels.gj_per_m2;

		const areaM2 = levels?.total_heated_floor_area_sqft * 0.092903;
		if (isEmissions) {
			activeRatingVal /= 1000.;
			curRatingVal /= 1000.;
		}
		activeRatingVal *= areaM2;
		curRatingVal *= areaM2;

		let thisHomeMeter = 0, thisHomeValue = 0;
		if (isEmissions) {
			thisHomeMeter = levels?.reference_home_levels?.ghg_per_m2_level?.national_rating_placement||0;
			thisHomeValue = Math.round(10*(levels?.reference_home_levels?.ref_energy_use_ghg||0))/10;
			activeRatingVal = Math.round(activeRatingVal*10)/10;
			curRatingVal = Math.round(curRatingVal*10)/10;
		}
		else {
			thisHomeMeter = levels?.reference_home_levels?.gj_per_m2_level?.national_rating_placement||0;
			thisHomeValue = Math.round(levels?.reference_home_levels?.ref_energy_use_gj||0);
			activeRatingVal = Math.round(activeRatingVal);
			curRatingVal = Math.round(curRatingVal);
		}

		return (
			<div className="crp-package-rating-meter" id="crating-package-meter-container">
				<CRatingBubble width={this.T('hmp-lang-code') === 'FR' ? 90 : 75} height={70} top={0} left={0} style={activeRating < curRating ? "above-left" : "above-right"} meterContainerId={"crating-package-meter-container"} meterImgId={"crating-package-meter-img"} meterValue={Math.max(0, Math.min(100, activeRating))} backgroundColor={"#4A3DF0"} textClass="crating-bubble-above-left-text" zIndex={1}>
					{this.T('hmp-lang-code') === 'FR' ? 'Après' : 'After'}<br/>
					{this.T('hmp-lang-code') === 'FR' ? 'Rénovations' : 'Retrofits'}<br/>
					<span>{activeRatingVal}</span>
				</CRatingBubble>
				<CRatingBubble width={this.T('hmp-lang-code') === 'FR' ? 90 : 75} height={70} top={0} left={0} style={activeRating < curRating ? "above-right" : "above-left"} meterContainerId={"crating-package-meter-container"} meterImgId={"crating-package-meter-img"} meterValue={Math.max(0, Math.min(100, curRating))} backgroundColor={"#4A3DF0"} textClass="crating-bubble-above-right-text" zIndex={3}>
					{this.T('hmp-lang-code') === 'FR' ? 'Actuel' : 'Current'}<br/>
					<span>{curRatingVal}</span>
				</CRatingBubble>
				<div style={{height: "75px"}}></div>
				<div className="meter-label-left">{this.T("Uses Least Energy")}</div>
				<div className="meter-inner-cont">
					<img className="meter-img" alt={""} src={isEmissions ? '/images/home-rating-scale-env.svg' : '/images/home-rating-scale.svg'} id="crating-package-meter-img"/>
				</div>
				<div className="meter-label-right">{this.T("Uses Most Energy")}</div>
				<CRatingBubble width={this.T('hmp-lang-code') === 'FR' ? 175 : 150} height={55} top={75 + 50} left={0} notchLeft={35 + 25} style={"below"} backgroundColor={"#FFFFFF"} meterContainerId={"crating-package-meter-container"} meterImgId={"crating-package-meter-img"} meterValue={thisHomeMeter} textClass="crating-bubble-below-text">
					{this.T('hmp-lang-code') === 'FR' ? 'Maison Neuve Typique' : 'Typical New Home'}<br/>
					<span>{thisHomeValue}</span>
				</CRatingBubble>
				{isEmissions ? <div className="crating-units-text"><Trans i18nKey={"home-rating-units-env"}><img alt="" src="/images/info-black.svg" /> Numbers above are in tonnes of CO2e/year</Trans></div> : undefined}
                {!isEmissions ? <div className="crating-units-text"><Trans i18nKey={"home-rating-units-eng"}><img alt="" src="/images/info-black.svg" /> Numbers above are in GJ/year</Trans></div> : undefined}
			</div>
		);
	}

	renderComparison() {
		if (!this.getRInfo()) {
			return <div />;
		}

		//const aeu_gj = this.getRInfo().aeu_gj;
		//const avg_GJ = this.getRInfo().avg_GJ;
		const yearly_cost = this.getRInfo().yearly_cost;
		const avg_yearly_cost = this.getRInfo().avg_yearly_cost;

		const barPosPercent = (x) => Math.min(100, (x / (avg_yearly_cost * 2)) * 100) + "%";

		return (
			<div className="crp-card-cont" aria-label="Comparing your home to similar homes" aria-live="polite">
				<div className="crp-card">
					<div className="crp-comp-header">
						<div className="crp-ch-left">{this.T('Low Energy Use')}</div>
						<div className="crp-ch-right">{this.T('High Energy Use')}</div>
					</div>
					<div className="crp-comp-meter green">
						$
						<div className="crp-cm-bar-cont">
							<div className="crp-cm-bar green" style={{ width: barPosPercent(yearly_cost) }}></div>
							<div className="crp-cm-bubble-green">
								${Math.round(yearly_cost)}
								<div className="crp-cmb-arrow"></div>
							</div>
							<div className="crp-cm-label">{this.T('Your Home')}</div>
						</div>
						$$$
					</div>
					<div className="crp-comp-meter blue">
						$
						<div className="crp-cm-bar-cont">
							<div className="crp-cm-bar blue" style={{ width: barPosPercent(avg_yearly_cost) }}></div>
							<div className="crp-cm-bubble-blue">
								${Math.round(avg_yearly_cost)}
								<div className="crp-cmb-arrow"></div>
							</div>
							<div className="crp-cm-label">{this.T('Other Homes')}</div>
						</div>
						$$$
					</div>
				</div>
			</div>
		);
	}

	ctaClick() {

		if (this.props.showPRecCTA) {
			window.gotoPage('priorities');
			return;
		}
		else if (this.props.showSurveyCTA) {
			window.gotoPage('wizard');
			return;
		}
		else {
			const element = document.getElementById('helpful-links-main');
			const container = document.getElementById('ph-header-cont');
			const getOffset = (el) => {
				let top = 0;
				while (el) {
					top += el.offsetTop || 0;
					el = el.parentNode;
				}
				return top;
			}
			if (element && container) {
				const top = getOffset(element);
				const startTop = parseFloat(container.scrollTop);
				let idx = 0;
				const anim = () => {
					idx += 1;
					const container = document.getElementById('ph-header-cont');
					if (idx <= 15) {
						container.scrollTop = (top - startTop) * idx / 15 + startTop;
						setTimeout(anim, 1000/60);
					}
					else {
						container.scrollTop = top;
					}
				}
				setTimeout(anim, 1000/60);
			}
			return;
		}

	}

	renderPotentialSavings() {
    	let rec = this.getRInfoPackage().rec_totals || {};
		let descHTML = rec.summary_text;
		let titleHTML = rec.title_text;
		return (
			<div className="crp-card-cont" aria-label="Potential Savings" aria-live="polite">
				<div className="crp-card">
					<div className="crp-card-header-green" dangerouslySetInnerHTML={{ __html: titleHTML }}></div>
					<div className="crp-package-rating-tabs">
						<button onClick={() => this.setState({ packageRatingTab: 1 })} className={"crp-home-rating-button btn-left " + (this.state.packageRatingTab == 1 ? 'btn-selected' : '')}>
							<img alt="" src="/images/hmp-env-impact.svg"/>
							{this.T('Emissions Rating')}
						</button>
						<button onClick={() => this.setState({ packageRatingTab: 2 })} className={"crp-home-rating-button btn-middle " + (this.state.packageRatingTab == 2 ? 'btn-selected' : '')}>
							<img alt="" src="/images/hmp-energy-use.svg"/>
							{this.T('Energy Rating')}
						</button>
					</div>
					<div className="crp-rec-body">
						{/*this.renderPackageMeter()*/}
						<div className="crp-recommendation-text" dangerouslySetInnerHTML={{ __html: descHTML }}></div>
					</div>
					<button className="crp-read-more-button" onClick={this.modalOpenClick("potential_savings")}>
						<Trans i18nKey="crp-read-more">Read More <img alt="" src="/images/read-more-arrow.svg" /></Trans>
					</button>
				</div>
			</div>
		);
	}

	renderUpgrade(rec, index) {
		return (
			<div className="crp-card-cont" key={"crp-recommendation-" + index} aria-label={"Recommended upgrade: " + rec.name} aria-live="polite">
				<div className="crp-card">
					<div className="crp-card-header-blue">{rec.name}</div>
					<div className="crp-rec-body">
						<div className="crp-recommendation-text" dangerouslySetInnerHTML={{ __html: (rec.rec_text ? (rec.rec_text + "<br/><br/>") : "") + rec.save_text }}></div>
					</div>
					<button className="crp-read-more-button" onClick={this.modalOpenClick("upgrade_" + index)}>
						<Trans i18nKey="crp-read-more">Read More <img alt="" src="/images/read-more-arrow.svg" /></Trans>
					</button>
				</div>
			</div>
		);
	}

	renderRebateRows(rebate_list) {
		if (!Object.entries(rebate_list || {})?.length) {
			return (<div ></div>);
		}
		return (<Fragment>
			{Object.entries(rebate_list).map(([key, value]) => (
				<div className="crp-savings-row">
					<div className="crp-savings-title">{value.label} - {!this.props.type2 ? this.T('Average Rebates') : this.T('Estimated Rebates')}</div>
					<div className="crp-savings-value">{FormatDollars(RoundCost(value.value)) ?? <div className='crp-savings-unavailable-1'>{this.T('Unavailable')}</div>}</div>
				</div>
			))}
		</Fragment>)
	}

	getHLImage(img_id, default_url, img_url) {
		if (img_url) {
			return `/images/${img_url ?? default_url}`;
		}
		const mapping = {
			'on-5': 'helpful-links-3.jpg',
			'on-6': 'helpful-links-3.jpg',
			'on-7': 'helpful-links-3.jpg',
			'on-8': 'helpful-links-3.jpg',
			'on-9': 'helpful-links-3.jpg',
			'on-10': 'helpful-links-3.jpg',
			'on-11': 'helpful-links-4.jpg',
			'on-12': 'helpful-links-4.jpg',
			'on-13': 'helpful-links-4.jpg',
			'bc-5': 'helpful-links-bc-4.jpg',
			'bc-6': 'helpful-links-bc-5.jpg',
			'bc-7': 'helpful-links-bc-5.jpg',
			'bc-8': 'helpful-links-bc-5.jpg',
			'bc-9': 'helpful-links-bc-5.jpg',
			'bc-10': 'helpful-links-bc-6.jpg',
			'bc-11': 'helpful-links-bc-1.jpg',
			'bc-12': 'helpful-links-bc-1.jpg',
			'bc-13': 'helpful-links-bc-1.jpg'
		};
		return `/images/${mapping[img_id] ?? default_url}`;
	}

	packageHelpfulLink(rec) {
		if (rec.link_url) {
			return (
				<a className="crp-hlink" href={rec.link_url} target="_blank">
					<div style={{backgroundImage: "url('" + this.getHLImage(rec.link_image_id, 'helpful-links-4.jpg', rec?.link_image_url) + "')"}} className="crp-hl-banner"></div>
					<div id={`crp-hl-title-${rec.link_image_id}`} className="crp-hl-title">{rec.link_title}</div>
					<div id={`crp-hl-para-${rec.link_image_id}`} className="crp-hl-para">{rec.link_desc}</div>
				</a>
			);
		}
		else {
			return undefined;
		}
	}

	type2RecoHelpfulLink(link) {
		return (
			<a className="crp-hlink-small" href={link.url} target="_blank">
				<div style={{backgroundImage: "url('" + this.getHLImage(link.image_id, 'helpful-links-4.jpg', link.image_url) + "')"}} className="crp-hls-img"></div>
				<div className="crp-hl-title">{`${this.T('hmp-lang-code')}`.toLowerCase() === 'fr' ? link.title_fr : link.title}</div>
			</a>
		);
	}

	defaultHelpfulLinks() {
		if (this.theme.suggestedPrograms === 'pei') {
			const lang = `${this.T('hmp-lang-code')}`.toLowerCase();
			const links = [
				{ 'en': 'https://netzeronavigatorpei.com/question_e.php', 'fr': 'https://netzeronavigatorpei.com/question_f.php' },
				{ 'en': 'https://www.princeedwardisland.ca/en/service/winter-warming-program', 'fr': 'https://www.princeedwardisland.ca/fr/service/programme-dincitatifs-chaleur-hiver' },
				{ 'en': 'https://www.princeedwardisland.ca/en/service/net-zero-free-programs', 'fr': 'https://www.princeedwardisland.ca/fr/service/programmes-gratuits-carboneutralite' },
				{ 'en': 'https://www.princeedwardisland.ca/en/information/environment-energy-and-climate-action/energy-efficient-equipment-rebates', 'fr': 'https://www.princeedwardisland.ca/fr/information/environnement-energie-et-action-climatique/remises-equipement-ecoenergetique' },
				{ 'en': 'https://www.princeedwardisland.ca/en/information/environment-energy-and-climate-action/home-insulation-rebates', 'fr': 'https://www.princeedwardisland.ca/fr/information/environnement-energie-et-action-climatique/remises-lisolation-maisons' },
				{ 'en': 'https://www.princeedwardisland.ca/en/information/environment-energy-and-climate-action/instant-rebates-on-energy-efficiency-products', 'fr': 'https://www.princeedwardisland.ca/fr/information/environnement-energie-et-action-climatique/instant-rebates-on-energy-efficiency-products' }
			];
			return (<Fragment>
				{links.map((link, idx) => (
					<a className="crp-hlink" href={link[lang]} target="_blank">
						<div style={{backgroundImage: `url('/images/helpful-links-pei-${idx+1}.jpg')`}} className="crp-hl-banner"></div>
						<div id={`crp-hl-title-${idx+1}`} className={`crp-hl-title`}>{this.T(`pei-hl-${idx+1}-title`)}</div>
						<div id={`crp-hl-para-${idx+1}`} className={`crp-hl-para`}>{this.T(`pei-hl-${idx+1}-desc`)}</div>
					</a>
				))}
			</Fragment>);
		} else if (this.theme.suggestedPrograms === 'ottawa') {
			return (<Fragment>
				<a className="crp-hlink" href="https://betterhomesottawa.ca/better-homes-ottawa-services/" target="_blank">
					<div style={{backgroundImage: "url('/images/helpful-links-1.jpg')"}} className="crp-hl-banner"></div>
					<div id="crp-hl-title-1" className="crp-hl-title">{this.T("Better Homes Ottawa Services")}</div>
					<div id="crp-hl-para-1" className="crp-hl-para">{this.T("Get help from professionals who will help you what to do next.")}</div>
				</a>
				<a className="crp-hlink" href="https://betterhomesottawa.ca/rebate-and-incentive-programs/better-homes-loan-program/" target="_blank">
					<div style={{backgroundImage: "url('/images/helpful-links-2.jpg')"}} className="crp-hl-banner"></div>
					<div id="crp-hl-title-2" className="crp-hl-title">{this.T("Better Homes Ottawa Loan Program")}</div>
					<div	id="crp-hl-para-2" className="crp-hl-para">{this.T("The City of Ottawa is piloting a three - year, zero-interest loan program for home energy efficiency retrofits!")}</div>
				</a>
				<a className="crp-hlink" href="https://betterhomesottawa.ca/rebates-incentives-financing/" target="_blank">
					<div style={{backgroundImage: "url('/images/helpful-links-3.jpg')"}} className="crp-hl-banner"></div>
					<div	id="crp-hl-title-3" className="crp-hl-title">{this.T("Rebate & Incentive Programs")}</div>
					<div	id="crp-hl-para-3" className="crp-hl-para">{this.T("There are lots of programs available to help make your deep retrofit journey more affordable.")}</div>
				</a>
				<a className="crp-hlink" href="https://betterhomesottawa.ca/choosing-a-contractor/" target="_blank">
					<div style={{backgroundImage: "url('/images/helpful-links-5.jpg')"}} className="crp-hl-banner"></div>
					<div id="crp-hl-title-4" className="crp-hl-title">{this.T("Choosing a Contractor")}</div>
					<div	id="crp-hl-para-4" className="crp-hl-para">{this.T("Choosing the right contractor will determine the success of your energy retrofit project.")}</div>
				</a>
			</Fragment>);
		}
		else if (window.IS_BC_DEMO_ORG) {
			return (<Fragment>
				<a className="crp-hlink" href="https://www.betterhomesbc.ca/connect/" target="_blank">
					<div style={{backgroundImage: "url('/images/helpful-links-bc-1.jpg')"}} className="crp-hl-banner"></div>
					<div className="crp-hl-title">{this.T("Free Energy Coaching")}</div>
					<div className="crp-hl-para">{this.T("CleanBC Better Homes provides free energy coaching services for homeowners.")}</div>
				</a>
				<a className="crp-hlink" href="https://www.betterhomesbc.ca/find-a-contractor/" target="_blank">
					<div style={{backgroundImage: "url('/images/helpful-links-bc-2.jpg')"}} className="crp-hl-banner"></div>
					<div className="crp-hl-title">{this.T("Find a Contractor")}</div>
					<div className="crp-hl-para">{this.T("CleanBC Better Homes has a search tool to help you find a registered contractor for your home upgrade needs.")}</div>
				</a>
				<a className="crp-hlink" href="https://www.betterhomesbc.ca/rebates/cleanbc-better-homes-and-home-renovation-rebate-programs/" target="_blank">
					<div style={{backgroundImage: "url('/images/helpful-links-bc-3.jpg')"}} className="crp-hl-banner"></div>
					<div className="crp-hl-title">{this.T("CleanBC Rebates")}</div>
					<div className="crp-hl-para">{this.T("CleanBC’s Better Homes Program offers professional advice, qualified contractors and money-saving incentives.")}</div>
				</a>
			</Fragment>);
		}
	}

	modalPotentialSavings() {
    	let rec = this.getRInfoPackage().rec_totals || {};
		return (
			<div className="crp-savings" aria-live="polite">
				<div className="crp-savings-desc" dangerouslySetInnerHTML={{ __html: rec.summary_text }}></div>
				<div className="crp-savings-row"></div>
				{rec.cost_text && <div className="crp-savings-row">
					<div className="crp-savings-title">{this.T('Estimated Costs')}</div>
					<div className="crp-savings-value">{rec.cost_text ?? <div className='crp-savings-unavailable-1'>{this.T('Unavailable')}</div>}</div>
				</div>}
				{this.renderRebateRows(rec.rebate_list)}
				{rec.est_annual_save_text ? <div className="crp-savings-row">
					<div className="crp-savings-title">{this.T('Estimated Annual Savings')}</div>
					<div className="crp-savings-value">{rec.est_annual_save_text ?? <div className='crp-savings-unavailable-1'>{this.T('Unavailable')}</div>}</div>
				</div>: undefined}
				{rec.payback_text ? <div className="crp-savings-row">
					<div className="crp-savings-title">{this.T('Payback Period')}</div>
					<div className="crp-savings-value">{rec.payback_text ? (parseFloat(rec.payback_text) + " " + (parseFloat(rec.payback_text) <= 3 ? this.T('years or less') : this.T('Years'))) : <div className='crp-savings-unavailable-1'>{this.T('Unavailable')}</div>}</div>
				</div> : undefined}
				{rec.savings_10_year_text ? (parseFloat(`${rec.savings_10_year_text}`.replace('$', '')) >= 0 ? <div className="crp-savings-row">
					<div className="crp-savings-title">{this.T('10-Year Savings')}</div>
					<div className="crp-savings-value">{rec.savings_10_year_text ? `$${rec.savings_10_year_text}` : <div className='crp-savings-unavailable-1'>{this.T('Unavailable')}</div>}</div>
				</div> : undefined) : undefined}
				{(rec.is_heatpump ? rec.savings_15_year_text : rec.savings_20_year_text) ? <div className="crp-savings-row">
					<div className="crp-savings-title">{rec.is_heatpump ? this.T('15-Year Savings') : this.T('20-Year Savings')}</div>
					<div className="crp-savings-value">{(rec.is_heatpump ? (rec.savings_15_year_text ? `$${rec.savings_15_year_text}` : undefined) : (rec.savings_20_year_text ? `$${rec.savings_20_year_text}` : undefined)) ?? <div className='crp-savings-unavailable-1'>{this.T('Unavailable')}</div>}</div>
				</div> : undefined}
				{rec.trees_text ? <div className="crp-savings-trees">
					<img alt="" src="/images/save-tree.svg" /> {rec.trees_text}
				</div> : undefined}
				{rec.comments ? <div className="crp-comment">
					<div className="crp-comment-header">{this.T("Energy Advisor's Comments")}</div>
					{rec.comments}
				</div> : undefined}
				<div className="crp-helpful-links-header crp-hl-modal">{this.T("Helpful Links")}</div>
				<div className="crp-helpful-links crp-hl-modal">
					{this.packageHelpfulLink(rec)}
					{/*this.defaultHelpfulLinks()*/}
				</div>
			</div>
		);
	}

	modalRecommendation(rec, index) {
		return (
			<div className="crp-savings" aria-live="polite">
				<div className="crp-savings-desc" dangerouslySetInnerHTML={{ __html: (rec.rec_text ? (rec.rec_text + "<br/><br/>") : "") + rec.save_text }}></div>
				<div className="crp-savings-row"></div>
				{rec.cost_text ? <div className="crp-savings-row">
					<div className="crp-savings-title">{this.T('Estimated Costs')}</div>
					<div className="crp-savings-value">{rec.cost_text ?? (<div className="crp-savings-unavailable-1">{this.T('Unavailable')}</div>)}</div>
				</div>: undefined}
				{this.renderRebateRows(rec.rebate_list)}
				{rec.est_annual_save_text ? <div className="crp-savings-row">
					<div className="crp-savings-title">{this.T('Estimated Annual Savings')}</div>
					<div className="crp-savings-value">{rec.est_annual_save_text ?? (<div className="crp-savings-unavailable-1">{this.T('Unavailable')}</div>)}</div>
				</div>: undefined}
				{rec.payback_text ? <div className="crp-savings-row">
					<div className="crp-savings-title">{this.T('Payback Period')}</div>
					<div className="crp-savings-value">{rec.payback_text ? (parseFloat(rec.payback_text) + " " + (parseFloat(rec.payback_text) <= 3 ? this.T('years or less') : this.T('Years'))) : <div className='crp-savings-unavailable-1'>{this.T('Unavailable')}</div>}</div>
				</div>: undefined}
				{rec.savings_10_year_text ? (parseFloat(`${rec.savings_10_year_text}`.replace('$', '')) >= 0 ? <div className="crp-savings-row">
					<div className="crp-savings-title">{this.T('10-Year Savings')}</div>
					<div className="crp-savings-value">{rec.savings_10_year_text ? `$${rec.savings_10_year_text}` : (<div className="crp-savings-unavailable-1">{this.T('Unavailable')}</div>)}</div>
				</div> : undefined): undefined}
				{(rec.is_heatpump ? rec.savings_15_year_text : rec.savings_20_year_text) ? <div className="crp-savings-row">
					<div className="crp-savings-title">{rec.is_heatpump ? this.T('15-Year Savings') : this.T('20-Year Savings')}</div>
					<div className="crp-savings-value">{(rec.is_heatpump ? (rec.savings_15_year_text ? `$${rec.savings_15_year_text}` : undefined) : (rec.savings_20_year_text ? `$${rec.savings_20_year_text}` : undefined)) ?? (<div className="crp-savings-unavailable-1">{this.T('Unavailable')}</div>)}</div>
				</div> : undefined}
				{rec.trees_text ? <div className="crp-savings-trees">
					<img alt="" src="/images/save-tree.svg" /> {rec.trees_text}
				</div>: undefined}
				{false && (!rec.cost_text || !rec.eff_ns_reb_text || !rec.gh_reb_text || !rec.est_annual_save_text || !rec.payback_text || !rec.savings_10_year_text || (!rec.savings_20_year_text && !rec.savings_15_year_text)) && <div className="crp-unavailable-warning">* Unavailable values are not added to totals</div>}
				{rec.comment ? <div className="crp-comment">
					<div className="crp-comment-header">{this.T("Energy Advisor's Comments")}</div>
					{rec.comment}
				</div> : undefined}
				{rec.link_url ? <div className="crp-helpful-links-header crp-hl-modal">{this.T("Helpful Links")}</div> : undefined}
				{rec.link_url ? <div className="crp-helpful-links crp-hl-modal">
					{rec.link_url ? <a className="crp-hlink" href={rec.link_url} target="_blank">
						<div style={{backgroundImage: "url('" + this.getHLImage(rec.link_image_id,'helpful-links-3.jpg') + "')"}} className="crp-hl-banner"></div>
						<div className="crp-hl-title">{rec.link_title}</div>
						<div className="crp-hl-para">{rec.link_desc}</div>
					</a> : undefined}
					{/*this.defaultHelpfulLinks()*/}
				</div> : undefined}
			</div>
		);
	}

	modalIntro() {

		const stepTitles = {
			1: this.T('Welcome'),
			2: this.T('Home Rating'),
			3: this.T('Retrofit Scenarios'),
			4: this.T('Data Sources'),
			5: this.T('Survey for Increased Accuracy'),
			6: this.T('Personalized Recommendations')
		};

		const stepTexts = {
			1: this.T("Welcome to your Climative Home Energy Report! This is the start of your retrofit journey, helping you reduce your home’s emissions, make it more comfortable, and save money."),
			2: this.T("We used a combination of artificial intelligence (AI) and building science to estimate your home’s emissions, energy rating and the breakdown of major energy users in your home."),
			3: this.T("Learn how different retrofit scenarios will impact your home. Each retrofit scenario shows the estimated change to your home’s energy and emissions, as well as the financial impact."),
			4: this.T("The Data Sources section at the bottom of the Home Energy Report 1) tells you what data is currently being used in your energy report, and 2) lets you add more info so you get the best results."),
			5: this.T("Your home is first assessed using publicly-available data, so the ratings and recommendations start with what’s typical for a home like yours. Increase the accuracy of your Home Energy Report by providing more info through the survey."),
			6: this.T("Everyone has different priorities when making energy efficiency upgrades.\n\nWith the Personalize My Recommendations feature, you can get retrofit scenarios customized based on your priorities.")
		};

		const NUM_STEPS = 6;
		const _step = this.state.introStep;

		return (<div className="crp-intro-modal-cont">
			<img className="crp-im-img" alt="" src={`/images/customer-intro-${_step}${this.T('hmp-lang-code') == 'FR' ? '-fr' : ''}.png`} />
			<div className="crp-im-top">
				<div className="crp-im-step-no"><Trans i18nKey={"hmp-intro-step-no-1"}>{{_step}} of {{NUM_STEPS}}</Trans></div>
				<button className="crp-im-skip" onClick={this.modalCloseClick()}><Trans i18nKey="hmp-intro-skip">Skip</Trans></button>
			</div>
			<h3 className="crp-im-title">{stepTitles[_step]}</h3>
			<div className="crp-im-desc">{stepTexts[_step]}</div>
			<div className="crp-im-bottom">
				{_step === NUM_STEPS ?
				<button role="checkbox" aria-checked={this.state.dontShowIntroAgain} className="cdb-accept-checkbox cdb-dont-show-again" onClick={() => this.setState({ dontShowIntroAgain: !this.state.dontShowIntroAgain })}>
					<img className="bordered-checkbox" alt="" src={this.state.dontShowIntroAgain ? "/images/cdb-check-checked.svg" : "/images/cdb-check-unchecked.svg"}/>
					<div className="cdb-accept-checkbox-label"><Trans i18nKey="cdb-dont-show-again">
						Don’t Show this Again
					</Trans></div>
				</button> : undefined}
				{_step === NUM_STEPS ? <br/> : undefined}
				{_step === NUM_STEPS ? <br/> : undefined}
				<button className={"crp-im-back " + ((_step <= 1) ? 'disabled' : '')} onClick={() => { if (_step > 1) this.setState({introStep: this.state.introStep-1}); }}>{this.T('Back')}</button>
				<button className={"crp-im-next"} onClick={() => {
					if (_step < NUM_STEPS) {
						this.setState({introStep: this.state.introStep+1});
					} else {
						if (this.state.dontShowIntroAgain) {
							this.props.dispatch(homeownerDontShowIntro(this.props.currentUser.id, this.props.building.buildingId));
						}
						this.modalCloseClick()();
					}
				}}>{_step < NUM_STEPS ? this.T('Next') : this.T('Done')}</button>
			</div>
		</div>);
	}

	modalALevel() {
		return (
			<div className="crp-info-modal">
				<AssessmentLevel isInfoModal={true} />
			</div>
		);
	}

	modalFaq(section) {
		let content = [];
		if (section === 'faq-rating') {
			content.push((<CFAQSection section="Report > Home Rating" needsCard={true} />));
		}
		else if (section === 'faq-upgrades') {
			content.push((<CFAQSection section="Report > Retrofit Scenarios > Preview" needsCard={true} />));
		}
		
		return (
			<div className="crp-info-modal">
				{content.map((v) => v)}
			</div>
		);

	}

	shareBillsClick(e) {
		e.preventDefault();
		this.modalOpenClick('share-bills-modal')(e);
		return false;
	}

	shareEnerGuideClick(e) {
		e.preventDefault();
		this.setState({ shareEnerguideStep2: false, showEnerGuideUpdateSuccess: false });
		this.modalOpenClick('share-energuide-modal')(e);
		return false;
	}
	
	shareBillsModal() {	
		return (
			<div className="cdb-share-bills-modal" aria-live="polite">
				<div className="cdb-share-modal-para"><Trans i18nKey="cdb-accept-bills-para">
					Your Home Energy Report and public Home Rating (displayed on the Low Carbon Communities map) can be updated with your home’s energy consumption data from utilities.<br/>
					<br/>
					<b>Please set your preferences below:</b>
				</Trans></div>
				<button className="cdb-accept-checkbox" onClick={() => this.setState({ acceptShareBills: !this.state.acceptShareBills })}>
					<img alt={this.T("Current state: ") + this.T(this.state.acceptShareBills ? 'Checked' : 'Unchecked')} src={this.state.acceptShareBills ? "/images/cdb-check-checked.svg" : "/images/cdb-check-unchecked.svg"}/>
					<div className="cdb-accept-checkbox-label">{window.IS_BC_DEMO_ORG ? <Trans i18nKey="cdb-accept-eng-checkbox-bc">
						Retrieve available energy use data from BC Hydro
					</Trans> : window.IS_ON_DEMO_ORG ? <Trans i18nKey="cdb-accept-eng-checkbox-bc-on">
						Retrieve available energy use data from Hydro One
					</Trans> : <Trans i18nKey="cdb-accept-eng-checkbox-bc">
						Retrieve available energy use data
					</Trans>}</div>
				</button>
				<button className="cdb-accept-checkbox" onClick={() => this.setState({ acceptShareBills2: !this.state.acceptShareBills2 })}>
					<img alt={this.T("Current state: ") + this.T(this.state.acceptShareBills2 ? 'Checked' : 'Unchecked')} src={this.state.acceptShareBills2 ? "/images/cdb-check-checked.svg" : "/images/cdb-check-unchecked.svg"}/>
					<div className="cdb-accept-checkbox-label">{window.IS_BC_DEMO_ORG ? <Trans i18nKey="cdb-accept-eng-checkbox-2-bc">
						Retrieve available energy use data from Fortis BC
					</Trans> : window.IS_ON_DEMO_ORG ? <Trans i18nKey="cdb-accept-eng-checkbox-2-on">
						Retrieve available energy use data from Enbridge
					</Trans> : <Trans i18nKey="cdb-accept-eng-checkbox-2">
						Retrieve available energy use data
					</Trans>}</div>
				</button>
				<button className="cdb-accept-checkbox" onClick={() => this.setState({ acceptShareBills3: !this.state.acceptShareBills3 })}>
					<img alt={this.T("Current state: ") + this.T(this.state.acceptShareBills3 ? 'Checked' : 'Unchecked')} src={this.state.acceptShareBills3 ? "/images/cdb-check-checked.svg" : "/images/cdb-check-unchecked.svg"}/>
					<div className="cdb-accept-checkbox-label"><Trans i18nKey="cdb-accept-eng-checkbox-3">
						Continue retrieving energy use data from my utilities in the future
					</Trans></div>
				</button>
				<button className="cdb-accept-checkbox" onClick={() => this.setState({ acceptShareBills4: !this.state.acceptShareBills4 })}>
					<img alt={this.T("Current state: ") + this.T(this.state.acceptShareBills4 ? 'Checked' : 'Unchecked')} src={this.state.acceptShareBills4 ? "/images/cdb-check-checked.svg" : "/images/cdb-check-unchecked.svg"}/>
					<div className="cdb-accept-checkbox-label"><Trans i18nKey="cdb-accept-eng-checkbox-4">
						Update public Home Rating based on retrieved utility data 
					</Trans></div>
				</button>
				<br/>
				<img className="cdb-share-optional-icon" src="/images/info-dark-blue.svg" alt="" /><div className="cdb-share-optional">Sharing this data is optional. You can change these permissions at any time.</div>
				<div className="cdb-accept-buttons-block"></div>
				<div className="cdb-accept-buttons">
					<button className={"cdb-accept-cancel-btn"} onClick={() => {
						this.setState({ acceptShareBills: false });
						this.modalCloseClick()();
					}}>{this.T("Cancel")}</button>
					<button className={"cdb-accept-done-btn " + (!this.state.acceptShareBills ? "disabled" : "")} onClick={() => {
						if (this.state.acceptShareBills) {
							this.props.dispatch(homeownerAction(this.props.building.buildingId, {action: 'share-bills'}));
							this.modalCloseClick()();
						}
					}}>{this.T("Done")}</button>
				</div>
			</div>
		);
	}

	onDropEnerGuide(files) {
		const hadEnerGuide = this.props.hasEnerguide;
		this.setState({ enerGuideUploadError: false, loading: true });
		this.props.dispatch(uploadEnerGuidePDF(files[0], this.props.building.buildingId, {
			progress: (progress, total) => {
			},
			success: (res) => {
				this.setState({ showEnerGuideUpdateSuccess: hadEnerGuide, loading: false });
			},
			error: (err) => {
				this.setState({ enerGuideUploadError: true, loading: false });
			}
		}));
	}

	deleteEnerguideClick() {
		this.props.dispatch(deleteEnerGuidePDF(this.props.building.buildingId));
	}

	shareEnerGuideModal() {	
		let timestamp = this.props.enerGuideInfo?.updated_at;
		let reportFilename = this.T("Report Uploaded on") + " " + moment(new Date(timestamp)).format('YYYY-MM-DD');
		let reportLink = this.props.enerGuideInfo?.link;
		if (this.state.showEnerGuideUpdateSuccess) {
			return (<div className="cdb-share-energuide-modal" aria-live="polite">
				<div className="cdb-update-success-msg">
					<img alt={""} src={'/images/success-green-checkmark.svg'}/><div className="cdb-update-success-msg-inner"><Trans i18nKey={"cdb-update-success-msg"}>Successfully uploaded a new EnerGuide label.<br/>Your home has been updated.</Trans></div>
				</div>
				<div className="cdb-sem-file"><img src="/images/attach-file.svg"/> <a href={reportLink} className="cdb-sem-file-link" target="_blank">{reportFilename}</a>{/*<button className="cdb-sem-file-delete-btn" onClick={() => this.deleteEnerguideClick()}><img src="/images/trash-red.svg" /></button>*/}</div>
				<div className="cdb-accept-buttons-block"></div>
				<div className="cdb-accept-buttons">
					<button className={"cdb-accept-done-btn"} onClick={() => {
						this.modalCloseClick()();
					}}>{this.T("Done")}</button>
				</div>
			</div>);
		}
		else if (this.props.hasEnerguide) {
			return (<div className="cdb-share-energuide-modal" aria-live="polite">
				<div className="cdb-sem-file"><img src="/images/attach-file.svg"/> <a href={reportLink} className="cdb-sem-file-link" target="_blank">{reportFilename}</a>{/*<button className="cdb-sem-file-delete-btn" onClick={() => this.deleteEnerguideClick()}><img src="/images/trash-red.svg" /></button>*/}</div>
				<button className="cdb-sem-download" onClick={() => {
					let tok = reportLink.split('/');
					helpers.DownloadURI(reportLink, tok[tok.length-1]);
				}}>{this.T("Download")}</button><br/>
				{this.props.building.energuideReportStatusNew === ENERGUIDE_REPORT_STATUSES.PREV_USER_UPLOADED_REPORT ? <div className="cdb-sem-info-2"><Trans i18nKey={"cdb-sem-info-update"}>A previous user uploaded an EnerGuide Label.<br/><br/>If you have had an EnerGuide evaluation performed, upload your EnerGuide Label to update your home.<br/><br/>This will replace the existing EnerGuide Label on file.<br/><br/><i>Not sure where to look? Try your email inbox.If you provided them with your email address, you were likely emailed a PDF copy.</i></Trans></div> : undefined}
				{this.props.building.energuideReportStatusNew === ENERGUIDE_REPORT_STATUSES.USER_UPLOADED_REPORT ? <div className="cdb-sem-info-2"><Trans i18nKey={"cdb-sem-info-update-2"}>If you have had a more recent EnerGuide evaluation performed, upload your EnerGuide Label to update your home.<br/><br/>This will replace the existing EnerGuide Label on file.</Trans></div> : undefined}
				<Dropzone ref={this.dzEnerGuideRef} onDrop={this.onDropEnerGuide} >
				{({getRootProps, getInputProps}) => (
					<section>
						<div {...getRootProps()}>
							<input {...getInputProps()} />
							<div className="cdb-sem-upload-cont">
								<img src="/images/energuide-thumbnail.png" />
								<div className="cdb-sem-upload-right">
									<div className="cdb-sem-upload-title">{this.T("Click Here to Upload EnerGuide Label")}</div>
									<div className="cdb-sem-upload-subtitle">{this.T("pdf format, Maximum of 2 MB")}</div>
								</div>
							</div>
						</div>
					</section>
				)}
				</Dropzone>
				{this.state.enerGuideUploadError ? <div className="cdb-sem-error"><img src="/images/info-red.svg"/> {this.T("Upload failed, file format is not supported")}</div> : undefined}
				<div className="cdb-accept-buttons-block"></div>
				<div className="cdb-accept-buttons">
					<button className={"cdb-accept-done-btn"} onClick={() => {
						this.modalCloseClick()();
					}}>{this.T("Done")}</button>
				</div>
			</div>);
		}
		else {
			return (<div className="cdb-share-energuide-modal" aria-live="polite">
				<Dropzone ref={this.dzEnerGuideRef} onDrop={this.onDropEnerGuide} >
				{({getRootProps, getInputProps}) => (
					<section>
						<div {...getRootProps()}>
							<input {...getInputProps()} />
							<div className="cdb-sem-upload-cont">
								<img src="/images/energuide-thumbnail.png" />
								<div className="cdb-sem-upload-right">
									<div className="cdb-sem-upload-title">{this.T("Click Here to Upload EnerGuide Label")}</div>
									<div className="cdb-sem-upload-subtitle">{this.T("pdf format, Maximum of 2 MB")}</div>
								</div>
							</div>
						</div>
					</section>
				)}
				</Dropzone>
				{this.state.enerGuideUploadError ? <div className="cdb-sem-error"><img src="/images/info-red.svg"/> {this.T("Upload failed, file format is not supported")}</div> : undefined}
				<div className="cdb-sem-info"><Trans i18nKey={"cdb-sem-info"}>If you had on EnerGuide evaluation performed for your home and provided them with your email address, you were likely emailed a pdf copy of the EnerGuide Label.</Trans></div>
				<div className="cdb-accept-buttons-block"></div>
				<div className="cdb-accept-buttons">
					<button className={"cdb-accept-done-btn"} onClick={() => {
						this.modalCloseClick()();
					}}>{this.T("Done")}</button>
				</div>
			</div>);
		}
	}

	renderHomeSwitcher() {
		let ret;
		if (this.props?.currentUser?.primaryBuildingId) {
			let options = [];
			if (this.props.customerBuildings) {
				for (let building of this.props.customerBuildings) {
					options.push({
						title: building.address,
						id: building.id,
						key: building.id,
						naddr: building.normalized_address,
						current: building.current,
						primary: building.primary,
						permissionType: building.permissionType
					});
				}
			}
			let selected = options.find((e) => e.id === this.props.building.id);
			if (options.length > 1 && selected) {
				ret = (<CDropdown
					aria-label="Switch Home"
					key="crp-rh-switcher"
					style="report-home-switcher"
					title={selected.title}
					permissionType={selected.permissionType}
					options={options}
					defaultValue={selected}
					onChange={async (val) => {
						this.setState({ loading: true });
						window.VIEWED_REPORT = false;
						await this.props.dispatch(setCurrentBuilding(val.id));
						this.setState({ loading: false });
					}}
				></CDropdown>);
			}
			else {
				if (!selected) {
					selected = {
						title: this.props.building.address,
						id: this.props.building.id,
						naddr: this.props.building.normalized_address,
						current: true
					};
				}
				ret = (<div className="btmh-address"><img src="/images/small-house.svg"/> {selected.title} {selected.permissionType && selected.permissionType !== 'owner' ? <RoleBadge role={selected.permissionType} /> : undefined}</div>);
			}
		}
		return ret;
	}

	async toggleRebate(rebate) {
		try {
			this.setState({ loading: true, rand: Math.random() });
			let body = { rebates: {} };
			body.rebates[rebate.key] = !rebate.checked;
			await axios.post('/buildings/' + this.props.building.id + '/update-selected-rebates', body);
			await this.props.dispatch(getBuildingRequest(this.props.building.id));
			await this.props.dispatch(getBuildingRequestLM(this.props.building.id));
			this.setState({ loading: false, rand: Math.random() });
		}
		catch (err) {
			toast.error('Unable to toggle rebate.');
		}
	}

	modalPackageDetails() {
		let pkg = this.getRInfoPackage() ?? {};
		let bundle_avg_bill_savings = 0;
		for (let index = 0; index < pkg.recommendations.length; index++) {
			const element = -pkg.recommendations[index].first_year_cost_impact;
			if (typeof element === 'number' && !isNaN(element)) {
				bundle_avg_bill_savings += element;
			}
		}
		bundle_avg_bill_savings = Math.round(bundle_avg_bill_savings).toString();

		let content = undefined;
		let TFunc = this.T;
		switch (this.state.mpTabSel) {
			case 1: { // Financial
				let projected_savings_data = [];
				projected_savings_data.push({
					x: 0,
					y: -(pkg?.rec_totals?.cost_minus_rebates ?? 0),
					name: "UPFRONT COSTS - REBATES"
				});
				projected_savings_data.push({
					x: 10,
					y: pkg?.rec_totals?.save_10_year ?? 0,
					name: "10YR SAVINGS"
				});
				if (pkg?.rec_totals?.payback != null) {
					projected_savings_data.push({
						x: pkg?.rec_totals?.payback,
						y: 0,
						name: "PAYBACK PERIOD"
					});
				}
				projected_savings_data.push({
					x: 20,
					y: pkg?.rec_totals?.save_20_year ?? 0,
					name: "20YR SAVINGS"
				});
				projected_savings_data = projected_savings_data.sort((a,b) => a.x - b.x);
				let breakEvenX = -1000;
				for (let val of projected_savings_data) {
					if (Math.round(val.y/100) === 0) {
						breakEvenX = val.x;
						break;
					}
				}
				const baseline = this.getRInfo()?.bundle_recommendations?.baseline ?? {};
				const energy_bill_after_retrofits_graph_ignore_lifetime = JSON.parse(JSON.stringify(pkg?.rec_totals?.graphs?.energy_bill_after_retrofits_graph_ignore_lifetime.map((e) => Math.round(e)) || []));
				const baseline_energy_bill_cost_graph = JSON.parse(JSON.stringify(baseline?.graphs?.baseline_energy_bill_cost_graph?.map((e) => Math.round(e)) || []));
				if (baseline_energy_bill_cost_graph.length) {
					baseline_energy_bill_cost_graph.length = Math.min(baseline_energy_bill_cost_graph?.length, energy_bill_after_retrofits_graph_ignore_lifetime?.length);
				}		

				let proj_eol_data = [];
				const recos = JSON.parse(JSON.stringify((pkg.recommendations || [])));
				for (let reco of recos) {
					let obj = {
						x: reco.upgrade_lifetime,
						y: (energy_bill_after_retrofits_graph_ignore_lifetime[reco.upgrade_lifetime-1] ?? 0) * 0.75 + (baseline_energy_bill_cost_graph[reco.upgrade_lifetime-1] ?? 0) * 0.25,
						name: `${helpers.GetRecName(reco.recommendations_name, this.T, this.props.modelType2)}`
					};
					let any = false;
					for (let val of proj_eol_data) {
						if (val && val?.x === obj.x) {
							val.name += '\n' + obj.name;
							any = true;
							break;
						}
					}
					if (!any) {
						proj_eol_data.push(obj);
						proj_eol_data.push(null);
					}
				}

				let max_lifetime = 0;
				for (let val of proj_eol_data) {
					if (val?.x) {
						max_lifetime = Math.max(max_lifetime, val?.x);
					}
				}
				max_lifetime = max_lifetime || 30;

				if (baseline_energy_bill_cost_graph?.length) {
					baseline_energy_bill_cost_graph.length = Math.min(baseline_energy_bill_cost_graph?.length || 0, max_lifetime);
				}
				if (energy_bill_after_retrofits_graph_ignore_lifetime?.length) {
					energy_bill_after_retrofits_graph_ignore_lifetime.length = Math.min(energy_bill_after_retrofits_graph_ignore_lifetime?.length || 0, max_lifetime);
				}

				let rebate_list = [];
				for (let key in (pkg?.rec_totals?.rebate_list || {})) {
					pkg.rec_totals.rebate_list[key].key = key;
					rebate_list.push(pkg.rec_totals.rebate_list[key]);
				}
				const userRebates = this.theme.userRebates || {};
				const selectedRebates = this.getModel()?.property?.selected_rebates;
				for (let reco of recos) {
					let recoKey = `${reco.recommendations_name || ''}`.split(" ")[0];
					if (userRebates[recoKey]) {
						for (let rebate of userRebates[recoKey]) {
							if (!(pkg?.rec_totals?.rebate_list || {})[rebate.key]) {
								rebate_list.push({
									label: rebate.title,
									value: 0,
									checked: selectedRebates[rebate.key],
									key: rebate.key,
									editable: true,
									link: rebate.link,
									filterFn: rebate.filterFn
								});
							}
							else {
								pkg.rec_totals.rebate_list[rebate.key].checked = selectedRebates[rebate.key];
								pkg.rec_totals.rebate_list[rebate.key].editable = true;
								pkg.rec_totals.rebate_list[rebate.key].link = rebate.link;
								pkg.rec_totals.rebate_list[rebate.key].filterFn = rebate.filterFn;
							}
						}
					}
				}

				rebate_list.sort((a, b) => (a?.label || '').localeCompare(b?.label || ''));

				for (let item of rebate_list) {
					if (typeof item.filterFn === 'string') {
						item.filterFn = eval(item.filterFn);
					}
				}

				rebate_list = rebate_list.filter((e) => (!e.filterFn || e.filterFn(this.getModel())) ? e : null);
				
				const carNegative = ParseFloat2(pkg?.rec_totals?.cost_minus_rebates_text) < 0,
				      y20sNegative = ParseFloat2(pkg?.rec_totals?.savings_20_year_text) < 0,
					  billNegative = ParseFloat2(pkg?.rec_totals?.avg_annual_energy_bill_savings_text) < 0,
					  y10sNegative = ParseFloat2(pkg?.rec_totals?.savings_10_year_text) < 0,
				      pbpNegative = ParseFloat2(pkg?.rec_totals?.payback_text) < 0;
				const strAbs = (x, flag) => flag ? `${x}`.replace(/-/g, '') : x;

				content = [(<div className="cdp-card">
					<h3 className="cdp-title">{this.T("Financial Summary")}</h3>
					<div className="crp-rebate-warning">
						<Trans i18nKey={"crp-rebeate-warning"}>
							<b>Actual rebates may differ from estimates shown.</b>
							The estimated rebates shown do not account for specific details of your home or equipment which may impact actual rebate amounts.
						</Trans>
					</div>
					{!this.state.vcbShow ? <div className="crp-cstat-line-2">
						<CStatBlock className="cstat-cost-after-rebates" stat1={pkg?.rec_totals?.cost_minus_rebates_text ?? '$???'} stat2={this.T(`COST AFTER\nREBATES`)} stat2Negative={this.T(`SAVINGS AFTER\nREBATES`)} />
						<CStatBlock className="cstat-20-year-savings" stat1={pkg?.rec_totals?.savings_20_year_text ?? '$0'} stat2={this.T(`SAVINGS OVER\n20 YEARS`)}  stat2Negative={this.T(`COST OVER\n20 YEARS`)}/>
					</div> : [(<div className="crp-vcb-block">
						<div className="crp-vcb-1">
							<div className="crp-vcb-title">{carNegative ? this.T("Savings After Rebates") : this.T("Cost After Rebates")}</div>
							<div className="crp-vcb-title-number">{strAbs(pkg?.rec_totals?.cost_minus_rebates_text ?? '$???', carNegative)}</div>
						</div>
						<div className="crp-vcb-stat">
							<div className="crp-vcb-stat-title">{this.T("Estimated Retrofit Costs")}</div>
							<div className="crp-vcb-stat-number crp-vcb-number-cost ra-negative-color">{pkg?.rec_totals?.cost_text ?? '$0'}</div>
						</div>
						{rebate_list.map((rebate) => (rebate.editable ?
						<div className="crp-vcb-stat-checkable">
							<img className="crp-vcb-stat-checkbox" src={rebate.checked ? '/images/blue-checkbox-checked.svg' : '/images/blue-checkbox-unchecked.svg'} onClick={() => {
								this.toggleRebate(rebate);
							}}></img>
							<div className="crp-vcb-stat-title">{this.T("Estimated Rebates")} - {rebate.label}</div>
							<div className="crp-vcb-stat-number crp-vcb-number-save">${rebate.value}</div>
							{rebate.link ? <a className="crp-vcb-stat-link" href={rebate.link} target="_blank">{this.T("Read more")}{" >"}</a> : undefined}
						</div> :
						<div className="crp-vcb-stat">
							<div className="crp-vcb-stat-title">{this.T("Estimated Rebates")} - {rebate.label}</div>
							<div className="crp-vcb-stat-number crp-vcb-number-save">${rebate.value}</div>
						</div>))}
					</div>),(<div className="crp-vcb-block">
						<div className={"crp-vcb-2 " + (y20sNegative ? 'ra-negative-color' : '')}>
							<div className="crp-vcb-title">{y20sNegative ? this.T("20-Year Costs") : this.T("20-Year Savings")}</div>
							<div className="crp-vcb-title-number">{pkg?.rec_totals?.savings_20_year_text ?? '$0'}</div>
						</div>
						<div className={"crp-vcb-stat " + (billNegative ? 'ra-negative-color' : '')}>
							<div className="crp-vcb-stat-title">{billNegative ? this.T("Energy Bill Costs (1st Year)") : this.T("Energy Bill Savings (1st Year)")}</div>
							<div className="crp-vcb-stat-number crp-vcb-number-save">{strAbs(bundle_avg_bill_savings ? ('$' + bundle_avg_bill_savings) : '$0', billNegative)}</div>
						</div>
						{pkg?.rec_totals?.payback_text && (<div className="crp-vcb-stat">
							<div className="crp-vcb-stat-title">{this.T("Payback Period")}</div>
							<div className="crp-vcb-stat-number crp-vcb-number-save">{pbpNegative ? ("0 " + this.T("years")) : (parseFloat(pkg?.rec_totals?.payback_text) + " " + (parseFloat(pkg?.rec_totals?.payback_text) <= 3 ? this.T('years or less') : this.T('Years')))}</div>
						</div>)}
						<div className={"crp-vcb-stat " + (y10sNegative ? 'ra-negative-color' : '')}>
							<div className="crp-vcb-stat-title">{y10sNegative ? this.T("10 Yr Costs") : this.T("10 Yr Savings")}</div>
							<div className="crp-vcb-stat-number crp-vcb-number-save">{strAbs(pkg?.rec_totals?.savings_10_year_text ?? '$0', y10sNegative)}</div>
						</div>
					</div>)]}
					<button className="crp-vcb-btn" onClick={() => this.setState({ vcbShow: !this.state.vcbShow})}>{this.state.vcbShow ? this.T("Hide Financial Breakdown") : this.T("View Financial Breakdown")}</button>
				</div>),
				this.state.mpBaSel === 2 ? (<div className="cdp-card" key="cdp-graph-financial-impact-projection">
					<h3 className="cdp-title">{this.T("Financial Impact Projection")}</h3>
					<div className="cdp-sub-title">{this.T("This graph shows the complete financial impact over years. This includes all financial aspects considered in your retrofit scenario.")}</div>
					<HighchartsReact
						highcharts={Highcharts}
						containerProps={{ className: "cdp-highcharts-cont" }}
						options={{
							title: {
								text: '',
								align: 'left'
							},
						
							subtitle: {
								text: '',
								align: 'left'
							},
						
							yAxis: {
								title: {
									text: TFunc('Financial Impact ($)')
								}
							},
						
							xAxis: {
								title: {
									text: TFunc('Years')
								},
								accessibility: {
									rangeDescription: TFunc('Range: 1 to 25 Years')
								}
							},
						
							legend: {
								layout: 'vertical',
								align: 'right',
								verticalAlign: 'middle',
								enabled: false
							},

							tooltip: {
								formatter: function () {
									if (this.x === breakEvenX) {
										return `<b>
										${TFunc('Break-even point: ')} ${Math.round(this.x)} ${TFunc('years')}
										</b>`;
									}
									else if (this.x === 0) {
										return `<b>
										${TFunc('Initial financial impact:')} $${Math.abs(Math.round(this.y/500)*500)} ${this.y < 0 ? TFunc('in costs') : TFunc('in savings')}
										</b>`;
									}
									else {
										return `<b>
										${Math.round(this.x)}-${TFunc('year financial impact:')} $${Math.abs(Math.round(this.y/500)*500)} ${this.y < 0 ? TFunc('in costs') : TFunc('in savings')}
										</b>`;
									}
								}
							},

							plotOptions: {
								series: {
									label: {
										connectorAllowed: false
									},
									pointStart: 1
								},
								area: {
									negativeColor: '#D70015',
									color: '#248A3D'
								}
							},
						
							series: [{
								name: TFunc('Projected Savings'),
								data: projected_savings_data,
								type: "area"
							}],
						
							responsive: {
								rules: [{
									condition: {
										maxWidth: 500
									},
									chartOptions: {
										legend: {
											layout: 'horizontal',
											align: 'center',
											verticalAlign: 'bottom'
										}
									}
								}]
							}
						
						}}
					/>
				</div>) : undefined,
				(<div className="cdp-card" key="cdp-graph-projected-energy-bills">
					<h3 className="cdp-title">{this.T("Projected Energy Bills")}</h3>
					<div className="cdp-sub-title">{this.T("Energy Costs are rising. Here’s how these upgrades impact your energy bills.")}</div>
					<HighchartsReact
						highcharts={Highcharts}
						containerProps={{ className: "cdp-highcharts-cont" }}
						options={{
							title: {
								text: '',
								align: 'left'
							},
						
							subtitle: {
								text: '',
								align: 'left'
							},
						
							yAxis: {
								title: {
									text: TFunc('Annual Energy Bills ($)')
								}
							},
						
							xAxis: {
								title: {
									text: TFunc('Years')
								},
								accessibility: {
									rangeDescription: TFunc('Range: 1 to 20 Years')
								}
							},
						
							legend: {
								layout: 'horizontal',
								align: 'center',
								verticalAlign: 'bottom'
							},
						
							plotOptions: {
								series: {
									label: {
										connectorAllowed: false
									},
									pointStart: 1,
									marker: {
										enabled: true
									}
								}
							},

							tooltip: {
								formatter: function () {
									if (this.series?.index === 0) {
										return `<b>
										${TFunc("Energy bills in")} ${this.x} ${TFunc("years: ")} ${this.y < 0 ? '-' : ''} $${Math.round(this.y/10)*10}${TFunc("/year in do-nothing case")}
										</b>`;
									}
									else if (this.series?.index === 1) {
										return `<b>
										${TFunc("Energy bills in")} ${this.x} ${TFunc("years: ")} ${this.y < 0 ? '-' : ''} $${Math.round(this.y/10)*10}${TFunc("/year after retrofits")}
										</b>`;
									}
									else if (this.series?.index === 2) {
										let names = this.key.split('\n');
										let ret = [];
										ret.push(`${TFunc('Expected End of Life:')}<br/><br/>`);
										for (let name of names) {
											ret.push(`<b>${TFunc(name)}</b><br/>`)
										}
										return ret.join('\n');
									}
								}
							},
						
							series: [{
								name: this.T('Do-Nothing Case'),
								data: baseline_energy_bill_cost_graph,
								zIndex:1
							}, {
								name: this.T('Retrofit Scenario'),
								data: energy_bill_after_retrofits_graph_ignore_lifetime,
								color: '#0040DD',
								zIndex:1
							}, {
								name: this.T('Equipment End of Life'),
								data: proj_eol_data,
								color: '#C5271F',
								zIndex:2,
								opacity: 0.5,
								marker: {
									radius: 7,
									symbol: "diamond",
								},
							}],
						
							responsive: {
								rules: [{
									condition: {
										maxWidth: 500
									},
									chartOptions: {
										legend: {
											layout: 'horizontal',
											align: 'center',
											verticalAlign: 'bottom'
										}
									}
								}]
							}
						
						}}
					/>
				</div>)];

				if (this.state.mpBaSel === 2) { // Advanced
					const pkg_recos = JSON.parse(JSON.stringify((pkg.recommendations || [])));
					const upgrades = pkg_recos.map((rec) => rec);
					let graph_data = [];
					//upgrades[0].financial_impact[this.state.barFilterMetricFinancial] = -upgrades[0].financial_impact[this.state.barFilterMetricFinancial] ;
					let color_idx = 0;
					let colors = [];
					for (let index = 0; index < upgrades.length; index++) {
						const element = upgrades[index];
						element.financial_impact['bill-savings-first-year'] = -element.first_year_cost_impact;
						let obj = {
							name: helpers.GetRecName(element.recommendations_name, this.T, this.props.modelType2),
							data: [
								element.financial_impact[this.state.barFilterMetricFinancial]
							],
							color_idx: color_idx % GRAPH_COLORS.length
						};
						if (obj.data[0] > 0.) {
							colors.push(color_idx);
							color_idx += 1;
						}
						else {
							obj.color_idx = -1;
							colors.push(-1);
						}
						graph_data.push(obj);
					}
					const graph_total = graph_data.length ? graph_data.map((e) => e.data[0]).reduce((a,b)=>Math.max(0, a)+Math.max(0, b)) : 0;
					for (let item of graph_data) {
						item.data[0] = Math.round(item.data[0] / graph_total * 1000) / 10;
					}
					content.push((<div className="cdp-card" key="cdp-graph-individual-upgrade-impact">
						<h3 className="cdp-title">{this.T("Individual Retrofit Impact")}</h3>
						<CDropdown
							aria-label={this.T("Individual Retrofit Impact")}
							options={this.upgradesMetricsOptionsFinancial}
							error={null}
							defaultValue={this.upgradesMetricsOptionsFinancial.filter((e) => this.state.barFilterMetricFinancial == e.key)[0]}
							emptyText={this.T("Select Metric")}
							onChange={(val) => {
								this.setState({ barFilterMetricFinancial: val.key });
							}}
						/>
						<HighchartsReact
							highcharts={Highcharts}
							containerProps={{ className: "cdp-highcharts-cont-bar" }}
							options={{
								chart: {
									type: 'bar',
									height: 100,
									margin: 0,
									marginTop: 40
								},
								tooltip: {
									formatter: function () {
										return "<b>" + (this.series?.userOptions?.name ?? TFunc('Unknown')) + ': ' + Math.round(this.y) + '%</b>';
									}
								},
								title: {
								  	text: ''
								},
								credits: {
								  	enabled: false
								},
								yAxis: {
									visible: false
								},
								xAxis: {
									visible: false
								},
								legend: {
									margin: 50,
									y: 15,
									enabled: false
								},
								plotOptions: {
									series: {
										stacking: 'percent',
										pointWidth: 61,
										dataLabels:{
											enabled:true,
											formatter:function() {
												return Math.round(this.y) + '%';
											}
										}
								  	}
								},
								series: graph_data.filter((e) => e.data[0] > 0.)
							}}
						/>
						<div role="list">
						{pkg_recos.map((rec, idx) => (<div role="listitem" className={"a crp-iui-reco-stat " + (Math.round(rec.financial_impact[this.state.barFilterMetricFinancial] * (this.state.barFilterMetricFinancial?.includes('impact') ? -1 : 1)) < 0 ? "crp-negative" : "")}>
							<span className="crp-reco-stat-dot" style={{"background-color": colors[idx] >= 0 ? GRAPH_COLORS[colors[idx] % GRAPH_COLORS.length] : 'transparent'}}></span>
							<div className="crp-reco-stat-name">{helpers.GetRecName(rec.recommendations_name, this.T, this.props.modelType2)}</div>
							<div className="crp-reco-stat-value">{Math.round(rec.financial_impact[this.state.barFilterMetricFinancial]) < 0 ? '-' : ''}${Math.abs(Math.round(rec.financial_impact[this.state.barFilterMetricFinancial]))}</div>
						</div>))}
						</div>
					</div>));
				}
				content.push((<div className="cdp-card" key="cdp-faq-section"><CFAQSection section="Report > Retrofit Scenarios > Financial"/></div>));
			} break;
			case 2: { // Emissions

				const baseline = this.getRInfo()?.bundle_recommendations?.baseline ?? {};
				const levels = this.getRInfo().levels;
				const rec_totals = this.getRInfoPackage().levels;
				const upgrades = (pkg.recommendations || []).map((rec) => rec);
				const ratingType = true ? 'ghg_per_m2_level' : 'gj_per_m2_level';
		
				const activeRating = rec_totals[ratingType][this.state.areaSelected]||0;		
				const curRating = levels[ratingType][this.state.areaSelected]||0;

				const curColor = this.ratings[curRating-1].color;
				const newColor = this.ratings[activeRating-1].color;

				let nTrees = pkg?.rec_totals?.post_upgrade?.annual_trees ?? "0";
				let co2e_percent_savings = [];
				let color_idx = 0;
				let colors = [];
				for (let index = 0; index < upgrades.length; index++) {
					const element = upgrades[index];
					co2e_percent_savings.push({name: helpers.GetRecName(element.recommendations_name, this.T, this.props.modelType2), data: [Math.round((element.lifetime_co2e_impact / pkg.rec_totals.post_upgrade.lifetime_emissions_saved) * 1000)/10]});
					colors.push(color_idx);
					color_idx += 1;
				}
				content = [(<div className="cdp-card">
					<h3 className="cdp-title">{this.T("Updated Emissions Rating")}</h3>
					{this.renderPackageMeter(true)}
					<div className="crp-em-eng-savings"><div className="crp-em-eng-savings-left-text">{this.T('Savings')}:</div> <img alt={this.T("Annual Emissions Change")} src="/images/savings-arrow-blue.svg"/> {(pkg?.rec_totals?.percentSave||0) + "%"}</div>
				</div>),(<div className="cdp-card" key="cdp-graph-lifetime-emissions-impact">
						<h3 className="cdp-title">{this.T("Lifetime Emissions Impact")}</h3>
						<div className="crp-percent-chart-space"></div>
						<HighchartsReact
							highcharts={Highcharts}
							containerProps={{ className: "cdp-highcharts-cont-bar" }}
							options={{
								chart: {
									type: 'bar',
									height: 100,
									margin: 0,
									marginTop: 40
								},
								tooltip: {
									formatter: function () {
										return "<b>" + (this.series?.userOptions?.name ?? TFunc('Unknown')) + ': ' + Math.round(this.y) + '%</b>';
									}
								},
								title: {
								  	text: ''
								},
								credits: {
								  	enabled: false
								},
								yAxis: {
									visible: false
								},
								xAxis: {
									visible: false
								},
								legend: {
									margin: 50,
									y: 15,
									enabled: false
								},
								plotOptions: {
									series: {
										stacking: 'percent',
										pointWidth: 61,
										dataLabels:{
											enabled:true,
											formatter:function() {
												return Math.round(this.y) + '%';
											}
										}
								  	}
								},
								series: co2e_percent_savings
							}}
						/>
						<div role="list">
						{(pkg.recommendations || []).map((rec, idx) => (<div role="listitem" className="crp-iui-reco-stat">
							<span className="crp-reco-stat-dot" style={{"background-color": colors[idx] >= 0 ? GRAPH_COLORS[colors[idx] % GRAPH_COLORS.length] : 'transparent'}}></span>
							<div className="crp-reco-stat-name">{helpers.GetRecName(rec.recommendations_name, this.T, this.props.modelType2)}</div>
							<div className="crp-reco-stat-value">{Math.round((-rec.lifetime_co2e_impact / 1000) * 10) / 10} {this.T('Tonnes')}</div>
						</div>))}
						</div>
					</div>),(<div className="cdp-trees-card" style={-pkg?.rec_totals?.post_upgrade?.lifetime_emissions_saved_tonnes < 0 ? {display: 'none'} : {}}>
					<div className="cdp-trees-top">
						<div className="cdp-trees-top-num">{-pkg?.rec_totals?.post_upgrade?.lifetime_emissions_saved_tonnes}</div><div className="cdp-trees-top-2">{this.T("TONNES OF EMISSIONS SAVED (LIFETIME IMPACT)")}</div>
					</div>
					<div className="cdp-trees-bottom">
						<img alt={''} src="/images/tree-black.svg"/> <div className="cdp-trees-bottom-2"><Trans i18nKey={"like-planting-n-trees"}>That's like planting {{nTrees}} trees</Trans></div>
					</div>
				</div>),(<div className="cdp-card" key="cdp-faq-section"><CFAQSection section="Report > Retrofit Scenarios > Emissions"/></div>)];
			} break;
			case 3: { // Energy
				const upgrades = (pkg.recommendations || []).map((rec) => rec);
				const baseline = this.getRInfo()?.bundle_recommendations?.baseline ?? {};
				const levels = this.getRInfo().levels;
				const rec_totals = this.getRInfoPackage().levels;
				const ratingType = false ? 'ghg_per_m2_level' : 'gj_per_m2_level';
		
				const activeRating = rec_totals[ratingType][this.state.areaSelected]||0;
				const curRating = levels[ratingType][this.state.areaSelected]||0;

				const curColor = this.ratings[curRating-1].color;
				const newColor = this.ratings[activeRating-1].color;

				let nDrives = pkg?.rec_totals?.post_upgrade?.laps_around_earth ?? "0";
				let gj_percent_savings = [];
				let color_idx = 0;
				let colors = [];
				for (let index = 0; index < upgrades.length; index++) {
					const element = upgrades[index];
					gj_percent_savings.push({name: helpers.GetRecName(element.recommendations_name, this.T, this.props.modelType2), data: [Math.round((element.lifetime_gj_impact / pkg.rec_totals.post_upgrade.lifetime_energy_saved) * 1000)/10]});
					colors.push(color_idx);
					color_idx += 1;
				}
				content = [(<div className="cdp-card">
					<h3 className="cdp-title">{this.T("Updated Energy Rating")}</h3>
					{this.renderPackageMeter(false)}
					<div className="crp-em-eng-savings"><div className="crp-em-eng-savings-left-text">{this.T('Savings')}:</div> <img alt="" src="/images/savings-arrow-blue.svg"/> {(pkg?.rec_totals?.bundle_gj_percentSave||0) + "%"}</div>
				</div>),(<div className="cdp-card" key="cdp-graph-lifetime-energy-impact">
						<h3 className="cdp-title">{this.T("Lifetime Energy Impact")}</h3>
						<div className="crp-percent-chart-space"></div>
						<HighchartsReact
							highcharts={Highcharts}
							containerProps={{ className: "cdp-highcharts-cont-bar" }}
							options={{
								chart: {
									type: 'bar',
									height: 100,
									margin: 0,
									marginTop: 40
								},
								tooltip: {
									formatter: function () {
										return "<b>" + (this.series?.userOptions?.name ?? 'Unknown') + ': ' + Math.round(this.y) + '%</b>';
									}
								},
								title: {
								  	text: ''
								},
								credits: {
								  	enabled: false
								},
								yAxis: {
									visible: false
								},
								xAxis: {
									visible: false
								},
								legend: {
									margin: 50,
									y: 15,
									enabled: false
								},
								plotOptions: {
									series: {
										stacking: 'percent',
										pointWidth: 61,
										dataLabels:{
											enabled:true,
											formatter:function() {
												return Math.round(this.y) + '%';
											}
										}
								  	}
								},
								series: gj_percent_savings
							}}
						/>
						{(pkg.recommendations || []).map((rec, idx) => (<div className="crp-iui-reco-stat">
							<span className="crp-reco-stat-dot" style={{"background-color": colors[idx] >= 0 ? GRAPH_COLORS[colors[idx] % GRAPH_COLORS.length] : 'transparent'}}></span>
							<div className="crp-reco-stat-name">{helpers.GetRecName(rec.recommendations_name, this.T, this.props.modelType2)}</div>
							<div className="crp-reco-stat-value">{Math.round((-rec.lifetime_gj_impact) * 10) / 10} GJ</div>
						</div>))}
					</div>),(<div className="cdp-trees-card" style={-pkg?.rec_totals?.post_upgrade?.lifetime_energy_saved < 0 ? {display: 'none'} : {}}>
					<div className="cdp-trees-top">
						<div className="cdp-trees-top-num">{(-pkg?.rec_totals?.post_upgrade?.lifetime_energy_saved) ?? "???"}</div><div className="cdp-trees-top-2">{this.T("GJ OF ENERGY SAVED")}</div>
					</div>
					<div className="cdp-trees-bottom">
						<img alt={''} src="/images/car-black.svg"/> <div className="cdp-trees-bottom-2"><Trans i18nKey={"like-driving-n-times"}>That’s the same energy it would take to drive around the Earth {{nDrives}} times.</Trans></div>
					</div>
				</div>),(<div className="cdp-card" key="cdp-faq-section"><CFAQSection section="Report > Retrofit Scenarios > Energy"/></div>)];
			} break;
			case 4: { // General
				console.log("pkg.recommendations", pkg.recommendations)
				if (this.props.type2 || this.props.isPostUpgrade) { // type 2 published
					content = [
						(this.props.modelType2?.report_override?.recommendations?.overall_comments ? <div className="cdp-card">
							<button className="cdp-gen-comments-button" onClick={(e) => {
								this.setState({ genCommentsShow: !this.state.genCommentsShow });
								(e || window.event).stopPropagation();
								(e || window.event).preventDefault();
								return false;
							}}>{this.T("Energy Advisor’s Comments")} <img alt="" src={this.state.genCommentsShow ? '/images/btmh-arrow-active.svg' : '/images/btmh-arrow.svg'} /></button>
							{this.state.genCommentsShow ? <div className="cdp-gen-comments">
								{this.props.modelType2?.report_override?.recommendations?.overall_comments}
							</div> : undefined}
						</div> : undefined),
						(<div className="cdp-card">
							<h3 className="cdp-title">{this.T("Recommended Retrofits")}</h3>
							<div className="cdp-below-title">{this.T("The following upgrades are included in this Retrofit Scenario:")}</div>
							{(pkg.recommendations || []).map((rec, idx) => (<div className="cdp-rec-cont-type-2">
								<img alt={""} src={'/images/' + helpers.GetRecIcon(rec.recommendations_name, this.T) + '.svg'} />
								<div className="cdp-rec-right">
									<div className="cdp-rec-name">{helpers.GetRecName(rec.recommendations_name, this.T, this.props.modelType2)}</div>
									<div className="cdp-rec-summary">{helpers.GetRecDescription(rec.recommendations_name, this.T)}</div>
								</div>
								<div className="cdp-rec-data">
									{helpers.GetRecData(rec.recommendations_name, rec, this.props.modelType2, this.props.building, this.T).length ? <h4>{helpers.GetRecNameModeled(rec.recommendations_name, this.T)}</h4> : undefined}
									{helpers.GetRecData(rec.recommendations_name, rec, this.props.modelType2, this.props.building, this.T).map((row) => (<div className="cdp-rec-data-row">
										<div className="cdp-rec-data-title">{row.title}</div><div className="cdp-rec-data-value">{row.value}</div>
									</div>))}
								</div>
								{helpers.GetRecComments(rec.recommendations_name, rec, this.props.modelType2) ? <button className="cdp-rec-comments-button" onClick={(e) => {
									let recCommentsShow = this.state.recCommentsShow;
									recCommentsShow[idx] = !recCommentsShow[idx];
									this.setState({ recCommentsShow, rand: Math.random() });
									(e || window.event).stopPropagation();
									(e || window.event).preventDefault();
									return false;
								}}>{this.state.recCommentsShow[idx] ? this.T("Hide Energy Advisor’s Comments") : this.T("Show Energy Advisor’s Comments")} <img alt="" src={this.state.recCommentsShow[idx] ? '/images/btmh-arrow-active.svg' : '/images/btmh-arrow.svg'} />
								</button> : undefined}
								{this.state.recCommentsShow[idx] && helpers.GetRecComments(rec.recommendations_name, rec, this.props.modelType2) ? <div className="cdp-rec-comments">
									{helpers.GetRecComments(rec.recommendations_name, rec, this.props.modelType2)}
								</div> : undefined}
								{rec.helpful_links?.length ? <div className="cdp-rec-helpful-links-cont">
									{(rec.helpful_links/* || [{ title: 'Test Helpful Link', image_id: 'on-4', url: 'www.betterhomes.ca'}, { title: 'Test Helpful Link 2', image_id: 'on-5', url: 'www.betterhomes.ca'}]*/).map((link, link_idx) => this.type2RecoHelpfulLink(link))}
								</div> : undefined}
							</div>))}
						</div>),
						(<div className="cdp-card" key="cdp-faq-section"><CFAQSection section="Report > Retrofit Scenarios > Retrofits"/></div>)
					];
				}
				else {
					content = [
						(<div className="cdp-card">
							<h3 className="cdp-title cdp-title-space">{this.T("Included Retrofits")}</h3>
							<div className="cdp-below-title">{this.T("The following retrofits are included in this bundle:")}</div>
							{(pkg.recommendations || []).map((rec) => (<div className="cdp-rec-cont">
								<img alt={""} src={'/images/' + helpers.GetRecIcon(rec.recommendations_name, this.T) + '.svg'} />
								<div className="cdp-rec-right">
									<div className="cdp-rec-name">{helpers.GetRecName(rec.recommendations_name, this.T)}</div>
									<div className="cdp-rec-summary">{helpers.GetRecDescription(rec.recommendations_name, this.T)}</div>
								</div>
							</div>))}
						</div>),
						(!this.theme.hidePackageHelpfulLinks ? <h3 className="cdp-title cdp-title-space">{this.T("Helpful Links")}</h3> : undefined),
						(!this.theme.hidePackageHelpfulLinks ? <div className="crp-helpful-links crp-hl-modal">
							{(pkg.recommendations || []).map((rec) => this.packageHelpfulLink(rec))}
							{/* {this.defaultHelpfulLinks()} */}
						</div> : undefined),
						(<div className="cdp-card" key="cdp-faq-section"><CFAQSection section="Report > Retrofit Scenarios > Retrofits"/></div>)
					];
				}
			} break;
			default: break; // error
		}
		return (
			<div className="cdb-package-modal" aria-live="polite">
				<div className="cdp-tabs" role="tablist" id="cdp-tabs">
					{this.props.hideFinancialData ? undefined : <button aria-controls="cdp-tabs" role="tab" aria-selected={this.state.mpTabSel === 1 ? 'true' : 'false'} className={"cdp-tab " + (this.state.mpTabSel === 1 ? 'cdp-tab-selected' : '')} onClick={() => this.setState({ mpTabSel: 1 })}>{this.T("Financial")}</button>}
					<button aria-controls="cdp-tabs" role="tab" aria-selected={this.state.mpTabSel === 2 ? 'true' : 'false'} className={"cdp-tab " + (this.state.mpTabSel === 2 ? 'cdp-tab-selected' : '')} onClick={() => this.setState({ mpTabSel: 2 })}>{this.T("Emissions")}</button>
					<button aria-controls="cdp-tabs" role="tab" aria-selected={this.state.mpTabSel === 3 ? 'true' : 'false'} className={"cdp-tab " + (this.state.mpTabSel === 3 ? 'cdp-tab-selected' : '')} onClick={() => this.setState({ mpTabSel: 3 })}>{this.T("Energy")}</button>
					<button aria-controls="cdp-tabs" role="tab" aria-selected={this.state.mpTabSel === 4 ? 'true' : 'false'} className={"cdp-tab " + (this.state.mpTabSel === 4 ? 'cdp-tab-selected' : '')} onClick={() => this.setState({ mpTabSel: 4 })}>{this.T("Retrofits")}</button>
				</div>
				{this.state.mpTabSel === 1 ? <div role="tablist" className="cdp-ba-cont" id="cdp-ba-tabs">
					<button aria-controls="cdp-ba-tabs" role="tab" aria-selected={this.state.mpBaSel === 1 ? 'true' : 'false'} className={"cdp-ba " + (this.state.mpBaSel === 1 ? 'cdp-ba-selected' : '')} onClick={() => this.setState({ mpBaSel: 1 })}>{this.T("Basic")}</button>
					<button aria-controls="cdp-ba-tabs" role="tab" aria-selected={this.state.mpBaSel === 2 ? 'true' : 'false'} className={"cdp-ba " + (this.state.mpBaSel === 2 ? 'cdp-ba-selected' : '')} onClick={() => this.setState({ mpBaSel: 2 })}>{this.T("Advanced")}</button>
				</div> : undefined}
				{content}
			</div>
		);
	}

	render() {

		if (this.props.show) {
			if (this.theme.hasAdvisor) {
				document.title = this.T('Home Energy Report') + ' - ' + this.T('Energy Navigator');
			}
			else {
				document.title = this.T('Home Energy Report') + ' - ' + this.T('Climative  Low Carbon Communities');
			}
		}

		if (!this.props.isPreview && this.props.show) {
			document.body.className = "customer-page " + this.theme.className;
		}

		if ((!this.state.buildingInfoLoaded || !this.getRInfo() || !this.getRInfo().recommendations) && !(this.props.currentUser && !this.props.currentUser?.primaryBuildingId)) {
			if (!this.props.buildingLoadError) {
				return <div className={"customer-report-page" + this.theme.className}>
					<div className={'loading loading-show'}><img alt={this.T("Loading Animation")} src='/images/loading-transparent.gif'/></div>
				</div>;
			}
			else {
				if (helpers.getCurrentPage() === 'report') {
					window.gotoPage('map');
					toast.error(this.T("Unable to display report, please try again later."));
				}
				return <div className={"customer-report-page" + this.theme.className}>
				</div>;
			}
		}

		this.recordView();

		const lang = `${this.T('hmp-lang-code')}`.toLowerCase();

		let recs = this.getRInfoPackage() ? this.getRInfoPackage().recommendations : [];

		for (let i=0; i<recs.length; i++) {
			if (recs[i].is_foundation && this.props.hideFoundationUpgrade) {
				recs.splice(i, 1);
				break;
			}
		}

		const lastUpdatedDate = moment(this.props.lastUpdated).format('MMM. D, YYYY - h:mm a');

		let showAnyCTA = !((!this.theme.findServiceProviderURL && !this.props.showSurveyCTA) || (this.theme.disableBooking && !this.props.showSurveyCTA) || !this.props.showCTA) || !this.theme.advisorExperience;
		if (!this.props.isAuthenticated || !this.props.currentUser?.primaryBuildingId) {
			showAnyCTA = false;
		}
		let hideHelpfulLinks = false;
		if (THEME.advisorExperience) {
			showAnyCTA = false;
			hideHelpfulLinks = true;
		}

		let buildingId = (this.props.currentUser?.currentBuildingId ?? this.props.currentUser?.primaryBuildingId) ?? this.props?.building?.buildingId;
		const HIDE = ((buildingId !== this.props?.building?.buildingId || buildingId !== this.props?.modelType1?.property?.building_id) && this.props.currentUser?.id?.toLowerCase()?.indexOf('mock') !== 0);
		const acceptingInvite = localStorage.getItem('accepting_invite') === 'true';

		return (
			<div className={"customer-report-page " + ((!this.props?.building?.buildingId || this.props.currentUser?.id?.toLowerCase()?.indexOf('mock') === 0 || this.props.building?.buildingId?.toLowerCase()?.indexOf('mock') === 0) ? ('report-censored-' + this.props.shareLevel) : '')} style={{ visibility: !HIDE ? 'visible' : 'hidden', display: !this.props.show ? 'none' : 'block'}}>
				<div className={'loading ' + ((this.state.loading || this.props.buildingLoading || acceptingInvite) ? 'loading-show' : 'loading-hide')}><img alt={this.T("Loading Animation")} src='/images/loading-transparent.gif'/></div>
				{/*!this.props.isPreview && <TermsAndConditions
					isCustomer={true}
					termsAccepted={this.props.currentUser && this.props.currentUser.termsAcceptedAt}
				/>*/}
				{recs.length >= 2 && this.renderModal(
					"potential_savings",
					this.props.type2 ? this.T("Retrofit Package Savings") : this.T("Total Energy Savings"),
					this.modalPotentialSavings.bind(this)
				)}
				{recs.map((rec, index) => this.renderModal("upgrade_" + index, rec.name, () => this.modalRecommendation(rec, index)))}
				{this.renderModal("package-modal", this.state.rsDropdownOptions.filter((e) => this.state.upgradePackageSelected == e.key)[0]?.title, () => this.modalPackageDetails())}
				{this.renderModal("assessment-level", this.T("ASSESSMENT LEVELS"), () => this.modalALevel())}
				{this.renderModal("faq-rating", this.T("FAQ - Energy & Emissions Ratings"), () => this.modalFaq('faq-rating'))}
				{this.renderModal("faq-upgrades", this.T("FAQ – Retrofits"), () => this.modalFaq('faq-upgrades'))}
				{this.renderModal("share-bills-modal", this.T("Share Utility Data"), () => this.shareBillsModal())}
				{this.renderModal("share-energuide-modal", !this.props.hasEnerguide ? this.T("Upload EnerGuide Label") : this.T("Your EnerGuide Label"), () => this.shareEnerGuideModal())}
				{!acceptingInvite ? this.renderModal("intro-modal", this.T("Welcome to Your Home Energy Report"), () => this.modalIntro()) : undefined}
				<div className="crp-non-footer" aria-hidden={this.state.modalOpen ? 'true' : 'false'}>
					<div className="crp-body">
						{(this.props.notif_alerts.length >= 1 && this.props.isAuthenticated && this.props.building?.id && this.props.building?.buildingId !== 'MOCK-DATA-123') ?<div className="crp-notif-wrapper">
							{this.props.notif_alerts.length === 1 ? 
								(<CNotifBubble title={helpers.NotificationTitle(this.props.notif_alerts[0], this.T)} description={helpers.NotificationDescription(this.props.notif_alerts[0], this.T)} id={this.props.notif_alerts[0].id} />) :
								(<CNotifBubble title={this.T('You have') + ' ' + this.props.notif_alerts.length + ' ' + this.T('alerts')} description={this.T('Open the notifications page to read more and take action.')} id={null} />)
							}
						</div> : undefined}
						{this.theme.advisorExperience ? <div className="hbb-cont" aria-live="polite">
							<button className="ho-back-button" onClick={() => window.gotoPage('dashboard')}>
								<img src="/images/nav-back-arrow-blue.svg" alt={""} /> <b>{this.T('Dashboard')}</b> / {this.props.isPostUpgrade ? this.T("Retrofit Validation Report") : this.props.type2 ? this.T("Energy Advice Report") : this.T("Energy Savings Potential Report") }
							</button>
						</div> : undefined}
						{this.theme.advisorExperience ? [<h1 className="crp-main-title" aria-live="polite">{this.props.isPostUpgrade ? this.T("Your Retrofit Validation Report") : this.props.type2 ? this.T("Your Energy Advice Report") : this.T("Your Energy Savings Potential Report")}</h1>,<div className="crp-main-title" style={{marginTop: "-10px"}}><AssessmentLevel level={this.props.assessmentLevel} onInfoClick={this.modalOpenClick('assessment-level')} />{this.renderHomeSwitcher()}</div>] :
						    <div className="crp-main-title"><p role="heading" aria-level="1">{this.T('Home Energy Report')}</p>
							{this.props.isAuthenticated && (this.props.building?.id === 'MOCK-DATA-123' || !this.props.building?.id) ? <div className="crp-top-cta">
								<div className="crp-tc-1">{this.T("No Home Selected")}</div>
								<div className="crp-tc-2">{this.T("Add your home to view your home energy report.")}</div>
								<button onClick={() => window.gotoPage('map')} className="crp-tc-button">{this.T("Find My Home")}</button>
							</div> : !this.props.isAuthenticated ? <div className="crp-top-cta">
								<div className="crp-tc-1">{this.T("You Are Not Signed In")}</div>
								<div className="crp-tc-2">{this.T("Sign in to view your home energy report.")}</div>
								<button onClick={() => {this.props.openLoginModal()}} className="crp-tc-button">{this.T("Sign In")}</button>
							</div> : undefined}
							<AssessmentLevel level={this.props.assessmentLevel} onInfoClick={this.modalOpenClick('assessment-level')} />
							{this.renderHomeSwitcher()}
							{(this.props.isAuthenticated && this.props.building?.id && this.props.building?.buildingId !== 'MOCK-DATA-123') ? <div className="crp-main-title-updated"><Trans i18nKey={"crp-data-updated-on"}>Updated: {{lastUpdatedDate}}</Trans>
								{this.props.hasEnerguide && <a className="crp-energuide-verified" data-tooltip-id="tooltip-energuide-verified" data-tooltip-content={this.T("Verified with EnerGuide label")}><img src="/images/verified-check.svg" alt=""/> {this.T('Verified')}</a>}
							</div> : undefined}
							<Tooltip id="tooltip-energuide-verified" className="tooltip-energuide-verified" />
						</div>}
						<CHomeRatingCard reportPage={true} rInfo={this.getRInfo()} updateParentState={(json) => {this.setState(json);}} homeRatingTab={this.state.homeRatingTab} areaSelected={this.state.areaSelected} modalOpenClick={this.modalOpenClick} />
						{!this.props.hideRetrofitScenarios ? <CRetrofitScenariosCard type2={this.props.type2} rInfo={this.getRInfo()} rInfoPackage={this.getRInfoPackage()} updateParentState={(json) => {this.setState(json);}} upgradePackageSelected={this.state.upgradePackageSelected} modalOpenClick={this.modalOpenClick} /> : undefined}
						{!this.props.type2 ? <div className="crp-datasources-cont">
							<div className="crp-datasources">
								<h2 className="crp-ds-title">{this.T("Data Sources")}</h2>
								<div className="crp-ds-updated-date"><Trans i18nKey={"crp-data-updated-on"}>Updated: {{lastUpdatedDate}}</Trans></div>
								<div className="crp-ds-copy">{this.T("Your Energy Rating and Retrofit Scenarios are based on:")}</div>
								<div className="cdb-step-checkbox">
									<img alt={this.props.dataSources?.has_public_data ? this.T('Checked') : this.T('Unchecked')} src={this.props.dataSources?.has_public_data ? '/images/cdb-step-checkbox-checked.svg' : '/images/cdb-step-checkbox-empty.svg'}/><div className="cdb-step-check-title">{this.T('Publicly-Available Data')}</div>
								</div>
								<div className="cdb-step-checkbox">
									<img alt={this.props.dataSources?.has_public_data ? this.T('Checked') : this.T('Unchecked')} src={this.props.dataSources?.has_survey ? '/images/cdb-step-checkbox-checked.svg' : '/images/cdb-step-checkbox-empty.svg'}/><div className="cdb-step-check-title">{this.T('Survey')}</div>
									<a className="cdb-step-checkbox-button" href="#/app/customer/wizard" title={this.props.dataSources?.has_survey ? this.T("Update your Home Information Survey") : this.T("Fill in your Home Information Survey")}>{this.props.dataSources?.has_survey ? this.T('Update') : this.T('Survey')} <img alt="" src='/images/cdb-step-checkbox-button.svg' /></a>
								</div>
								<div className={"cdb-step-checkbox " + (this.props.building.energuideReportStatus === ASYNC_STATUSES.SHARED ? "pending" : "")}>
									<img alt={this.props.hasEnerguide ? this.T('Checked') : this.T('Unchecked')} src={this.props.hasEnerguide ? '/images/cdb-step-checkbox-checked.svg' : this.props.building.energuideReportStatus == ASYNC_STATUSES.SHARED ? '/images/cdb-step-checkbox-pending.svg' : '/images/cdb-step-checkbox-empty.svg'}/><div className="cdb-step-check-title">{this.T('EnerGuide Label')}</div>
									<a className="cdb-step-checkbox-button" onClick={(e) => this.shareEnerGuideClick(e || window.event)} title={this.T("EnerGuide Label")}>{this.props.hasEnerguide ? this.T('View/Replace') : this.T('Upload')} <img src='/images/cdb-step-checkbox-button.svg' /></a>
								</div>
								{/*<div className={"cdb-step-checkbox " + (this.props.building.utilityDataStatus == ASYNC_STATUSES.SHARED ? "pending" : "")}>
									<img alt={this.props.dataSources?.has_public_data ? this.T('Checked') : this.T('Unchecked')} src={this.props.dataSources?.has_bills ? '/images/cdb-step-checkbox-checked.svg' : this.props.building.utilityDataStatus == ASYNC_STATUSES.SHARED ? '/images/cdb-step-checkbox-pending.svg' : '/images/cdb-step-checkbox-empty.svg'}/><div className="cdb-step-check-title">{this.T('Utility Data')}</div>
									{!this.props.dataSources?.has_bills && !this.props.building.utilityDataStatus ? <a className="cdb-step-checkbox-button" onClick={(e) => this.shareBillsClick(e || window.event)} aria-label={this.T("Adjust your Utility Data Sharing Settings")}>{this.T('Share')} <img src='/images/cdb-step-checkbox-button.svg' /></a> : <a className="cdb-step-checkbox-button" onClick={(e) => this.shareBillsClick(e || window.event)} aria-label={this.T('Adjust your Utility Data Sharing Settings')}>{this.T('Update Preferences')} <img alt="" src='/images/cdb-step-checkbox-button.svg' /></a>}
									{this.props.building.utilityDataStatus == ASYNC_STATUSES.SHARED && !this.props.dataSources?.has_bills ? <a className="cdb-step-checkbox-button pending">{this.T('Pending')}</a> : undefined}
								</div>*/}
							</div>
						</div> : undefined}
						{!hideHelpfulLinks ? <h2 className={"crp-helpful-links-header" + (showAnyCTA && !this.props.showPRecCTA && !this.props.showSurveyCTA ? " crp-next-steps" : "")}>{showAnyCTA && !this.props.showPRecCTA && !this.props.showSurveyCTA ? <span><img alt="" src="/images/next-steps-notch.svg"/>{this.T("Next Steps: Suggested Programs")}</span> : this.T("Suggested Programs")}</h2> : undefined}
						{!hideHelpfulLinks ? <div id="helpful-links-main" className={"crp-helpful-links" + (showAnyCTA && !this.props.showPRecCTA && !this.props.showSurveyCTA ? " crp-next-steps" : "")}>
							{this.defaultHelpfulLinks()}
						</div> : undefined}
						{/*this.renderComparison()*/}
						<div style={{ height: "56px" }} />
					</div>
				</div>
				<div className={"crp-footer-block " + (!showAnyCTA ? 'crp-footer-no-cta' : '')}></div>
				<footer className={"crp-footer " + (!showAnyCTA ? 'crp-footer-no-cta' : '')} aria-label={this.T("Footer")}>
					{showAnyCTA && <div className={"crp-cta cta-float " + (this.state.showFloatingCTA ? "cta-float-show" : "")}>
						<div className="crp-cta-button-cont">
							<span className="cta-float-text">{this.props.showPRecCTA ? this.T("Get Personalized Retrofit Scenarios") : this.props.showSurveyCTA ? (this.props.hideRetrofitScenarios ? this.T("Unlock Estimated Retrofit Scenarios") : this.T("Improve the Accuracy of Your Report")) : this.T("Ready to start saving energy?")}</span>
							<button className="crp-cta-button" onClick={() => this.ctaClick()}>
								{this.props.showPRecCTA ? this.T('PERSONALIZE MY RECOMMENDATIONS') : this.props.showSurveyCTA ? this.T("Take Building Info Survey") : this.props.type2 ? this.T("Find a Service Provider") : this.T("See Suggested Programs")}
							</button>
						</div>
					</div>}
					{showAnyCTA && <div className="crp-cta">
						<div className="crp-cta-button-cont">
						<span className="cta-float-text">{this.props.showPRecCTA ? this.T("Get Personalized Retrofit Scenarios") : this.props.showSurveyCTA ? (this.props.hideRetrofitScenarios ? this.T("Unlock Estimated Retrofit Scenarios") : this.T("Improve the Accuracy of Your Report")) : this.T("Ready to start saving energy?")}</span>
							<button className="crp-cta-button" onClick={() => this.ctaClick()}>
								{this.props.showPRecCTA ? this.T('PERSONALIZE MY RECOMMENDATIONS') : this.props.showSurveyCTA ? this.T("Take Building Info Survey") : this.props.type2 ? this.T("Find a Service Provider") : this.T("See Suggested Programs")}
							</button>
						</div>
					</div>}
					{!showAnyCTA && <div className={"crp-cta cta-float " + (this.state.showFloatingCTA ? "cta-float-show" : "")}></div>}
					{!showAnyCTA && <div className="crp-cta"></div>}
					{this.theme.isSimptek ? <br/> : undefined}
					<div className='cwp-fspace' />{this.theme.footer.copyright[lang]}<br />{!this.theme.isSimptek ? <span><Trans i18nKey="simptek-copyright">Powered by <a href="https://www.climative.ai">Climative</a></Trans></span> : undefined}
				</footer>
			</div>
		);
	}
}

////

function mapStateToProps(state, ownProps) {
	let building = state.buildings.curBuilding.building || {};
	let model = state.buildings.curModel || {};
	let model2 = model.LPUB;

	// for report preview
	if (ownProps?.isDraft) {
		model2 = model.LPU;
	}

	let dataSources = model?.__report_info?.data_sources || {};

	let showSurveyCTA = !dataSources.has_survey && !!state.auth.currentUser;
	
	let status = { main: building.highestAuditStatus || 1, sub: building.highestAuditSubStatus || 1 };

	let extra = {};

	if (THEME?.advisorExperience) {
		if (statusGtEqual(status, STATUSES.PRE_PREVIEW_PUBLISHED)) {
			extra.type2 = true;
		}
	}

	let permissionMap = {};
	for (let building of state.auth.customerBuildings ?? []) {
		permissionMap[building.id] = building.permissionType;
	}

	let ret = {
		buildingLoadError: state.buildings.curBuilding.building?.error === 'no-access' ||
						   state.buildings.curModel?.error === 'no-access',
		buildingLoading: state.buildings.isReceiving || state.buildings.isReceivingLM,
		notif_alerts: (model.notifications || []).filter((e) => e.type === 'alert'),
		modelType1: model,
		modelType2: THEME?.advisorExperience ? (ownProps?.isDraft || status.main >= STATUSES.POST_AWAITING_SIGNUP.main || statusEqual(STATUSES.PRE_PREVIEW_PUBLISHED, status) || status.main === STATUSES.UPG_UPGRADES_NOT_STARTED.main ? model2 : null) : undefined,
		showCTA: THEME?.advisorExperience ? ((statusEqual(status, STATUSES.VA_AUTOMATED_INSIGHTS) || statusEqual(status, STATUSES.PRE_PREVIEW_PUBLISHED)) ||
				 					  (statusGtEqual(status, STATUSES.POST_AWAITING_SIGNUP) && !statusGtEqual(status, STATUSES.POST_AWAITING_BOOKING))) : !!state.auth.currentUser,
		showSurveyCTA,
		showPRecCTA: !building.customerPrioritiesJSON && !showSurveyCTA,
		isPostUpgrade: THEME?.advisorExperience ? statusGtEqual(status, STATUSES.POST_AWAITING_SIGNUP) : undefined,
		building,
		status,
		isAuthenticated: !!state.auth.currentUser,
		permissionMap,
		currentUser: helpers.isLoggedIn() ? state.auth.currentUser : {
			id: 'mock-user-123',
			buildingId: 'mock-user-123'
		},
		lastUpdated: model.updatedAt,
		dataSources: dataSources,
		vendorName: building?.vendorInfo?.vendorName,
		vendorLink: building?.vendorInfo?.vendorLink,
		vendorUseDefault: (!building || building.buildingId === 'MOCK-DATA-123') || building?.vendorInfo?.useDefault,
		customerBuildings: state.auth.customerBuildings ?? null,
		shareLevel: (!state.auth.currentUser?.primaryBuildingId || !building || building.buildingId === 'MOCK-DATA-123') ? 1 : building?.data_sharing_level || 1,
		hasEnerguide: !!building?.energuide_blob_storage_link,
		hideFinancialData: window.GET_FF('enf-hide-financial-data') && window.ORG_INFO?.theme_json?.hideFinancialData,
		hideRetrofitScenarios: window.GET_FF('enf-hide-retrofit-scenarios-until-l2') && window.ORG_INFO?.theme_json?.hideRetrofitScenariosUntilL2Audit && dataSources && !dataSources.has_survey,
		enerGuideInfo: {
			link: building?.energuide_blob_storage_link,
			file_name: building?.energuide_file_name,
			original_file_name: building?.energuide_original_file_name,
			updated_at: building?.energuide_created_at
		},
		...extra
	};

	if (!ret.currentUser || ret.currentUser.id === 'mock-user-123') {
		ret.assessmentLevel = 0;
	}
	else {
		ret.assessmentLevel = 1;
		if (ret.modelType2) {
			ret.assessmentLevel = 3;
		}
		else if (dataSources.has_survey) {
			ret.assessmentLevel = 2;
		}
	}

	return ret;
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CustomerReportPage)));
