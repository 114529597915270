export const roles = {
    'owner': {
        nameKey: 'homeowner-building-role-owner',
        color: '#E87D1D',
        id: 'owner'
    },
    'co-owner': {
        nameKey: 'homeowner-building-role-co-owner',
        color: '#00989D',
        id: 'co-owner'
    },
    /*'collaborator': {
        nameKey: 'homeowner-building-role-collaborator',
        color: '#E87D1D',
        id: 'collaborator'
    }*/
};

export const roleList = Object.keys(roles).map((key) => roles[key]);
export const roleListNoOwner = roleList.filter((role) => role.id !== 'owner');