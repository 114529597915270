import axios from 'axios';
import config from '../config';
import jwt from "jsonwebtoken";
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { mockUser } from './mock';
import { getBuildingRequest, getBuildingRequestLM, receiveBuilding, receiveBuildings } from './buildings';
import themes from '../components/CustomerPages/Themes';
import i18n from 'i18next';
import { isLoggedIn } from '../helpers/utils';

export const AUTH_FAILURE = 'AUTH_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const PASSWORD_RESET_EMAIL_REQUEST = 'PASSWORD_RESET_EMAIL_REQUEST';
export const PASSWORD_RESET_EMAIL_SUCCESS = 'PASSWORD_RESET_EMAIL_SUCCESS';
export const AUTH_INIT_SUCCESS = 'AUTH_INIT_SUCCESS';
export const AUTH_INIT_ERROR = 'AUTH_INIT_ERROR';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const GET_CUSTOMER_BUILDINGS = 'GET_CUSTOMER_BUILDINGS';

const getCustomerBuildingsRequest = async () => {
  if (!isLoggedIn()) {
    let current = null, primary = null;
    if (!current) {
      current = 'MOCK-DATA-123';
    }
    if (!primary) {
      primary = 'MOCK-DATA-123';
    }
    window.localStorage.setItem('currentBuildingId', current ?? '');
    window.localStorage.setItem('primaryBuildingId', primary ?? '');
    return {data: [], current, primary};
  }
  const response = await axios.get('/user/me/get-buildings');
  /*if (window.localStorage.getItem('impersonating') === 'true') { // to test without backend changes
    response.data[0] = response.data[response.data.length - 1];
    response.data.length = 3;
  }*/
  let current = null, primary = null;
  if (window.localStorage.getItem('currentBuildingId')) {
    for (let i=0; i<response?.data?.length; i++) {
      response.data[i].current = response.data[i].id === window.localStorage.getItem('currentBuildingId');
      response.data[i].primary = response.data[i].id === window.localStorage.getItem('primaryBuildingId');
    }
  }
  for (let i=0; i<response?.data?.length; i++) {
    if (response.data[i].current) {
      current = response.data[i].id;
    }
    if (response.data[i].primary) {
      primary = response.data[i].id;
    }
  }
  if (response?.data?.length && !current) {
    current = response.data[0].id;
  }
  if (response?.data?.length && !primary) {
    primary = response.data[0].id;
  }
  if (!current) {
    current = 'MOCK-DATA-123';
  }
  if (!primary) {
    primary = 'MOCK-DATA-123';
  }
  window.localStorage.setItem('currentBuildingId', current ?? '');
  window.localStorage.setItem('primaryBuildingId', primary ?? '');
  return {data: response.data, current, primary};
}

const findMe = async () =>{
  // window.HIDE_REBATES = !(false/*response.data.org.options.show_rebates*/);
  // window.HIDE_DOLLARS = !(false/*response.data.org.options.show_dollars*/);
  if (config.isBackend) {
    const response = await axios.get('/user/me');
    //if (window.localStorage.getItem('impersonating') === 'true') {
      await getCustomerBuildingsRequest();
      response.data.primaryBuildingId = window.localStorage.getItem('primaryBuildingId');
      response.data.currentBuildingId = window.localStorage.getItem('currentBuildingId');
    //}
    //  Sask Power Oganizations in Patch DB and Prod Simptek DB respectivley
    window.ALLOW_NONOMINAL = response.data.organizationId == "01E7B2CA-2081-EB11-9889-0004FF9D6381" || response.data.organizationId == "6DDB0E6E-4F78-EB11-85AA-DC9840138F01";
    window.IS_SASK_POWER = response.data.organizationId == "01E7B2CA-2081-EB11-9889-0004FF9D6381" || response.data.organizationId == "6DDB0E6E-4F78-EB11-85AA-DC9840138F01";
    window.IS_E1_ORG = response.data.organizationId == "1F46ABF2-6BAC-EC11-997E-28187876E892" || response.data.organizationId == "F88C9618-6012-ED11-95D7-0022483E1A51";

    // E1 Org in Patch DB
    window.HIDE_REBATES = !(response.data.organizationId == "1F46ABF2-6BAC-EC11-997E-28187876E892" || response.data.organizationId == "F88C9618-6012-ED11-95D7-0022483E1A51");
    window.HIDE_AUTO_REBATES = true;
    window.HIDE_DOLLARS = !(response.data.organizationId == "1F46ABF2-6BAC-EC11-997E-28187876E892" || response.data.organizationId == "F88C9618-6012-ED11-95D7-0022483E1A51");

    window.IS_ON_DEMO_ORG = response.data.organizationId == "69AF673A-619B-ED11-994A-000D3AE8B695";
    window.IS_BC_DEMO_ORG = response.data.organizationId == "DD9E8140-2D76-ED11-9D79-6045BD5CD0CD";

    return response.data;
  } else {
    return mockUser;
  }
}

export function authError(payload) {
  return {
    type: AUTH_FAILURE,
    payload,
  };
}

export function getCustomerBuildings(loadPrimary) {
  return async (dispatch) => {
    const {primary, current, data} = await getCustomerBuildingsRequest();
    let buildingId = current ?? primary;
    if (buildingId && loadPrimary) {
      await Promise.all([dispatch(getBuildingRequest(buildingId)), dispatch(getBuildingRequestLM(buildingId))]);
    }
    dispatch({
      type: GET_CUSTOMER_BUILDINGS,
      payload: {
        customerBuildings: data,
      }
    });
  }
};

export function loadCurrentUser() {
  return async (dispatch) => {
    await dispatch(doInit());
  };
};

export function setCurrentBuilding(building_id) {
  return async (dispatch) => {
    window.localStorage.setItem('currentBuildingId', building_id);
    if (window.localStorage.getItem('impersonating') !== 'true') {
      await axios.post('/user/me/set-current-building', {building_id});
    }
    await dispatch(getCustomerBuildings());
    await dispatch(doInit());
    await dispatch(getBuildingRequest(building_id));
    await dispatch(getBuildingRequestLM(building_id));
  }
};

export function claimHome(building_id, onboardInfo, onboardOrgId, themeInfo, tempClaim, vertificationData) {
  return async (dispatch) => {
    try {
      if (onboardInfo) {
        const obres = await axios.post('/buildings/onboard-from-type1-inputs', {
          org_id: onboardOrgId,
          ...onboardInfo,
          verification_data: vertificationData
        });
        if (obres?.data?.success) {
          building_id = obres?.data?.building_id
        }
        else {
          if (obres?.data?.error === 'Address already in use.') {
            toast.error(i18n.t("Unable to claim your home, the address is used by another building."));  
          }
          else {
            toast.error(i18n.t("Unable to claim your home, please try again later."));
          }
          return;
        }
      }
      const response = await axios.post('/user/me/claim-building', {building_id, temporary_claim: !!tempClaim, verification_data: vertificationData});
      if (!tempClaim) {
        await dispatch(getCustomerBuildings());
        await dispatch(doInit());
        await dispatch(setCurrentBuilding(building_id));
        await dispatch(getBuildingRequest(building_id, true));
        localStorage.removeItem('lastUnclaimedBuildingId');
        if (onboardInfo) {
          await dispatch(getBuildingRequestLM(building_id, true));
        }
        toast.success(i18n.t("Sucessfully claimed your home."));
        if (themeInfo.advisorExperience) {
          window.gotoPage('dashboard');
        }
        else {
          window.gotoPage('report');
        }
      }
      else {
        localStorage.setItem('lastUnclaimedBuildingId', building_id);
        await dispatch(doInit());
      }
      return building_id;
    }
    catch (err) {
      if (err?.response?.data?.code === 'already-claimed') {
        toast.error(i18n.t("You are unable to claim this home, because it is currently associated with another user’s account."));
      }
      else if (err?.response?.data?.error === 'Address already in use.') {
        toast.error(i18n.t("You are unable to claim this home, because the address is used by another building."));  
      }
      else {
        toast.error(i18n.t("Unable to claim your home, please try again later."));
      }
    }
  }
};

export function unClaimHome(building_id) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/user/me/un-claim-building', {building_id});
      await dispatch(getCustomerBuildings());
      await dispatch(doInit());
      dispatch(getBuildingRequest(building_id, true));
      toast.success(i18n.t("Successfuly removed a property from your account."));
    }
    catch (err) {
      toast.error(i18n.t("Unable to removed a property from your account."));
    }
  }
};

export function acceptTermsAndConditions() {
  return (dispatch) => {
    axios.post('/user/me/accept-terms-conditions').then(res => {
      dispatch(doInit());
    });
  };
};

export function doInit(customerLogin) {
  return async (dispatch) => {
    let currentUser = null;
    window._HAS_BOTH_SITES = false;
    if (!config.isBackend) {
      currentUser = mockUser;
      if (window._SITE_OVERRIDE) {
        currentUser.app = window._SITE_OVERRIDE;
      }
      if (localStorage.getItem('app') !== currentUser.app) {
        currentUser.app = localStorage.getItem('app');
      }
      if (currentUser.role.en && currentUser.role.ra) {
        window._HAS_BOTH_SITES = true;
      }
      currentUser.role = [ currentUser.role[currentUser.app] ];
      if (currentUser.role.indexOf('customer') >= 0) {
        window.USER_IS_CUSTOMER = true;
      }
      dispatch({
        type: AUTH_INIT_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } else {
      try {
        let token = localStorage.getItem('token');
        if (token) {
          currentUser = await findMe();
          if (window._SITE_OVERRIDE) {
            currentUser.app = window._SITE_OVERRIDE;
          }
          if (localStorage.getItem('app') !== currentUser.app) {
            currentUser.app = localStorage.getItem('app');
          }
          if (currentUser.role.en && currentUser.role.ra) {
            window._HAS_BOTH_SITES = true;
          }
          if (currentUser.role == 'customer') {
            currentUser.role = [ 'customer' ];
          }
          else {
            currentUser.role = [ currentUser.role[currentUser.app] ];
          }
          if (currentUser.role.indexOf('customer') >= 0) {
            window.USER_IS_CUSTOMER = true;
            let decoded = jwt.decode(token);
            window.HOMEOWNER_THEME = decoded.user.theme ?? 'Default';
            localStorage.setItem('theme', window.HOMEOWNER_THEME);    
            if (!window.HIDE_INTERCOM) {
              window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: "puelupwp",
                user_id: decoded?.user?.id,
                name: `${currentUser?.firstName ?? ''} ${currentUser?.lastName ?? ''}` || null,
                email: currentUser?.email,
                created_at: currentUser?.termsAcceptedAt
              });
            }
          }
        }
        dispatch({
          type: AUTH_INIT_SUCCESS,
          payload: {
            currentUser,
          },
        });
      } catch (error) {
        dispatch({
          type: AUTH_INIT_ERROR,
          payload: error,
        });
      }
    }
  }
}

export function logoutUser() {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_REQUEST,
    });
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    let app = localStorage.getItem('app') || 'ra';
    localStorage.removeItem('app');
    axios.defaults.headers.common['Authorization'] = "";
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    window.location.href = '#' + {'en': '/login', 'ra': '/login', 'b360': '/login'}[app];
  };
}

export function logoutCustomer(afterDelete) {
    return (dispatch) => {
      dispatch({
        type: LOGOUT_REQUEST,
      });
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      let app = localStorage.getItem('app') || 'ra';
      localStorage.removeItem('app');
      axios.defaults.headers.common['Authorization'] = "";
      if (!afterDelete) {
        dispatch({
          type: LOGOUT_SUCCESS,
        });
        themes.redirectToLogin(true);
      }
    };
}

export function receiveToken(token) {
    return (dispatch) => {
        let user;
        if (config.isBackend) {
          user = jwt.decode(token)
        } else {
          user = {
            email: config.auth.email,
            user: {
              id: 'default_no_connection_id_444',
            }
          }
        }
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        dispatch({
          type: LOGIN_SUCCESS
        });
        if (!window.USER_IS_CUSTOMER) {
          dispatch(push('/app'));
        }
    }
}

export function loginImpersonate(token, emailAddress) {
  return loginUser({token, emailAddress, is_customer: true}, null, true);
}

export function loginUser(creds,app,impersonate) {
    return async (dispatch) => {
      if (!config.isBackend) {
        dispatch(receiveToken('token'));
        dispatch(doInit());
        dispatch(push('/app'));
      } else {
        if (creds.is_customer) {
          window.USER_IS_CUSTOMER = true;
        }
        dispatch({
          type: LOGIN_REQUEST,
        });
        if (creds.social && !creds.is_customer) {
          window.location.href = config.baseURLApi + "/user/signin/" + creds.social + '?app=' + config.redirectUrl;
        }
        else if (creds.is_customer && (impersonate || creds.token || (creds.phone.length > 0 && creds.pin.length > 0))) {
          localStorage.setItem('app', 'ra');
          creds.theme = window.ORG_INFO?.theme ?? 'Default';
          try {
            let res = await axios.post("/user/signin/local-customer", impersonate ? { impersonate: creds } : creds);
            const token = res.data.token;
            localStorage.setItem('hidden-notif', JSON.stringify({}));
            await dispatch(receiveToken(token));
            window.localStorage.setItem('currentBuildingId', '');
            window.localStorage.setItem('primaryBuildingId', '');
            if (impersonate && creds?.emailAddress) {
              window.localStorage.setItem('impersonating', 'true');
            }
            else {
              window.localStorage.setItem('impersonating', 'false');
            }
            await dispatch(doInit());
            if (res?.data?.user?.id) {
              await dispatch(receiveBuilding({}));
              await dispatch(getBuildingRequestLM(window.localStorage.getItem('currentBuildingId') || res.data.user.id));
              await dispatch(getBuildingRequest(window.localStorage.getItem('currentBuildingId') || res.data.user.id));
            }
            else {
              await dispatch(receiveBuilding({}));
              await dispatch(getBuildingRequestLM());
              await dispatch(getBuildingRequest());
            }
            window.USER_IS_CUSTOMER = true;
            if (!creds.is_customer && !impersonate) {
              localStorage.setItem('crp-intro-show', "yes");
              localStorage.setItem('gotoViewReport', res.data.gotoViewReport ? "yes" : "no");
              localStorage.setItem('gotoRemoteAudit', res.data.gotoRemoteAudit ? "yes" : "no");
            }
          } catch(err) {
            if (!impersonate) {
              dispatch(authError(err.response.data));
            }
            else {
              throw 'impersonate login error';
            }
          }
        }
        else if (!creds.is_customer && creds.email.length > 0 && creds.password.length > 0) {
          axios.post("/user/signin/local", creds).then(res => {
            const token = res.data.token;
            localStorage.setItem('app', res.data.app_name);
            dispatch(receiveToken(token));
            dispatch(doInit());
            if (!creds.is_customer) {
              if (res.data.app_name === 'en') {
                if (res.data.roles.en === 'serviceprovider') {
                  //dispatch(push('/app/main/en-buildings'));
                  dispatch(push('/app/main/en-dashboard'));
                }
                else {
                  dispatch(push('/app/main/en-dashboard'));
                }
              }
              else {
                dispatch(push('/app/main/energy-advisor'));
              }
            }
          }).catch(err => {
            dispatch(authError(err.response.data));
          })
        } else {
          dispatch(authError('Something was wrong. Try again'));
        }
      }
    };
}

export function verifyEmail(token) {
  return(dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/login'));
    } else {
      axios.put("/user/verify-email", {token}).then(verified => {
        if (verified) {
          toast.success(i18n.t("Your email was verified"));
        }
      }).catch(err => {
        toast.error(err.response.data);
      }).finally(() => {
        dispatch(push('/login'));
      })
    }
  }
}

export function resetPassword(token, password) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/login'));
    } else {
      dispatch({
        type: RESET_REQUEST,
      });
      axios.put("/user/password-reset", {token, password}).then(res => {
          dispatch({
            type: RESET_SUCCESS,
          });
          toast.success(i18n.t("Password has been updated"));
        dispatch(push('/login'));
      }).catch(err => {
        dispatch(authError(err.response.data));
      })
    }
  }
}

export function sendPasswordResetEmail(email) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/login'));
    } else {
      dispatch({
        type: PASSWORD_RESET_EMAIL_REQUEST,
      });
      axios.post("/user/send-password-reset-email", {email}).then(res => {
        dispatch({
          type: PASSWORD_RESET_EMAIL_SUCCESS,
        });
        toast.success(i18n.t("Email with resetting instructions has been sent"));
        dispatch(push('/login'));
      }).catch(err => {
        dispatch(authError(err.response.data));
      })
    }
  }
}

export function registerUser(creds) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/login'));
    } else {
      dispatch({
        type: REGISTER_REQUEST,
      });

      if (creds.email.length > 0 && creds.password.length > 0) {
        axios.post("/user/signup", creds).then(res => {
          dispatch({
            type: REGISTER_SUCCESS
          });
          toast.success(i18n.t("You've been registered successfully. Please check your email for verification link"));
          dispatch(push('/login'));
        }).catch(err => {
          dispatch(authError(err.response.data));
        })

      } else {
        dispatch(authError('Something was wrong. Try again'));
      }
    }
  };
}
