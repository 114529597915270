import { colorToRecolorFilter, fadeColor, multiplyColorByScalar } from "../../helpers/colors";

const applyPalette = (theme) => {
    const root = document.querySelector(':root');
    root.style.setProperty('--pal-red', '#E74C3C');
    root.style.setProperty('--pal-red-filter', colorToRecolorFilter('#E74C3C'));
    root.style.setProperty('--pal-red', '#2A500C');
    root.style.setProperty('--pal-red-filter', colorToRecolorFilter('#2A500C'));
    if (theme?.colors?.primary) {
        root.style.setProperty('--pal-primary', theme?.colors?.primary);
        root.style.setProperty('--pal-primary-filter', colorToRecolorFilter(theme?.colors?.primary));
        const faded = fadeColor(theme?.colors?.primary);
        root.style.setProperty('--pal-primary-faded', faded);
        const darker = multiplyColorByScalar(theme?.colors?.primary, 0.7);
        root.style.setProperty('--pal-primary-darker', darker);
    }
    if (theme?.colors?.primary_inv) {
        root.style.setProperty('--pal-primary-inv', theme?.colors?.primary_inv);
    }
    if (theme?.colors?.secondary) {
        root.style.setProperty('--pal-secondary', theme?.colors?.secondary);
        const faded = fadeColor(theme?.colors?.secondary);
        root.style.setProperty('--pal-secondary-faded', faded);
    }
    if (theme?.colors?.secondary_inv) {
        root.style.setProperty('--pal-secondary-inv', theme?.colors?.secondary_inv);
    }
    if (theme?.colors?.footer) {
        root.style.setProperty('--pal-footer', theme?.colors?.footer);
    }
    if (typeof theme?.colors?.cta_button_radius === 'string') {
        root.style.setProperty('--pal-cta-button-radius', theme?.colors?.cta_button_radius);
    }
};

const themes = {
    "autodetect": () => {
        const theme = window.ORG_INFO?.theme ?? "Default";
        let obj = window.ORG_INFO?.theme_json;
        obj.className = 'customer-pages-default-theme';
        applyPalette(obj);
        if (window.ORG_INFO?.theme_config?.logo_url) {
            obj.header.image = window.ORG_INFO?.theme_config?.logo_url;
            obj.login.image = window.ORG_INFO?.theme_config?.logo_url;
        }
        return obj;
    },
    "redirectToLogin": (reload) => {
        /*const theme = themes.autodetect();
        const URL = theme?.login?.loginURL ?? '#/customer-login';
        window.location.href = URL;
        if (reload && URL == '#/customer-login') {
            window.location.reload();
        }
        return URL == '#/customer-login';*/
        return true;
    }
};

export default themes;
