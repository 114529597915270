import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";
import i18next from 'i18next';

import '../Common.scss';
import axios from 'axios';
import { toast } from 'react-toastify';

const { RandomKey } = require('../../../helpers/utils');

class BuildingOwnerVerificationForms extends React.Component {
    constructor(props) {
        super(props);
        this.key = RandomKey();
        this.T = props.t;

        this.state = {
            active: props.active,
            buildingInfo: props.buildingInfo
        }

        this.peiPropertyTaxForm = this.peiPropertyTaxForm.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.active !== prevProps.active || this.props.buildingInfo !== prevProps.buildingInfo) {
            this.setState({ active: this.props.active, buildingInfo: this.props.buildingInfo, rand: Math.random() });
        }
    }

    renderErrorMessage(message) {
        if (message && typeof message === 'string') {
            return <div className='error-message'>{message.split('\n').map((line) => <div>{line}</div>)}</div>;
        }
    }

    checkboxAndSubmit(getVerificationData, valid) {
        return <div className='form-group'>
            <button role="checkbox" aria-checked={this.state.checkbox} className={"claim-check " + (this.state.checkbox ? "claim-check-checked" : "claim-check-unchecked")} onClick={() => this.setState({ checkbox: !this.state.checkbox, rand: Math.random() })}>
                <img alt="" src={this.state.checkbox ? "/images/ctc-checkbox-checked.svg" : "/images/ctc-checkbox-unchecked.svg"} />
                <div className="claim-checkbox-label">{this.T("I am the owner of")} {this.state.buildingInfo.displayAddress}</div>
            </button><br/>
            {this.renderErrorMessage(this.state.errorMessage)}
            <button disabled={!this.state.checkbox || !valid} className={"confirm-claim"} onClick={async () => {
                this.setState({ errorMessage: null });
                let verification_data = getVerificationData();
                let address = this.state.buildingInfo.displayAddress;
                let postalCode = this.state.buildingInfo.postalCode;
                try {
                    let res = await axios.post('/v3/building-ownership-verification', { address, verificationData: verification_data, postalCode });
                    if (Math.floor(res.status / 100) === 2) {
                        this.props.onFinished(getVerificationData());
                    }
                    else {
                        throw { response: res };
                    }
                }
                catch (e) {
                    if (e.response?.data?.code === 'ownership-validation-failed') {
                        this.setState({ errorMessage: this.T('bov-error-message-1-' + (window.ORG_INFO?.theme_json?.ownershipVerificationService ?? 'default').toLowerCase()) });
                    }
                    else if (e.response?.data?.code === 'validation-system-error') {
                        this.setState({ errorMessage: this.T('bov-error-message-2-' + (window.ORG_INFO?.theme_json?.ownershipVerificationService ?? 'default').toLowerCase()) });
                    }
                    else if (e.response?.data?.code === 'wrong-theme' || e.response?.data?.code === 'unknown-theme') {
                        this.setState({ errorMessage: this.T('bov-error-message-postal-code') });
                    }
                }
            }}>{this.T("Claim My Home")}</button>
        </div>
    }

    peiPropertyTaxForm() {
        return (<div className='owner-verification-form pei-property-tax'>
            <div className='info-box'><Trans i18nKey={'pei-owner-verification-info'}>
            To prove that you are the owner of this home, please provide the following information from your Property Tax Statement.<br/>
            <br/>
            If you need assistance, please email _______.<br/>
            <br/>
            <a href="https://www.princeedwardisland.ca/sites/default/files/publications/property_tax_bill_page_1_sample.pdf" target="_blank">View example property tax statement</a>
            </Trans></div>
            <div className='form-group'>
                <label>{this.T('Property Number')}</label>
                <input className={this.state.pidError ? 'input-error' : ''} type='text' placeholder={this.T('Property Number')} onChange={(e) => this.setState({ pid: e.target.value, pidError: !e.target.value })} />
            </div>
            <div className='form-group'>
                <label>{this.T('Access Number')}</label>
                <input className={this.state.naidError ? 'input-error' : ''} type='text' placeholder={this.T('Access Number')} onChange={(e) => this.setState({ naid: e.target.value, naidError: !e.target.value })} />
            </div>
            <div className='form-group'>
                <label>{this.T('Last Payment Amount')}</label>
                <input className={this.state.lastPaymentError ? 'input-error' : ''} type='number' placeholder={this.T('Last Payment Amount')} onChange={(e) => this.setState({ lastPayment: e.target.value !== '' ? parseFloat(e.target.value) : null, lastPaymentError: e.target.value === '' || typeof parseFloat(e.target.value) !== 'number' })} />
            </div>
            {this.checkboxAndSubmit(() => {
                return {
                    pid: this.state.pid,
                    naid: this.state.naid,
                    lastPayment: this.state.lastPayment,
                };
            }, this.state.pid && this.state.naid && (typeof this.state.lastPayment === 'number'))}
        </div>);
    }

    render() {

        if (!this.state.active) {
            return <div />;
        }

        const forms = {
            'pei-property-tax': this.peiPropertyTaxForm,
        };
        const form = window.ORG_INFO?.theme_json?.ownershipVerification ? forms[window.ORG_INFO?.theme_json?.ownershipVerificationService] : null;

        return form ? form() : <div />;

    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(BuildingOwnerVerificationForms)));