import moment from 'moment';
import axios from 'axios';
import config from "../config";
import { toast } from 'react-toastify';
import i18n from 'i18next';
const { listsData } = require('../helpers/audit-enums');
const { isLoggedIn, buildingIsMock } = require('../helpers/utils');
const findOption = listsData.findOption;

export const RECEIVED_BUILDING = 'RECEIVED_BUILDING';
export const RECEIVING_BUILDING = 'RECEIVING_BUILDING';

export const RECEIVED_BUILDINGS = 'RECEIVED_BUILDINGS';
export const RECEIVING_BUILDINGS = 'RECEIVING_BUILDINGS';

export const RECEIVED_BUILDING_LM = 'RECEIVED_BUILDING_LM';
export const RECEIVING_BUILDING_LM = 'RECEIVING_BUILDING_LM';

export const UPDATING_BUILDING = 'UPDATING_BUILDING';
export const UPDATED_BUILDING = 'UPDATED_BUILDING';
export const VA_STATUS_UPDATED = 'VA_STATUS_UPDATED';

export function getBuildingRequest(buildingId, isPublic) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) {
            dispatch(receiveBuilding(''));
        } else {
            dispatch(receivingBuilding(buildingId));

            const process = (res) => {
                let typesOfHousesItem = listsData.list.typesOfHouses.filter(function (x) {
                    return x.value === (res.data.building.typeOfHouse || '').toLowerCase();
                });
                res.data.building.typeOfHouseSelected = typesOfHousesItem;
                let heatPumpSourceSupplyItem = listsData.list.heatPumpSourceSupply.filter(function (x) {
                    return x.value === (res.data.building.heatPumpSourceSupply || '').toLowerCase();
                });
                res.data.building.heatPumpSourceSupplySelected = heatPumpSourceSupplyItem;
                let primaryHotWaterEqTypeItem = listsData.list.primaryHotWaterEqType.filter(function (x) {
                    return x.value === (res.data.building.primaryHotWaterEquipmentType || '').toLowerCase()
                });
                res.data.building.primaryHotWaterEquipmentTypeSelected = primaryHotWaterEqTypeItem;
                let primaryHotWaterEqFuelTypeItem = listsData.list.primaryHotWaterEqFuelType.filter(function (x) {
                    return x.value === (res.data.building.primaryHotWaterEquipmentFuelType || '').toLowerCase()
                });
                res.data.building.primaryHotWaterEquipmentFuelTypeSelected = primaryHotWaterEqFuelTypeItem
                let houseStorysItem = listsData.list.houseStorys.filter(function (x) {
                    return Math.abs(parseFloat(x.value) - res.data.building.stories) < 0.01;
                });
                res.data.building.storiesSelected = houseStorysItem;
                let ventalationTypeItem = listsData.list.ventalationTypes.filter(function (x) {
                    return x.value === (res.data.building.ventalationType || '').toLowerCase()
                });
                res.data.building.ventalationTypeSelected = ventalationTypeItem;
                let secondaryDomesticHotWaterTypesItem = listsData.list.secondaryDomesticHotWaterTypes.filter(function (x) {
                    return x.value === (res.data.building.secondaryDomesticHotWaterType || '');
                });
                res.data.building.secondaryDomesticHotWaterTypeSelected = secondaryDomesticHotWaterTypesItem;
                let primataryHeatingTypesItem = listsData.list.primaryHeatingTypes.filter(function (x) {
                    return x.value === (res.data.building.furnaceType || '').toLowerCase()
                });
                res.data.building.primaryHeatingTypeSelected = primataryHeatingTypesItem;
                let houseTotOccupantsItem = listsData.list.houseTotOccupants.filter(function (x) {
                    return parseInt(x.value) === res.data.building.totalOccupants
                });
                res.data.building.totalOccupantsSelected = houseTotOccupantsItem;
                res.data.building.presenceOfPonyWallsSelected =
                    (res.data.building.presenceOfPonyWalls !== null ?
                        (res.data.building.presenceOfPonyWalls === true ?
                            { value: "Yes", label: "Yes", rating: 'good' }
                            : { value: "NO", label: "No", rating: 'good' })
                        : { value: "Select", label: "Select", rating: 'good' }
                    );
                let tempOfBasementItem = listsData.list.tempOfBasement.filter(function (x) {
                    return parseInt(x.value) === res.data.building.temperatureOfBasement
                });
                res.data.building.temperatureOfBasementSelected = tempOfBasementItem;
                let tempOfMainFloorItem = listsData.list.tempOfMainFloor.filter(function (x) {
                    return parseInt(x.value) === res.data.building.temperatureOfMainFloor
                });
                res.data.building.temperatureOfMainFloorSelected = tempOfMainFloorItem;
                let firePlaceDamperItem = listsData.list.firePlaceDamper.filter(function (x) {
                    return x.value === (res.data.building.firePlaceDamper || '').toLowerCase()
                });
                res.data.building.firePlaceDamperSelected = firePlaceDamperItem;
                let typeOfACSystemItem = listsData.list.typeOfACSystem.filter(function (x) {
                    return x.value === (res.data.building.typeOFACSystem || '').toLowerCase()
                });
                res.data.building.typeOfACSystemSelected = typeOfACSystemItem;
                let suplimentaryHeating1Item = listsData.list.suplimentaryHeating1.filter(function (x) {
                    return x.value === (res.data.building.supplementaryHeatingSystem1 || '').toLowerCase()
                });
                res.data.building.supplementaryHeatingSystem1Selected = suplimentaryHeating1Item;
                if (['softwood', 'hardwood'].indexOf(res.data.building.supplementaryHeatingSystem1Fuel) >= 0) {
                    res.data.building.supplementaryHeatingSystem1Fuel = 'mixed wood';
                }
                let suplimentaryHeating1FuelItem = listsData.list.suplimentaryHeating1Fuel.filter(function (x) {
                    return x.value === (res.data.building.supplementaryHeatingSystem1Fuel || '').toLowerCase();
                });
                res.data.building.supplementaryHeatingSystem1FuelSelected = suplimentaryHeating1FuelItem;
                let suplimentaryHeating2Item = listsData.list.suplimentaryHeating2.filter(function (x) {
                    return x.value === (res.data.building.supplementaryHeatingSystem2 || '').toLowerCase();
                });
                res.data.building.supplementaryHeatingSystem2Selected = suplimentaryHeating2Item;
                if (['softwood', 'hardwood'].indexOf(res.data.building.supplementaryHeatingSystem2Fuel) >= 0) {
                    res.data.building.supplementaryHeatingSystem2Fuel = 'mixed wood';
                }
                let suplimentaryHeating2FuelItem = listsData.list.suplimentaryHeating2Fuel.filter(function (x) {
                    return x.value === (res.data.building.supplementaryHeatingSystem2Fuel || '').toLowerCase();
                });

                res.data.building.supplementaryHeatingSystem2FuelSelected = suplimentaryHeating2FuelItem;
                res.data.building.v2DraftinessSelected = findOption(listsData.list.v2Draftiness, res.data.building.v2Draftiness);
                res.data.building.v2PrimaryHeatingSystemFuelTypeSelected = findOption(listsData.list.v2PrimaryHeatingSystemFuelType, res.data.building.v2PrimaryHeatingSystemFuelType);
                res.data.building.v2PrimaryHeatingSystemHeatpumpSourceSelected = findOption(listsData.list.v2PrimaryHeatingSystemHeatpumpSource, res.data.building.v2PrimaryHeatingSystemHeatpumpSource);
                res.data.building.v2PrimaryHeatingSystemHeatpumpTypeSelected = findOption(listsData.list.v2PrimaryHeatingSystemHeatpumpType, res.data.building.v2PrimaryHeatingSystemHeatpumpType);
                res.data.building.v2PrimaryHeatingSystemTypeSelected = findOption(listsData.list.v2PrimaryHeatingSystemType, res.data.building.v2PrimaryHeatingSystemType);
                res.data.building.v2SecondaryHeatingSystemFuelTypeSelected = findOption(listsData.list.v2SecondaryHeatingSystemFuelType, res.data.building.v2SecondaryHeatingSystemFuelType);
                res.data.building.v2SecondaryHeatingSystemHeatpumpSourceSelected = findOption(listsData.list.v2SecondaryHeatingSystemHeatpumpSource, res.data.building.v2SecondaryHeatingSystemHeatpumpSource);
                res.data.building.v2SecondaryHeatingSystemHeatpumpTypeSelected = findOption(listsData.list.v2SecondaryHeatingSystemHeatpumpType, res.data.building.v2SecondaryHeatingSystemHeatpumpType);
                res.data.building.v2SecondaryHeatingSystemTypeSelected = findOption(listsData.list.v2SecondaryHeatingSystemType, res.data.building.v2SecondaryHeatingSystemType);
                res.data.building.v2WaterHeatingSystemFuelTypeSelected = findOption(listsData.list.v2WaterHeatingSystemFuelType, res.data.building.v2WaterHeatingSystemFuelType);
                res.data.building.v2WaterHeatingSystemTypeSelected = findOption(listsData.list.v2WaterHeatingSystemType, res.data.building.v2WaterHeatingSystemType);
                res.data.building.v2OwnHomeSelected = findOption(listsData.list.v2OwnHome, res.data.building.v2OwnHome);

                dispatch(receiveBuilding(res.data));
            };

            if (!buildingId || buildingId?.toLowerCase().indexOf('mock') === 0) {
                return axios.get('/mock-data/building-get').then(process).catch(() => dispatch(receiveBuilding({error: "no-access"})));
            }
            else if (isPublic) {
                return axios.get('/public/get-building/' + buildingId).then(process).catch(() => dispatch(receiveBuilding({error: "no-access"})));
            }
            else {
                return axios.get('/buildings/' + buildingId).then(process).catch(() => dispatch(receiveBuilding({error: "no-access"})));
            }
        };
    };
}

export function getBuildingsRequest() {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) {
            dispatch(receiveBuildings(''));
        } else {
            dispatch(receivingBuildings());
            return axios.get('/buildings/').then(res => {
                dispatch(receiveBuildings(res.data));
            });
        }
    };
}

export function clearBuildingRequest() {
    return (dispatch) => {
        dispatch(receiveBuildingLM({}));
        dispatch(receiveBuilding({}));
    };
}

export function getBuildingRequestLM(buildingId, isPublic) {

    return async (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) {
            dispatch(receiveBuildingLM(''));
        } else {
            dispatch(receivingBuildingLM());
            const lang = window.localStorage.getItem('i18nextLng') || 'en';
            const process = (res) => {
                if (res.data && res.data.data) {
                    res.data.data.LPU = res.data.dataLPU || null;
                    res.data.data.LPRE = res.data.dataLPRE || null;
                    res.data.data.LPUB = res.data.dataLPUB || null;
                    res.data.data.lastPublished = res.data.lastPublished || null;
                    res.data.data.confidenceScore = res.data.confidenceScore || 0;
                }
                if (!buildingIsMock(buildingId) && isLoggedIn()) {
                    axios.get(`/buildings/notifications`).then(res2 => {
                        if (res.data.data) {
                            res.data.data.notifications = res2?.data?.notifications || [];
                        }
                        dispatch(receiveBuildingLM(res.data.data));
                    }).catch((err) => {
                        dispatch(receiveBuildingLM(res.data.data));
                    });
                }
                else {
                    dispatch(receiveBuildingLM(res.data.data));
                }
            };
            if (!buildingId || buildingId?.toLowerCase()?.indexOf('mock') === 0) {
                return axios.get('/mock-data/latest-model').then(process).catch(() => dispatch(receiveBuildingLM({error: "no-access"})));
            }
            else if (isPublic) {
                return axios.get('/public/get-building-model/' + buildingId).then(process).catch(() => dispatch(receiveBuildingLM({error: "no-access"})));
            }
            else {
                return axios.get('/buildings/' + buildingId + '/latest-model/?lang=' + lang).then(process).catch(() => dispatch(receiveBuildingLM({error: "no-access"})));
            }
        }
    };
}

export function clearBuilding() {
    return (dispatch) => {
        dispatch(receiveBuildingLM({}));
        dispatch(receiveBuilding({}));
    }
}

export function uploadEnerGuidePDF(file, building_id, callbacks) {
    return (dispatch) => {
        if (!isLoggedIn() || buildingIsMock(building_id)) return;
        dispatch(vaStatusUpdate(0));
        dispatch(updatingBuilding());
        const formData = new FormData();
        formData.append("file", file);
        return axios.post(`/buildings/${building_id}/energuide-pdf-upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (ev) => {
                let total = ev.total;
                let percent = (ev.loaded * 100) / total;
                callbacks.progress(percent, total);
            }
        }).then(res => {
            axios.post('/buildings/update-base-model', {building_id, non_survey_update: true})
			.then(() => {
                dispatch(getBuildingRequest(building_id)).then(() => {
                    dispatch(getBuildingRequestLM(building_id)).then(() => {
                        toast.success(i18n.t("EnerGuide Report uploaded."));
                        dispatch(vaStatusUpdate(undefined));
                        dispatch(updatedBuilding(building_id));
                        callbacks.success(res);
                    });
                });
            });
        }).catch(err => {
            callbacks.error(err);
            toast.error(i18n.t("Error uploading EnerGuide Report"));
            dispatch(vaStatusUpdate(undefined));
        });
    };
};

export function deleteEnerGuidePDF(building_id) {
    return (dispatch) => {
        if (!isLoggedIn() || buildingIsMock(building_id)) return;
        return axios.delete(`/buildings/${building_id}/energuide-pdf-delete`).then(res => {
            dispatch(getBuildingRequest(building_id)).then(() => {
                dispatch(getBuildingRequestLM(building_id)).then(() => {
                    toast.success(i18n.t("EnerGuide Report deleted."));
                });
            });
        }).catch(err => {
            toast.error(i18n.t("Error deleting EnerGuide Report"));
        });
    };
};  

export function buildingWizardRequest(building, direction, data) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;
        if (!isLoggedIn() || buildingIsMock(building?.buildingId)) return;

        dispatch(updatingBuilding());
        if (building.upgradeScenariosChecked) {
            dispatch(updatedBuilding({
                buildingId: building.buildingId,
                upgradeScenariosChecked: building.upgradeScenariosChecked
            }));
        }

        return axios({
            url: `/buildings/${building.buildingId}/update-status/${direction}`,
            method: 'PUT',
            data: data
        }).then(res => {
            dispatch(getBuildingRequest(building.buildingId)).then(() => {
                dispatch(getBuildingRequestLM(building.buildingId)).then(() => {
                    dispatch(vaStatusUpdate(undefined));
                    dispatch(updatedBuilding(building.buildingId));
                    toast.success(i18n.t("Building Updated"));
                });
            });
        });
    };
}

export function homeownerAction(buildingId, data) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;
        if (!isLoggedIn() || buildingIsMock(buildingId)) return;

        dispatch(updatingBuilding());

        return axios({
            url: `/buildings/${buildingId}/homeowner-action`,
            method: 'PUT',
            data: data
        }).then(res => {
            dispatch(getBuildingRequest(buildingId)).then(() => {
                dispatch(getBuildingRequestLM(buildingId)).then(() => {
                    dispatch(vaStatusUpdate(undefined));
                    dispatch(updatedBuilding(buildingId));
                });
            });
        });
    };
}

export function createProject(buildingId, serviceOrganizationId) {
    return async (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;
        if (!isLoggedIn() || buildingIsMock(buildingId)) return;

        try {
            let building = (await axios.get('/buildings/' + buildingId))?.data?.building;
            let res;
            if (building?.project?.ProjectId) {
                res = await axios({
                    url: `/v3/orgs/update-project-service-org`,
                    method: 'PUT',
                    data: {
                        projectId: building?.project?.ProjectId,
                        orgId: serviceOrganizationId || undefined
                    }
                });
            }
            else {
                res = await axios({
                    url: `/v3/orgs/initiate-project`,
                    method: 'POST',
                    data: {
                        buildingId,
                        serviceOrganizationId: serviceOrganizationId || undefined,
                        programSponsorId: window.ORG_INFO?.org_id
                    }
                });
            }
            return true;
        } catch (err) {
            if (err.response?.status === 409) {
                return true;
            }
            toast.error('Unable to create project');
            return false;
        }
    };
}

export function homeownerDontShowIntro(homeownerAccountId, buildingId) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;
        if (!isLoggedIn() || buildingIsMock(buildingId)) return;

        dispatch(updatingBuilding());

        return axios({
            url: `/buildings/${homeownerAccountId}/homeowner-dont-show-intro-again`,
            method: 'PUT',
            data: {}
        }).then(res => {
            dispatch(getBuildingRequest(buildingId)).then(() => {
                dispatch(getBuildingRequestLM(buildingId)).then(() => {
                    dispatch(vaStatusUpdate(undefined));
                    dispatch(updatedBuilding(buildingId));
                });
            });
        });
    };
}

export function homeownerViewedReport(buildingId) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;
        if (!isLoggedIn() || buildingIsMock(buildingId)) return;
        return axios({
            url: `/buildings/${buildingId}/homeowner-viewed-report`,
            method: 'PUT'
        });
    };
}

export function updateCustomerPrioritiesRequest(buildingId, priorities) {
    return (dispatch) => {
        // We check if app runs with backend mode
        if (!config.isBackend) return;
        if (!isLoggedIn() || buildingIsMock(buildingId)) return;

        return axios.put('/buildings/' + buildingId + '/priorities', { priorities });
    };
}

export function receiveBuilding(payload) {
    return {
        type: RECEIVED_BUILDING,
        payload
    }
}

export function receivingBuilding() {
    return {
        type: RECEIVING_BUILDING
    }
}

export function receiveBuildings(payload) {
    return {
        type: RECEIVED_BUILDINGS,
        payload
    }
}

export function receivingBuildings() {
    return {
        type: RECEIVING_BUILDINGS
    }
}

export function receiveBuildingLM(payload) {
    return {
        type: RECEIVED_BUILDING_LM,
        payload
    }
}

export function receivingBuildingLM() {
    return {
        type: RECEIVING_BUILDING_LM
    }
}


export function updatingBuilding() {
    return {
        type: UPDATING_BUILDING
    }
}

export function updatedBuilding(payload) {
    return {
        type: UPDATED_BUILDING,
        payload
    }
}

export function vaStatusUpdate(status) {
    return {
        type: VA_STATUS_UPDATED,
        payload: {
            status: status
        }
    };
}
