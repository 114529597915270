import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CDropdown from '../../CustomerPages/components/CDropdown';

import '../Common.scss';

const { RandomKey } = require('../../../helpers/utils');

class DotMenu extends React.Component {
    constructor(props) {
        super(props);
        this.key = RandomKey();

        this.state = {
        };
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        return (
            <CDropdown
                aria-label={this.props['aria-label']}
                style="res-more"
                selChangeEventOnly={true}
                title={"/images/hmp-more-icon-vert.svg"}
                options={this.props.options}
                onChange={(val) => {
                    if (this.props.onSelect) {
                        this.props.onSelect(val?.key);
                    }
                }}
            />
        );
    }
}

export default withRouter(connect(() => ({}))(DotMenu));