import moment from "moment";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./BuildingAcceptInvitePage.scss";
import { withTranslation, Trans } from "react-i18next";
import Themes from "./Themes";
import "./Themes.scss";
import axios from 'axios';
import config from "../../config";
import { toast } from 'react-toastify';
import { loginUser } from '../../actions/auth';

/////

let THEME;

//

class BuildingAcceptInvitePage extends PureComponent {
	constructor(props) {
		super(props);

		this.T = props.t;

		this.state = {
            loading: false
		};

		THEME = this.theme = Themes.autodetect();

        this.acceptInviteClick();
	}

	static propTypes = {
		building: PropTypes.object,
	};

	static defaultProps = {
		building: {},
	};

    async acceptInvite(token) {
        try {
            let resp = await axios.post('/v3/users/invite/acknowledge', {token});
            if (resp.status === 200) {
                return resp.data;
            }
            return false;
        }
        catch (err) {
            console.error('EAAcceptInvitePage: Error accepting invite');
            return false;
        }
    }

    acceptInviteClick() {
        if (window.location.href.indexOf('?token=') > 0) {
            this.setState({ loading: true });
            (async () => {
                let token = window.location.href.split('?token=')[1];
                let res;
                if (res = await this.acceptInvite(token)) {
                    localStorage.setItem('last_login_start_from', 'invite');
                    const langCode = `${this.T('hmp-lang-code')}`.toLowerCase();
	                let tok = `${window.location.href}`.split("#")[0].split('/');
                    let theme = tok.length > 3 ? tok[3] : '';
                    window.localStorage.setItem('invite_redirect_theme', theme);
                    tok.length = Math.min(tok.length, 3);
                    const redirect_uri = tok.join('/');
                    const url = `https://${config.b2cDomain}/climativetest.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_SIGNIN&client_id=c37bab30-9b29-41a7-972b-e09ec1a0e7e6&nonce=defaultNonce&redirect_uri=${encodeURIComponent(redirect_uri)}&scope=openid&response_type=id_token&prompt=login&theme=${this.theme.b2cThemeId||''}&ui_locales=${langCode}`;
                    window.location.href = url;
                }
                else {
                    toast.error(this.T('Unable to sign up.'));
                    setTimeout(() => {
                        window.gotoPage('map');
                    }, 4000);
                }
            })();
        }
    }

	componentDidMount() {
	}

	componentDidUpdate(prevProps) {
	}

	render() {
		document.body.className = "customer-page " + this.theme.className;

		return (
			<div className="customer-dashboard-page">
				<div role="alert">
					<div className={'loading ' + (this.state.loading ? 'loading-show' : 'loading-hide')}>{this.state.loading && <p className="sr-only">{this.T("Loading")}</p>}<img alt="" src='/images/loading-transparent.gif'/>{this.state.loadingClaiming ? <div className="loading-claiming-msg">{this.T("This may take a minute, please wait...")}</div> : undefined}</div>
				</div>
			</div>
		);
	}
}

////

function mapStateToProps(state) {
	return {
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(BuildingAcceptInvitePage)));
