import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";

import '../Common.scss';

const { RandomKey } = require('../../../helpers/utils');
const { changeColorOpacity } = require('../../../helpers/colors');

const levels = [
    {nameKey: 'assessment.level.1', color: '#A2845E', stars: 1},
    {nameKey: 'assessment.level.2', color: '#999', stars: 2},
    {nameKey: 'assessment.level.3', color: '#E5A000', stars: 3}
];

class AssessmentLevel extends React.Component {
    constructor(props) {
        super(props);
        this.key = RandomKey();
        this.T = props.t;

        this.state = {
            level: props.level
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.level !== this.props.level) {
            this.setState({
                level: this.props.level
            });
        }
    }

    renderLevel(levelId) {
        const level = levels[levelId-1];
        return (
            <div key={this.key} className={'en-level-badge'} style={{
                backgroundColor: level.color
            }}>{this.T(level.nameKey)} <img src={level.stars >= 1 ? '/images/white-star.svg' : '/images/empty-star.svg'} />
                                       <img src={level.stars >= 2 ? '/images/white-star.svg' : '/images/empty-star.svg'} />
                                       <img src={level.stars >= 3 ? '/images/white-star.svg' : '/images/empty-star.svg'} />
            </div>
        );
    }

    render() {
        if (!window.GET_FF('enf-assessment-level-indicator')) {
            return <div style={{display: 'none'}}/>;
        }
        if (this.props.isInfoModal) {
            return (<div className='en-level-info-cont'><Trans i18nKey='en-level-info'>
                The different levels of energy assessments are described below.<br/>
                <br/>
                {this.renderLevel(1)}<br/>
                <div className='en-li-info'>A starting point for each home. It represents a typical home like yours, before any upgrades are made.</div>
                {this.renderLevel(2)}<br/>
                <div className='en-li-info'>The questionnaire allows you to improve the assessment with things homeowners often know. For example, if extra insulation was added, or if it has a heat pump.</div>
                {this.renderLevel(3)}<br/>
                <div className='en-li-info'>An assessment by a professional energy advisor. This can be performed either in-person or over a video call.</div>
                <br/>
                <b><i>It is always recommended to get advice from a professional energy advisor before performing any retrofits.</i></b>
            </Trans></div>);
        }
        if (!this.state.level) {
            return <div style={{display: 'none'}}/>;
        }
        if (this.props.levelOnly) {
            return <div className='en-level-cont'>{this.renderLevel(this.state.level)}</div>;
        }
        return (
            <div className='en-level-cont'><button className='en-info-btn' onClick={this.props.onInfoClick} alt={this.T('About assessment levels')}><img src='/images/info-blue.svg' /></button> <span>{this.T('Assessment Level:')}</span> {this.renderLevel(this.state.level)}</div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(connect(mapStateToProps)(AssessmentLevel));