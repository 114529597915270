import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Themes from "./Themes";
import "./Themes.scss";
import moment from "moment";
import axios from "axios";
import FocusLock from 'react-focus-lock';
import { toast } from 'react-toastify';
import { loginImpersonate, logoutCustomer, getCustomerBuildings } from "../../actions/auth";

import { withTranslation, Trans } from "react-i18next";

import "./SupportAgentPage.scss";

/////

const SEARCH_DELAY = 250;

const userSearchEndpoint = async (searchText) => {
    try {
        let resp = await axios.get(`/v3/users/search/${searchText}`);
        return resp.data || [];
    }
    catch (err) {
        return [];
    }
};

class SupportAgentPage extends PureComponent {
	constructor(props) {
		super(props);

		this.T = props.t;

		this.state = {
			modalOpen: null,
            searchText: '',
            searchResults: [],
			selectUserDropdown: false
		};

		this.modalTitleRef = {};
		this.modalCloseClick = this.modalCloseClick.bind(this);
		this.modalOpenClick = this.modalOpenClick.bind(this);
        this.onTypeSearch = this.onTypeSearch.bind(this);
        this.searchTimeout = -1;

		this.theme = Themes.autodetect();

		if (window.location.href.includes('/support-agent')) {
		}
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.show && this.props.show) {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
			const el = document.getElementById('ph-header-cont');
			if (el) {
				el.scrollTop = 0;
			}
		}
	}

    onTypeSearch(searchText) {
        if (searchText.trim().length < 3) {
            this.setState({searchResults: []});
            return;
        }
        if (this.searchTimeout !== -1) {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = -1;
        }
        this.searchTimeout = setTimeout(async () => {
            this.searchTimeout = -1;
            let results = await userSearchEndpoint(searchText);
            results = results.filter(e => !!e.email_address);
            this.setState({searchResults: results});
        }, SEARCH_DELAY);
    }

	static propTypes = {
		building: PropTypes.object,
	};

	static defaultProps = {
		building: {},
	};

	modalCloseClick() {
		return () => {
			this.modalJustOpened = false;
			this.setState({
				modalOpen: null,
				rand: Math.random(),
			});
		};
	}

	modalOpenClick(stateName) {

		if (!this.modalTitleRef[stateName]) {
			this.modalTitleRef[stateName] = React.createRef();
		}

		return () => {
			this.modalJustOpened = true;
			setTimeout(() => {
				if (this.modalJustOpened && this.modalTitleRef[stateName] && this.modalTitleRef[stateName].current) {
					this.modalTitleRef[stateName].current.focus();
					this.modalJustOpened = false;
				}
			}, 100);
			this.setState({
				faqExpand: null,
				modalOpen: stateName,
				rand: Math.random(),
				modalY: this.props.isPreview ? document.getElementById('report-preview-cont').scrollTop : document.documentElement.scrollTop,
			});
		};
	}

	renderModal(stateName, title, contentFn) {
		let fullScreen = false;
		switch (stateName) {
			case 'delete-account':
				fullScreen = true;
				break;
			default: break;
		}
		return (
			<div
				className={"crp-modal-cont " + (fullScreen ? 'crp-modal-fullscreen' : '')}
				style={this.state.modalOpen == stateName ? { display: "block" } : { display: "none" }}
				//onClick={this.modalCloseClick(stateName)}
				aria-label={title + " Modal"}
				role="dialog"
				aria-labelledby="crp-modal-title"
				aria-live="polite" accessibilityLiveRegion="polite"
			>
				{this.state.modalOpen == stateName ? <FocusLock>
					<div
						className="cnp-modal"
						//style={{ top: this.state.modalY + "px" }}
						onClick={(e) => {
							(e || window.event).stopPropagation();
							return false;
						}}
					>
						<div ref={this.modalTitleRef[stateName]} className="crp-modal-header">
							<h2 className="crp-modal-title">{stateName === 'share-more-error' ? <img alt="" src="/images/yellow-warning.svg" className="cst-warning-icon" /> : undefined}{title}</h2>
							<button onClick={this.modalCloseClick(stateName)}><img alt="Close Modal" src="/images/x-icon-black.svg" /></button>
						</div>
						<div className="crp-modal-body">{contentFn()}</div>
					</div>
				</FocusLock> : undefined}
			</div>
		);
	}

	componentDidMount() {
	}

	async signOutFromUser(noMsg) {
		try {
			this.setState({ loading: true });
			await this.props.dispatch(loginImpersonate(this.props.impersonatorToken));
			await this.props.dispatch(getCustomerBuildings(false));
			if (!noMsg) {
				toast.success(this.T('Logged out from user'));
			}
			this.setState({ loading: false });
			return true;
		}
		catch (err) {
			toast.error(this.T('Unable to log out'));
		}
		return false;
	}

	async signInToUser(email) {
		try {
			this.setState({ loading: true });
			const token = this.props.impersonatorToken ?? localStorage.getItem('token');
			await this.props.dispatch(loginImpersonate(token, email));
			await this.props.dispatch(getCustomerBuildings(false));
			toast.success(this.T('Signed in as user'));
			this.setState({ loading: false, selectUserDropdown: false });
		}
		catch (err) {
			toast.error(this.T('Unable to sign in as user'));
		}
		return false;
	}

	render() {

		if (this.props.headerVersion) {

			let isLocked = false,
				isImpersonating = false;
			if (this.props.currentUser && this.props.impersonatorToken) {
				isLocked = false;//this.props.impLock;
				isImpersonating = true;
			}

			return (<div className={"sa-tools-header-inner " + (isLocked ? 'sa-tools-header-locked' : isImpersonating ? 'sa-tools-header-impersonating' : '')}>
				<div className="sah-signed-in-as">{this.props.impersonatorToken ? this.T('Signed In As:') : this.T('No User Selected')}</div>
				<button className="sah-select-user" onClick={() => this.setState({ selectUserDropdown: !this.state.selectUserDropdown })}>{this.props.impersonatorToken ? <>{this.props.currentUser.firstName} {this.props.currentUser.lastName} ({this.props.currentUser.email})</> : <>{this.T('Select a User')}</>} <img src={this.state.selectUserDropdown ? '/images/up-arrow-blue.svg' : '/images/dropdown-arrow.svg'} />
					{this.state.selectUserDropdown ? <div className="sah-select-user-dropdown" onClick={(e) => e.stopPropagation()}>
						<input type="text" placeholder={this.T('Search for user')} value={this.state.searchText} onChange={(e) => { this.setState({ searchText: e.target.value }); this.onTypeSearch(e.target.value); }} /><img src="/images/search-grey.svg" className="sr-search-icon" /><br/>
						{this.state.searchResults.length > 0 ? <div className="sah-search-results">
                            {this.state.searchResults.map((result) => {
                                return (
                                    <button key={result.id} className={"search-result " + (result.email_address === this.props.currentUser.email ? "sr-selected": "")} onClick={async () => {
										if (!this.props.impersonatorToken || (await this.signOutFromUser(true))) {
											await this.signInToUser(result.email_address);
										}
									}}>
                                        <div className="search-result-name">{result.first_name} {result.last_name}</div>
                                        <div className="search-result-email">{result.email_address}</div>
										{result.email_address === this.props.currentUser.email ? <img src={"/images/selected-check.svg"} className="sr-selected-check"/> : undefined}
                                    </button>
                                );
                            })}
                        </div> : undefined}
					</div> : undefined}
				</button>
				{this.props.impersonatorToken ? <button className="sah-signout-from-user" onClick={async () => {
					await this.signOutFromUser();
				}}><img src="/images/ph-signout-icon.svg"/> {this.T('Logout from user')}</button> : undefined}
				{this.state.selectUserDropdown ? <div className="sah-select-user-dropdown-block" onClick={() => this.setState({ selectUserDropdown: false })}></div> : undefined}
			</div>);
		}

		if (this.props.show) {
			document.title = this.T('Support Agent Tools') + ' - ' + this.T('Climative Low Carbon Communities');
			document.body.className = "customer-page " + this.theme.className;
		}

		if (!this.props.show) {
			return (<div />);
		}

		if (!this.props.currentUser || !this.props.currentUserIsSupportAgent) {
			window.location.href = "#/public/homeowner-map";
			return (<div />)
		}

		return (
			<div className="csagent-page-cont">
                <div className={'loading ' + (this.state.loading ? 'loading-show' : 'loading-hide')}><img alt={this.T("Loading Animation")} src='/images/loading-transparent.gif'/></div>
                <div className="csagent-page-inner">
                    <div className="page-title">
                        {this.T('Support Agent Tools')}
                    </div>
                    <div className='page-card'>
                        <div className="sub-title-1">
                            {this.T('Sign-in as Customer')}
                        </div>
                        {this.props.currentUser && this.props.impersonatorToken ? <div className="sub-title">
                            {this.T('Signed in as: ')} {this.props.currentUser.firstName} {this.props.currentUser.lastName} ({this.props.currentUser.email})
                            <button className="signout-from-user-btn" onClick={async () => {
                                await this.signOutFromUser();
                            }}>{this.T('Logout from user')}</button>
                        </div> : undefined}
                        {!(this.props.currentUser && this.props.impersonatorToken) ? <input type="text" placeholder={this.T('Search by email address and/or name')} value={this.state.searchText} onChange={(e) => { this.setState({ searchText: e.target.value }); this.onTypeSearch(e.target.value); }} /> : undefined}
                        {!(this.props.currentUser && this.props.impersonatorToken) ? <div className="search-results">
                            {this.state.searchResults.map((result) => {
                                return (
                                    <div key={result.id} className="search-result">
                                        <div className="search-result-name">{result.first_name} {result.last_name}</div>
                                        <div className="search-result-email">{result.email_address}</div>
                                        <button className="search-result-btn" onClick={async () => {
                                            await this.signInToUser(result.email_address);
                                        }}>{this.T('Sign-in as user')}</button>
                                    </div>
                                );
                            })}
                        </div> : undefined}
                    </div>
                </div>
			</div>
		);
	}
}

////

function mapStateToProps(state) {
	return {
        currentUser: state.auth.currentUser,
		currentUserIsSupportAgent: state.auth.currentUser?.impersonatorAccess,
        impersonatorToken: state.auth.currentUser?.impersonatorToken
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(SupportAgentPage)));
