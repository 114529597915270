import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CDropdown from './CDropdown';
import CStatBlock from './CStatBlock';
import i18next from 'i18next';
import { withTranslation, Trans } from "react-i18next";
import Themes from "../Themes";
import "../Themes.scss";

import './CRetrofitScenariosCard.scss';
import '../CustomerReport.scss';
import CFaqButton from './CFaqButton';
import { listsData } from '../../../helpers/audit-enums';
import { toast } from 'react-toastify';
import axios from 'axios';

const AuditStatus = require('../../../helpers/audit-status');

const { GetRecIcon, GetRecName, GetRSDropdownOpts, GetRSDropdownSubtitle, buildingIsMock, isLoggedIn } = require('../../../helpers/utils');
const { RandomKey } = require('../../../helpers/utils');

let STATUSES, statusEqual, statusGt, statusGtEqual, THEME, ASYNC_STATUSES;

class CRetrofitScenariosCard extends React.Component {
    constructor(props) {
        super(props);

        this.T = props.t;

        this.key = RandomKey();
        this.state = {
            customRetrofitScenarios: [],
            rsDropdownOptions: []
        };

        THEME = this.theme = Themes.autodetect();
        const statObj = AuditStatus(this.theme);
        STATUSES = statObj.STATUSES;
        statusEqual = statObj.statusEqual;
        statusGt = statObj.statusGt;
        statusGtEqual = statObj.statusGtEqual;
        ASYNC_STATUSES = statObj.ASYNC_STATUSES;
    }

    componentDidMount() {
        if (isLoggedIn() && !buildingIsMock(this.props.building.buildingId)) {
            this.refreshCustomRetrofitScenarios();
        }
        else {
            this.updateDDOptions();
        }
    }

    componentWillUnmount() {
    }

    async componentDidUpdate(prevProps) {
        if (isLoggedIn() && (this.props.building?.buildingId !== prevProps.building?.buildingId) && !buildingIsMock(this.props.building.buildingId)) {
            this.setState({customRetrofitScenarios: []});
            await this.refreshCustomRetrofitScenarios();
            this.updateDDOptions();
        }
    }

    updateDDOptions() {
        const opts = GetRSDropdownOpts(this.props.rInfo, this.T, THEME, this.props.type2, this.props.hideFinancialData, this.state.customRetrofitScenarios);
        this.setState({ rsDropdownOptions: opts });
        this.props.updateParentState({ rsDropdownOptions: opts });
    }

    async refreshCustomRetrofitScenarios() {
        try {
            let resp = await axios.get(`/v3/buildings/${this.props.building.buildingId}/retrofit-scenarios`);
            if (Math.floor(resp.status/100) !== 2) {
                throw new Error("Error getting custom retrofit scenarios");
            }
            const list = [];
            for (let rsId in resp.data.retrofitScenarios) {
                list.push(resp.data.retrofitScenarios[rsId]);
            }
            this.setState({ customRetrofitScenarios: list });
            this.updateDDOptions();
            return `custom-${resp.data.latestRetrofitScenarioId}`;
        } catch (err) {
            console.error("Error refreshing custom retrofit scenarios", err);
        }
    }

    async createRetrofitScenario() {
        try {
            this.props.updateParentState({ loading: true });
            let resp = await axios.post('/v3/retrofit-scenarios', {
                retrofitScenarioName: 'Custom Retrofit Scenario',
                buildingId: this.props.building.buildingId,
                measures: []
            });
            if (Math.floor(resp.status/100) !== 2) {
                throw new Error("Error creating retrofit scenario");
            }
            const latest = await this.refreshCustomRetrofitScenarios();
            this.props.updateParentState({ upgradePackageSelected: latest });
            toast.success(this.T("Created retrofit scenario"));
            this.props.updateParentState({ loading: false });
        }
        catch (err) {
            toast.error(this.T("Error creating retrofit scenario"));
            this.setState({ loading: false });
            console.error("Error creating retrofit scenario", err);
        }
    }

    render() {
        let rInfoPackage = this.props.rInfoPackage;
        let recs = rInfoPackage ? rInfoPackage.recommendations : [];

        for (let i = 0; i < recs.length; i++) {
            if (recs[i].is_foundation && this.props.hideFoundationUpgrade) {
                recs.splice(i, 1);
                break;
            }
        }

        return (
            <div key={this.key} className="crp-upgrade-packages-cont">
                <div className="crp-upgrade-packages">
                    <div className="crp-up-title">
                        <span role='heading' aria-level={2}>{this.props.type2 || this.state.rsDropdownOptions.length === 1 ? this.T('Retrofit Scenario') : this.T('Retrofit Scenarios')}</span>
                        <CFaqButton extraClass="crp-faq-button" onClick={this.props.modalOpenClick("faq-upgrades")} id="faq-upgrades-trigger" ariaLabel={this.T('Retrofit Scenarios FAQ')} />
                    </div>
                    {!this.props.type2 && this.state.rsDropdownOptions.length >= 2 ? <div className="crp-up-package-tabs">
                        <div className="crp-select-package-label">{this.T("Select Package")}</div>
                        <CDropdown
                            aria-label={this.T('Select Package')}
                            options={this.state.rsDropdownOptions}
                            error={null}
                            value={this.state.rsDropdownOptions.filter((e) => this.props.upgradePackageSelected == e.key)[0]}
                            defaultValue={this.state.rsDropdownOptions.filter((e) => this.props.upgradePackageSelected == e.key)[0]}
                            emptyText={this.T("Select Retrofit Scenario")}
                            onChange={(val) => {
                                this.props.updateParentState({ upgradePackageSelected: val.key });
                            }}
                            onAction={(action) => {
                                if (action === 'create') {
                                    this.createRetrofitScenario();
                                }
                            }}
                        />
                    </div> : undefined}
                    {!this.props.hideFinancialData ? <div className="crp-cstat-line-1">
                        <CStatBlock className="cstat-20-year-savings" stat1={(rInfoPackage?.rec_totals?.savings_20_year_text ?? '$0')} stat2={this.T(`SAVINGS OVER\n20 YEARS`)} stat2Negative={this.T(`COST OVER\n20 YEARS`)} />
                    </div> : undefined}
                    <div className="crp-cstat-line-2">
                        <CStatBlock className="cstat-emissions" stat1IconAlt={this.T("Reduced")} stat1Icon="/images/emissions-down.svg" stat1={(rInfoPackage?.rec_totals?.percentSave || 0) + "%"} stat2={this.T("EMISSIONS")} />
                        <CStatBlock className="cstat-energy-use" stat1IconAlt={this.T("Reduced")} stat1Icon="/images/energy-use-down.svg" stat1={(rInfoPackage?.rec_totals?.bundle_gj_percentSave || 0) + "%"} stat2={this.T("ENERGY USE")} />
                    </div>
                    <h2 className="crp-incl-upgrades-title">{this.T('Included Retrofits')}</h2>
                    <div className="crp-incl-upgrades-cont">
                        {recs.map((rec) => (<div className="crp-incl-rec">
                            <img alt={""} src={'/images/' + GetRecIcon(rec.recommendations_name, this.T) + '.svg'} />
                            {GetRecName(rec.recommendations_name, this.T, this.props.modelType2)}
                        </div>))}
                    </div>
                    {!this.props.noButtons ? [(<button id={'package-modal-trigger'} className="crp-package-details-btn" onClick={() => {
                        this.props.updateParentState({ mpTabSel: 1, mpBaSel: 1, barFilterMetricFinancial: 'bill-savings-first-year', vcbShow: false });
                        this.props.modalOpenClick("package-modal")();
                    }}>{this.T("Retrofit Scenario Details")}</button>), (<br />),
                    (!this.props.type2 ? <a href="#/app/customer/priorities" className="crp-priorities-link">{this.T("Personalize my Recommendations")} <img alt={this.T("Checkmark")} src='/images/cdb-step-checkbox-button.svg' /></a> : undefined)] : undefined}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    let building = state.buildings.curBuilding.building || {};
    let status = { main: building.highestAuditStatus || 1, sub: building.highestAuditSubStatus || 1 };
    let model = state.buildings.curModel || {};
    let model2 = model.LPUB;

    // Have to have this block here because this function is sometimes called before the constructor
    THEME = Themes.autodetect();
    const statObj = AuditStatus(THEME);
    STATUSES = statObj.STATUSES;
    statusEqual = statObj.statusEqual;
    statusGt = statObj.statusGt;
    statusGtEqual = statObj.statusGtEqual;
    ASYNC_STATUSES = statObj.ASYNC_STATUSES;

    return {
        building,
        currentUser: state.auth.currentUser,
        modelType2: THEME?.advisorExperience ? (status.main >= STATUSES.POST_AWAITING_SIGNUP.main || statusEqual(STATUSES.PRE_PREVIEW_PUBLISHED, status) ? model2 : null) : undefined,
        hideFinancialData: window.GET_FF('enf-hide-financial-data') && window.ORG_INFO?.theme_json?.hideFinancialData
    };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CRetrofitScenariosCard)));