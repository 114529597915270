import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";
import RoleBadge from "../../Common/Modules/RoleBadge"
import AssessmentLevel from "../../Common/Modules/AssessmentLevel";
import CHomeRatingCard from './CHomeRatingCard';
import Themes from "../Themes";
import moment from 'moment';

import "../Themes.scss";
import './CPrintableReport.scss';
import "../../Common/Common.scss";

const helpers = require('../../../helpers/utils');
const AuditStatus = require('../../../helpers/audit-status');

const { RandomKey } = require('../../../helpers/utils');

let STATUSES, statusEqual, statusGt, statusGtEqual, THEME, ASYNC_STATUSES, ENERGUIDE_REPORT_STATUSES;

class CPrintableReport extends React.Component {
    constructor(props) {
        super(props);

        this.T = props.t;
        this.key = RandomKey();
        this.state = {
        };

        THEME = this.theme = Themes.autodetect();

        const statObj = AuditStatus(this.theme);
        STATUSES = statObj.STATUSES;
        statusEqual = statObj.statusEqual;
        statusGt = statObj.statusGt;
        statusGtEqual = statObj.statusGtEqual;
        ASYNC_STATUSES = statObj.ASYNC_STATUSES;
        ENERGUIDE_REPORT_STATUSES = statObj.ENERGUIDE_REPORT_STATUSES;
    }

	getRInfo() {
		if (this.props.type2) {
			return this.props.modelType2 && this.props.modelType2.__report_info;
		} else {
			return this.props.modelType1 && this.props.modelType1.__report_info;
		}
	}

	getModel() {
		if (this.props.type2) {
			return this.props.modelType2;
		} else {
			return this.props.modelType1;
		}
	}

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        if (!THEME?.printReportCopy || window.localStorage.getItem('currentBuildingId') === 'MOCK-DATA-123' || !window.localStorage.getItem('currentBuildingId')) {
            return <div style={{display: "none"}}/>;
        }
        if (this.props.type === 'report') {
            if (!this.props.canPrint) {
                return <div className={'crp-printable-report cant-print'}>{this.T('This page is not printable')}</div>;
            }

            if (!this.props.building || !this.props.building.buildingId || !this.props.customerBuildings) {
                return <div className={'crp-printable-report cant-print'}>{this.T('This page is not printable')}</div>;
            }
            
            const lang = `${this.T('hmp-lang-code')}`.toLowerCase();
            const address = this.props.customerBuildings.find(b => b.id === this.props.building.buildingId)?.address;
            const lastUpdatedDate = moment(this.props.lastUpdated).format('MMMM D, YYYY');
            const currentDate = moment().format('MMMM D, YYYY');
            const summary = ((THEME?.printReportCopy?.summary || {})['level-' + this.props.assessmentLevel] || {})[lang] || '';
            const faqs = (THEME?.printReportCopy?.faqs || {})['level-' + this.props.assessmentLevel] || [];

            document.body.className = "customer-page " + this.theme.className;

            return (<div className={'crp-printable-report'}>
                <img className='org-logo' alt={this.theme.header.alt[lang]} src={this.theme.header.image} /><div className='page-title'>{this.T('Home Energy Report')}</div>
                <br/>
                <div className='home-address'><img src='/images/home-icon-2.svg'/> {address}</div>
                <br/>
                <div className='headers-cont'>
                    <div className='iheader'>
                        <div className='htitle'>{this.T('Assessment Level')}</div>
                        <div className='hvalue'><AssessmentLevel level={this.props.assessmentLevel} levelOnly={true} /></div>
                    </div>
                    <div className='iheader'>
                        <div className='htitle'>{this.T('Date Updated')}</div>
                        <div className='hvalue'>{lastUpdatedDate}</div>
                    </div>
                    <div className='iheader'>
                        <div className='htitle'>{this.T('Date Generated')}</div>
                        <div className='hvalue'>{currentDate}</div>
                    </div>
                </div>
                <div className='summary-text'>{summary.split('\n').map(line => <div className='summary-line'>{line}</div>)}</div>
                <br/>
                <div className='blocks-left'>
                    <div className='energy-rating-block'>
                        <div className='block-header'><img alt="" src="/images/print-eng.jpg"/> {this.T('Energy Rating')}</div>
                        <CHomeRatingCard printPage={true} rInfo={this.getRInfo()} homeRatingTab={2} areaSelected={'fsa'} />
                    </div>
                    <div className='energy-use-breakdown-block'>
                        <div className='block-header'><img alt="" src="/images/print-bd.jpg"/> {this.T('Energy Use Breakdown')}</div>
                        <CHomeRatingCard printPage={true} rInfo={this.getRInfo()} homeRatingTab={3} areaSelected={'fsa'} />
                    </div>
                </div>
                <div className='blocks-right'>
                    <div className='env-impact-block'>
                        <div className='block-header'><img alt="" src="/images/print-env.jpg"/> {this.T('Emissions Rating')}</div>
                        <CHomeRatingCard printPage={true} rInfo={this.getRInfo()} homeRatingTab={1} areaSelected={'fsa'} />
                    </div>
                </div>
                <br/>
                <div className='faqs-cont'>
                    <div className='faqs-title'><img src="/images/faq-icon-print.svg"/> {this.T('Frequently Asked Questions')}</div>
                    <div className='faqs-list'>
                        {faqs.map(faq => <div className='faq-item'>
                            <div className='faq-dot'>•</div>
                            <div className='faq-question'>{faq.question[lang]}</div><br/>
                            <div className='faq-answer'>{faq.answer[lang]}</div>
                        </div>)}
                    </div>
                </div>
                <div className='footer'>
                    <div className='line-1'>{this.T('print-report-footer-1')}</div>
                    <div className='line-2'>{this.T('print-report-footer-2')}</div>
                </div>
            </div>);
        }
        return (
            <div key={this.key} className={'crp-print-cont'}>
                <button className={'crp-print-button'} onClick={() => window.print()}><img src='/images/print.svg' /> {this.T('Print')}</button>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    let building = state.buildings.curBuilding.building || {};
    let model = state.buildings.curModel || {};
    let model2 = model.LPUB;

    // for report preview
    if (ownProps?.isDraft) {
        model2 = model.LPU;
    }

    let dataSources = model?.__report_info?.data_sources || {};

    let showSurveyCTA = !dataSources.has_survey && !!state.auth.currentUser;

    let status = { main: building.highestAuditStatus || 1, sub: building.highestAuditSubStatus || 1 };

    let extra = {};

    if (THEME?.advisorExperience) {
        if (statusGtEqual(status, STATUSES.PRE_PREVIEW_PUBLISHED)) {
            extra.type2 = true;
        }
    }

    let permissionMap = {};
    for (let building of state.auth.customerBuildings ?? []) {
        permissionMap[building.id] = building.permissionType;
    }

    let ret = {
        buildingLoading: state.buildings.isReceiving || state.buildings.isReceivingLM,
        notif_alerts: (model.notifications || []).filter((e) => e.type === 'alert'),
        modelType1: model,
        modelType2: THEME?.advisorExperience ? (ownProps?.isDraft || status.main >= STATUSES.POST_AWAITING_SIGNUP.main || statusEqual(STATUSES.PRE_PREVIEW_PUBLISHED, status) || status.main === STATUSES.UPG_UPGRADES_NOT_STARTED.main ? model2 : null) : undefined,
        showCTA: THEME?.advisorExperience ? ((statusEqual(status, STATUSES.VA_AUTOMATED_INSIGHTS) || statusEqual(status, STATUSES.PRE_PREVIEW_PUBLISHED)) ||
            (statusGtEqual(status, STATUSES.POST_AWAITING_SIGNUP) && !statusGtEqual(status, STATUSES.POST_AWAITING_BOOKING))) : !!state.auth.currentUser,
        showSurveyCTA,
        showPRecCTA: !building.customerPrioritiesJSON && !showSurveyCTA,
        isPostUpgrade: THEME?.advisorExperience ? statusGtEqual(status, STATUSES.POST_AWAITING_SIGNUP) : undefined,
        building,
        status,
        isAuthenticated: !!state.auth.currentUser,
        permissionMap,
        currentUser: localStorage.getItem('token') !== null ? state.auth.currentUser : {
            id: 'mock-user-123',
            buildingId: 'mock-user-123'
        },
        lastUpdated: model.updatedAt,
        dataSources: dataSources,
        vendorName: building?.vendorInfo?.vendorName,
        vendorLink: building?.vendorInfo?.vendorLink,
        vendorUseDefault: (!building || building.buildingId === 'MOCK-DATA-123') || building?.vendorInfo?.useDefault,
        customerBuildings: state.auth.customerBuildings ?? null,
        shareLevel: (!state.auth.currentUser?.primaryBuildingId || !building || building.buildingId === 'MOCK-DATA-123') ? 1 : building?.data_sharing_level || 1,
        hasEnerguide: !!building?.energuide_blob_storage_link,
        hideFinancialData: window.GET_FF('enf-hide-financial-data') && window.ORG_INFO?.theme_json?.hideFinancialData,
        hideRetrofitScenarios: window.GET_FF('enf-hide-retrofit-scenarios-until-l2') && window.ORG_INFO?.theme_json?.hideRetrofitScenariosUntilL2Audit && dataSources && !dataSources.has_survey,
        enerGuideInfo: {
            link: building?.energuide_blob_storage_link,
            file_name: building?.energuide_file_name,
            original_file_name: building?.energuide_original_file_name,
            updated_at: building?.energuide_created_at
        },
        ...extra
    };

    if (!ret.currentUser || ret.currentUser.id === 'mock-user-123') {
        ret.assessmentLevel = 0;
    }
    else {
        ret.assessmentLevel = 1;
        if (ret.modelType2) {
            ret.assessmentLevel = 3;
        }
        else if (dataSources.has_survey) {
            ret.assessmentLevel = 2;
        }
    }

    ret.canPrint = window.localStorage.getItem('token') && window.location.href.includes('/app/customer/view-report');

    return ret;
}

export default withTranslation()(connect(mapStateToProps)(CPrintableReport));