import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import HomeownerMapPage from "../PublicPages/HomeownerMapPage";
import PublicHeader from "../Common/Modules/PublicHeader";
import CustomerReportPage from "../CustomerPages/CustomerReport";
import CustomerWizardPage from "../CustomerPages/CustomerWizard";
import CustomerPriorities from "../CustomerPages/CustomerPriorities";
import CustomerNotifications from "../CustomerPages/CustomerNotifications";
import CustomerSettings from "../CustomerPages/CustomerSettings";
import CustomerTerms from "../CustomerPages/CustomerTerms";
import CustomerDashboard from "../CustomerPages/CustomerDashboard";
import SupportAgentPage from "../CustomerPages/SupportAgentPage";

import SuccessPage from "../CustomerPages/SuccessPage";

import Themes from "../CustomerPages/Themes";
import "../CustomerPages/Themes.scss";
import { getCurrentPage, isLoggedIn } from "../../helpers/utils";

let THEME = null;

class Layout extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		isUpdating: PropTypes.bool,
	};

	static defaultProps = {
		isUpdating: false,
	};

	constructor(props) {
		super(props);

		this.state = { loaderActive: this.props.isUpdating };

		THEME = this.theme = Themes.autodetect();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isUpdating !== this.props.isUpdating) {
			this.setState({ loaderActive: this.props.isUpdating });
		}
		if (prevProps.vaStatus !== this.props.vaStatus) {
			this.setState({ vaStatus: this.props.vaStatus });
		}
	}

	render() {

		let isCustomerURL = window.location.href.indexOf('/app/customer') >= 0 || window.location.href.indexOf('add-files') >= 0;
		let isPublicURL = window.location.href.indexOf('/public/') >= 0;

		document.body.className = "customer-page " + THEME.className;

		if (!this.props.currentUser && !isCustomerURL && !isPublicURL) {
			return <div />;
		}

		const isCustomer = true;

		if (!this.props.currentUser && localStorage.getItem('token')) {
			return <div/>;
		}

		const showTerms = isCustomer && isLoggedIn() && !this.props.currentUser.termsAcceptedAt;

		if (getCurrentPage() === 'map') {
			if (!this.state.mapInit) {
				setTimeout(() => {
					this.setState({ mapInit: true });
				}, 1);
			}
		}

		if (localStorage.getItem('invite_redirect_theme') && window.location.href.indexOf('-invitation') >= 0) {
			return (<div/>);
		}

		return (<PublicHeader key={'public-header'} getCallbackRefs={(refs) => {
			if (refs.openLoginModal) {
				window._openLoginModal = refs.openLoginModal
			}
		}}>
			{showTerms ? <CustomerTerms /> : undefined}
			<CustomerReportPage key="customer-report-page" show={!showTerms && getCurrentPage() === 'report'} openLoginModal={() => window._openLoginModal()} />
			<CustomerWizardPage key="customer-wizard-page" show={!showTerms && getCurrentPage() === 'wizard'} />
			<CustomerPriorities key="customer-priorities-page" show={!showTerms && getCurrentPage() === 'priorities'} />
			<CustomerNotifications key="customer-notif-page" show={!showTerms && getCurrentPage() === 'notif'} />
			<CustomerSettings key="customer-settings-page" show={!showTerms && getCurrentPage() === 'settings'} />
			<SupportAgentPage key="support-agent-page" show={!showTerms && getCurrentPage() === 'support-agent'} />
			{this.theme.advisorExperience ? <CustomerDashboard key="customer-dashboard-page" show={!showTerms && getCurrentPage() === 'dashboard'} /> : undefined}
			<SuccessPage key="success-page" show={!showTerms && window.location.href.indexOf("/app/customer/success") >= 0} type={window.location.href.split("/app/customer/success/")[1]} />
			{this.state.mapInit && <HomeownerMapPage key="homeowner-map-page" show={getCurrentPage() === 'map'} onHeaderClick={this.state.onHeaderClick} openLoginModal={() => window._openLoginModal()} />}
		</PublicHeader>);
	}
}

function mapStateToProps(store) {
	return {
		isUpdating: store.buildings.isUpdating,
		vaStatus: store.buildings.vaStatus,
		currentUser: store.auth.currentUser,
		currentUserIsSupportAgent: store.auth.currentUser?.impersonatorAccess
	};
}

export default withRouter(connect(mapStateToProps)(Layout));
