import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";

import '../Common.scss';

const { RandomKey } = require('../../../helpers/utils');
const { roles } = require('../../../helpers/role-enum');
const { changeColorOpacity } = require('../../../helpers/colors');

class RoleBadge extends React.Component {
    constructor(props) {
        super(props);
        this.key = RandomKey();
        this.T = props.t;

        this.state = {
            role: props.role
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.role !== this.props.role) {
            this.setState({
                role: this.props.role
            });
        }
    }

    render() {
        let role = roles[this.state.role];
        if (!role) {
            return <div style={{display: 'none'}}/>;
        }
        return (
            <div key={this.key} className={'en-role-badge ' + (this.props.slim ? 'slim' : '')} style={{
                backgroundColor: changeColorOpacity(role.color, 0.15),
                color: role.color,
                border: '1px solid ' + role.color
            }}>{this.T(role.nameKey)}</div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(RoleBadge)));