import moment from "moment";
import { getBuildingRequest, getBuildingRequestLM, downloadReportRequest } from "../../actions/buildings";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import axios from "axios";
import Themes from "./Themes";
import CDataDisclaimer from "./components/CDataDisclaimer.js";
import CDropdown from "./components/CDropdown.js";
import FocusLock from 'react-focus-lock';
import "./Themes.scss";

import { withTranslation, Trans } from "react-i18next";
import i18next from 'i18next';

import "./CustomerWizard.scss";
import { isLoggedIn } from "../../helpers/utils.js";

const { listsData } = require('../../helpers/audit-enums');

/////

const getList = (key, otherKey, model) => {
	let ret = [];
	for (let value of listsData.list[key]) {
		ret.push({ ...value, label: undefined, title: value.label });
	}
	if (otherKey) {
		for (let oValue of listsData.list[otherKey]) {
			if (oValue.enabled_if) {
				let oList = oValue.enabled_if.value;
				if (!oList) {
					oList = [];
				}
				else if (typeof oList == 'string') {
					oList = [ oList ];
				}
				for (let other of oList) {
					for (let value of ret) {
						if (value.value == other) {
							value.enables = value.enables || { model, value: [] };
							value.enables.value.push(oValue.value);
						}
					}
				}
			}
		}
	}
	for (let i=0; i<ret.length; i++) { // move unspecified to end
		if (ret[i].value == null) {
			let opt = ret[i];
			ret.splice(i, 1);
			ret.push(opt);
			break;
		}
	}
	return ret;
};

class CustomerWizardPage extends PureComponent {
	constructor(props) {
		super(props);

		this.T = props.t;

		this.state = {
			model: {},
			rand: 0,
			step: 0,
			finished: false,
			buildingInfoLoaded: false,
		};

		this.unitFields = [
			'total_floor_area_sqft',
			'footprint'
		];

		this.introSurveyData = {
			title: "Minimal Home Details",
			questions: [
				"house_type",
				"year_built",
				"storeys",
				"total_floor_area_sqft",
				"footprint"
			]
		};

		this.fieldTitles = {
			"house_type": "Type Of House",
			"year_built": "Year Of Construction",
			"storeys": "Floors Above Grade",
			"total_floor_area_sqft": "Total Heated Floor Area",
			"heated_basement": "Heated Basement",
			"footprint": "Building Footprint"
		};

		const CammelCase = (str) => {
			let tmp = `${str ?? 'None'}`.toLowerCase();
			let tok = tmp.split(/\s+/g);
			for (let i=0; i<tok.length; i++) {
				tok[i] = tok[i][0].toUpperCase() + tok[i].substring(1);
			}
			tmp = tok.join(' ');
			tok = tmp.split(/\//g);
			for (let i=0; i<tok.length; i++) {
				tok[i] = tok[i][0].toUpperCase() + tok[i].substring(1);
			}
			return this.T(tok.join('/'));
		};

		this.fieldFormatters = {
			"house_type": CammelCase,
			"year_built": (v) => `${v}`,
			"storeys": (v) => `${v}`,
			"total_floor_area_sqft": (v) => `${Math.round(v)} ${this.T('sqft')}`,
			"heated_basement": (v) => this.T(v ? 'Yes' : 'No'),
			"footprint": (v) => `${Math.round(v)} ${this.T('sqft')}`
		};

		this.fieldRemappers = { // so that new questions can map to old model values
			//'new_key': 'old_key'
		};

		this.steps = [
			{
				title: "Basic Info",
				questions: [ "house_type", "year_built", "foundation_type", "storeys", "finished_basement", "~areas", "preferred_area_units", "total_floor_area_sqft", "footprint" ]
			},
			{
				title: "Space Heating",
				questions: [ "primary_heating_fuel_type", "primary_heating_system", "primary_heating_system_year", "has_secondary_heating_system", "secondary_heating_fuel_type", "secondary_heating_system", "secondary_heating_system_year" ]
			},
			{
				title: "Space Cooling",
				questions: [ "has_air_conditioner", "air_conditioner_type", "air_conditioner_year" ]
			},
			{
				title: "Water Heating",
				questions: [ "water_heating_fuel_type", "water_heating_system", "water_heating_system_year", "water_heating_rented_or_owned" ]
			},
			{
				title: "Windows & Doors",
				questions: [ "~windows", "windows_upgraded", "windows_year", "windows", "~exterior_doors", "exterior_doors_upgraded", "exterior_doors_year", "exterior_doors" ]
			},
			{
				title: "Insulation",
				questions: [ "~ceiling_insulation", "ceiling_insulation_upgraded", "ceiling_insulation_year", "~foundation_insulation", "foundation_insulation_upgraded", "foundation_insulation_year", "~wall_insulation", "wall_insulation_upgraded", "wall_insulation_year" ]
			},
			{
				title: "Other",
				questions: [ "has_electric_vehicle_charger", "has_solar_pv_system" ]
			}
		];

		if (window.GET_FF('enf-survey-heating-system-questions')) {
			this.steps[1].questions = [
				'has_heat_pump',
				'heat_pump_type',
				'heat_pump_year',
				'non_hp_primary_heating_fuel_type',
				'non_hp_primary_heating_system',
				'non_hp_primary_heating_system_year',
			];
			// TODO: Copy info from old fields to heat pump fields on load based on primary_heating_system and primary_heating_system_year
			this.fieldRemappers = {
				'non_hp_primary_heating_fuel_type': 'primary_heating_fuel_type',
				'non_hp_primary_heating_system': 'primary_heating_system',
				'non_hp_primary_heating_system_year': 'primary_heating_system_year',
			};
		}

		if (window.GET_FF('enf-has-hrv-question')) {
			this.steps[1].questions.push("has_hrv");
		}

		let qNo = 1;
		for (let step of this.steps) {
			step.startNo = qNo;
			qNo += step.questions.length;
		}

		this.textOnChange = this.textOnChange.bind(this);
		this.buttonOnClick = this.buttonOnClick.bind(this);
		this.toggleOnClick = this.toggleOnClick.bind(this);
		this.nextStep = this.nextStep.bind(this);
		this.prevStep = this.prevStep.bind(this);
		this.reorderBubble = this.reorderBubble.bind(this);

		this.canvasRef = React.createRef();

		this.theme = Themes.autodetect();
	}

	static propTypes = {
		building: PropTypes.object,
	};

	static defaultProps = {
		building: {},
	};

	componentDidMount() {
		if (this.props.introSurvey) {
			return;
		}
		const buildingId = this.props.currentUser?.currentBuildingId ?? this.props.currentUser?.primaryBuildingId;
		if (buildingId && this.props.show) {
			this.props.dispatch(getBuildingRequest(buildingId));
			this.props.dispatch(getBuildingRequestLM(buildingId));
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.introSurvey) {
			return;
		}

		if (!prevProps.show && this.props.show) {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
			const el = document.getElementById('ph-header-cont');
			if (el) {
				el.scrollTop = 0;
			}
			this.setState({ finished: false, step: 0 });
			const buildingId = this.props.currentUser?.currentBuildingId ?? this.props.currentUser?.primaryBuildingId;
			if (buildingId && this.props.show) {
				this.props.dispatch(getBuildingRequest(buildingId));
				this.props.dispatch(getBuildingRequestLM(buildingId));
				this.setState({ buildingInfoLoaded: false });
				return;
			}
		}

		this.renderProgessCircle(this.state.step);
		if (this.props.building && this.props.model?.id && this.props.model?.id !== "MOCK-DATA-123" && !this.state.buildingInfoLoaded) {
			axios.get("/buildings/customer-wizard-result", {}).then(async (res) => {
				let model = this.state.model;
				let building = this.props.building;

				if (res.data && res.data.model) {
					model = this.state.model = res.data.model;
				} else {
					model.house_type = `${this.props.model?.property?.house_type}`.toLowerCase();
					model.primary_heating_fuel_type	= `${this.props.model?.property?.primary_heating_fuel_type}`.toLowerCase();
					model.primary_heating_system = `${this.props.model?.property?.primary_heating_type}`.toLowerCase();
					model.water_heating_fuel_type = building?.property?.primaryHotWaterEquipmentFuelType ? `${building?.property?.primaryHotWaterEquipmentFuelType}`.toLowerCase() : undefined;
					model.year_built = building.yearBuilt;
					model.storeys = building.stories;
					model.total_floor_area_sqft = building.floorArea;
					model.footprint = building.footprint;
					model.exterior_doors = building.totalNumberOfInstalledDoors;
					model.windows = building.totalNumberOfInstalledWindows;
					model.estar_windows_percent = Math.round((building.totalNumberOfInstalledEStarWindows / model.windows) * 4) * 25;
					model.estar_doors_percent = Math.round((building.totalNumberOfInstalledEStarDoors / model.exterior_doors) * 4) * 25;
				}

				if (window.GET_FF('enf-survey-heating-system-questions')) {
					if (model.primary_heating_system === 'heat pump') {
						model.has_heat_pump = true;
						model.heat_pump_type = undefined;
						model.heat_pump_year = model.primary_heating_system_year;
						model.primary_heating_system = undefined;
						model.primary_heating_system_year = undefined;
					}
				}

				let invFieldRemapping = {};
				for (let key in this.fieldRemappers) {
					invFieldRemapping[this.fieldRemappers[key]] = key;
				}

				for (let key in model) {
					if (key in invFieldRemapping) {
						model[invFieldRemapping[key]] = model[key];
					}
				}

				if (!model.preferred_area_units) {
					let count = { 'sqft': 0, 'sqm': 0 };
					for (let key in model) {
						let units = model[key + '_units'];
						if (units) {
							count[units] += 1;
						}
					}
					model.preferred_area_units = count['sqft'] >= count['sqm'] ? 'sqft' : 'sqm'
				}

				if (model.foundation_type === 'split') {
					model.storeys = 1.5;
				}

				for (let key in model) {
					let val = model[key];
					if (this.unitFields.indexOf(key) >= 0) {
						model[key + '_units'] = model.preferred_area_units;
						let units = model[key + '_units'];
						if (typeof val == 'number' && units) {
							if (units == 'sqm') {
								model[key] = val / 10.7639;
							}
						}
					}
				}

				for (let key of this.unitFields) {
					if (!model[key + '_units']) {
						model[key + '_units'] = 'sqft';
					}
				}
		
				this.setState({
					model: model,
					buildingInfoLoaded: true,
					disabled_edits: this.props.model?.property?.disabled_edits || {},
					data_source: this.props.model?.property?.data_source,
					any_disabled_edits: Object.keys(this.props.model?.property?.disabled_edits || {}).length > 0,
					rand: Math.random(),
				});
			});
		}
	}

	jumpToStep(step) {
		this.nextStep(step, false);
	}

	skipToEnd() {
		this.nextStep(this.steps.length - 1, true);
	}

	nextStep(jumpTo, skipToEnd) {
		if (this.state.formError) {
			return;
		}

		const isJumping = typeof jumpTo === 'number';
		if (skipToEnd) {
			jumpTo = this.steps.length;
		}
		const nextStep = this.state.step + 1;

		this.setState({ is_saving: true });

		let model = this.state.model;

		if (!model.hp_capacity_btu_hr) {
			if (model.hp_year && model.total_floor_area_sqft && model.hp_area_percent) {
				let sqft = (model.total_floor_area_sqft * model.hp_area_percent) / 100;
				model.hp_capacity_btu_hr = Math.round(/*0.293 * */(12000 * Math.ceil((sqft / 500) * 2)) / 2);
			}
		}
		if (!model.secondary_hp_capacity_btu_hr) {
			if (model.secondary_hp_year && model.total_floor_area_sqft && model.secondary_hp_area_percent) {
				let sqft = (model.total_floor_area_sqft * model.secondary_hp_area_percent) / 100;
				model.secondary_hp_capacity_btu_hr = Math.round(/*0.293 * */(12000 * Math.ceil((sqft / 500) * 2)) / 2);
			}
		}
		if (!model.hp_efficiency) {
			if (model.hp_year) {
				let hspf = 6.8;
				if (model.hp_year < 7) {
					hspf = 8.2;
				} else if (model.hp_year < 16) {
					hspf = 7.7;
				}
				model.hp_efficiency = Math.round(1000 * hspf /* * 0.293*/) / 1000;
			}
		}
		if (!model.secondary_hp_efficiency) {
			if (model.secondary_hp_year) {
				let hspf = 6.8;
				if (model.secondary_hp_year < 7) {
					hspf = 8.2;
				} else if (model.secondary_hp_year < 16) {
					hspf = 7.7;
				}
				model.secondary_hp_efficiency = Math.round(1000 * hspf /* * 0.293*/) / 1000;
			}
		}
		if (model.foundation_type === 'split') {
			model.storeys = 1.5;
		}
		this.setState({ model, rand: Math.random() });
		let tempModel = JSON.parse(JSON.stringify(model));

		for (let key in tempModel) {
			if (this.unitFields.indexOf(key) >= 0) {
				let val = tempModel[key];
				tempModel[key + '_units'] = tempModel.preferred_area_units || 'sqft';
				let units = tempModel[key + '_units'];
				if (typeof val == 'number' && units) {
					if (units == 'sqm') {
						tempModel[key] = val * 10.7639;
					}
				}
			}
		}

		for (let key in tempModel) {
			if (key in this.fieldRemappers) {
				tempModel[this.fieldRemappers[key]] = tempModel[key];
			}
		}

		window.TRACK_EVENT({eventId: 'questionnaire-step-completed', step: nextStep});
		axios.put("/buildings/customer-wizard-result", { model: tempModel }).then(async (res) => {
			if (skipToEnd) {
				this.setState({ step: this.steps.length-1 });
			}
			else if ((nextStep < this.steps.length) || isJumping) {
				this.setState({ step: isJumping ? jumpTo : (nextStep), is_saving: false });
			}

			if (((nextStep >= this.steps.length) && !isJumping) || skipToEnd) {  //after click Finish button
				const buildingId = this.props.building?.buildingId;
				const address = this.props.building?.address;
				tempModel.building_id = buildingId;
				tempModel.address = address;
				tempModel.b_obj = this.props.building;
				tempModel.data_source = this.state.data_source ?? 'HOMEOWNER';
				axios.post('/buildings/update-base-model', tempModel)
				.then(() => {
					axios.post('/buildings/survey-updated-notification', tempModel).then(() => {
						this.props.dispatch(getBuildingRequest(buildingId)).then(() => {
							this.props.dispatch(getBuildingRequestLM(buildingId)).then(() => {
								window.TRACK_EVENT({eventId: 'questionnaire-submitted'});
								this.setState({ is_saving: false });
								window.location.href = "#/app/customer/success/survey";
							});
						});
					})
				})
				.catch(function (error) {
					console.log(error);
				});
			}
		});
	}

	introSurveyClick() {
		let model = this.state.model;
		let tempModel = JSON.parse(JSON.stringify(model));

		for (let key of this.introSurveyData.questions) {
			if (model.preferred_area_units === 'sqm') {
				tempModel[key] = Math.round(tempModel[key] * 10.7639);
			}
			tempModel[key + "_units"] = undefined;
			delete tempModel[key + "_units"];
		}

		if (this.props.introSurveyCallback) {
			this.props.introSurveyCallback(tempModel);
		}
	}

	introSurveyComplete() {
		let model = this.state.model;
		for (let key of this.introSurveyData.questions) {
			if (!model[key]) {
				return false;
			}
		}
		return true;
	}

	prevStep() {
		this.setState({ step: this.state.step - 1 });
	}

	renderProgessCircle(step) {
		let total = this.steps.length;
		if (!this.canvasRef || !this.canvasRef.current) {
			return;
		}
		let ctx = this.progressCtx;
		if (!ctx) {
			ctx = this.canvasRef.current.getContext("2d");
		}
		if (!ctx) {
			return;
		}
		let styles = window.getComputedStyle(this.canvasRef.current);
		let color = styles.getPropertyValue("color");
		let width = this.canvasRef.current.width,
			height = this.canvasRef.current.height;
		let radius = height / 2 - height / 10;
		ctx.clearRect(0, 0, width, height);

		let a1 = Math.PI / 2;
		let a2 = ((step + 1) / total) * Math.PI * 2;

		ctx.lineWidth = height / 5;
		ctx.beginPath();
		ctx.arc(width / 2, height / 2, radius, 0, Math.PI * 2);
		ctx.strokeStyle = "#ECF1F5";
		ctx.stroke();
		ctx.beginPath();
		ctx.arc(width / 2, height / 2, radius, a1, a1 + a2);
		ctx.strokeStyle = color;
		ctx.stroke();
	}

	textOnChange(model, min, max, error_min, error_max) {
		return (e) => {
			e = e || window.event;
			let val = parseFloat(e.target.value);
			if (min < 10 && typeof val == 'number' && !error_min) {
				val = Math.max(min, val);
			}
			if (typeof max == 'number' && typeof val == 'number' && !error_max) {
				val = Math.min(max, val);
			}
			this.state.model[model + '_error'] = undefined;
			if (error_min && val < min) {
				this.state.model[model + '_error'] = error_min;
			}
			if (error_max && val > max) {
				this.state.model[model + '_error'] = error_max;
			}
			this.state.model[model] = val;
			if (this.fieldRemappers && this.fieldRemappers[model]) {
				this.state.model[this.fieldRemappers[model]] = val;
			}
			this.setState({
				model: this.state.model,
				rand: Math.random(),
				formError: Object.keys(this.state.model).filter((e) => e.indexOf('_error') >= 0 && this.state.model[e]).length ? true : false
			});
		};
	}

	buttonOnClick(model, val, set_other, enables) {
		return (e) => {
			let lastVal = this.state.model[model];
			this.state.model[model] = val;
			if (this.fieldRemappers && this.fieldRemappers[model]) {
				this.state.model[this.fieldRemappers[model]] = val;
			}
			if (set_other) {
				this.state.model[set_other.model] = set_other.value;
				if (this.fieldRemappers && this.fieldRemappers[set_other.model]) {
					this.state.model[this.fieldRemappers[set_other.model]] = set_other.value;
				}
			}
			/*if (model.indexOf("_units") >= 0 && model.indexOf("_units") == (model.length - "_units".length)) {
				let valModel = `${model}`.replace("_units", "");
				if (lastVal != val && lastVal) {
					if (val == 'sqm') {
						this.state.model[valModel] = Math.round(this.state.model[valModel] / 10.7639);
					}
					else {
						this.state.model[valModel] = Math.round(this.state.model[valModel] * 10.7639);
					}
				}
			}*/
			if (model === 'preferred_area_units') {
				if (val !== lastVal && lastVal) {
					for (let key in this.state.model) {
						if (this.unitFields.indexOf(key) >= 0) {
							this.state.model[key + '_units'] = val;
							if (val === 'sqm') {
								this.state.model[key] = Math.round(this.state.model[key] / 10.7639);
							}
							else {
								this.state.model[key] = Math.round(this.state.model[key] * 10.7639);
							}
							if (this.fieldRemappers && this.fieldRemappers[key]) {
								this.state.model[this.fieldRemappers[key]] = val;
							}
						}
					}
				}
			}
			if (enables) {
				if (enables.value.length == 1) {
					this.state.model[enables.model] = enables.value[0];
					if (this.fieldRemappers && this.fieldRemappers[enables.model]) {
						this.state.model[this.fieldRemappers[enables.model]] = enables.value[0];
					}
				}
				else {
					if (enables.value.indexOf(this.state.model[enables.model]) == -1) {
						this.state.model[enables.model] = null;
						if (this.fieldRemappers && this.fieldRemappers[enables.model]) {
							this.state.model[this.fieldRemappers[enables.model]] = null;
						}
					}
				}
			}
			this.setState({ model: this.state.model, rand: Math.random() });
		};
	}

	toggleOnClick(model, val) {
		return (e) => {
			this.state.model[model] = this.state.model[model] || [];
			let idx = this.state.model[model].indexOf(val);
			if (idx >= 0) {
				this.state.model[model].splice(idx, 1);
			} else {
				this.state.model[model].push(val);
			}
			this.state.model[model] = this.state.model[model].sort();
			if (this.fieldRemappers && this.fieldRemappers[model]) {
				this.state.model[this.fieldRemappers[model]] = this.state.model[model];
			}
			this.setState({ model: this.state.model, rand: Math.random() });
		};
	}

	reorderBubble(order, model, value, dir) {
		return (e) => {
			if (!this.state.model[model] || this.state.model[model].length != order.length) {
				this.state.model[model] = order;
			}
			let array = this.state.model[model];
			let idx = array.indexOf(value);
			if (idx > 0 && dir < 0) {
				let tmp = array[idx - 1];
				array[idx - 1] = value;
				array[idx] = tmp;
			}
			if (idx < order.length - 1 && dir > 0) {
				let tmp = array[idx + 1];
				array[idx + 1] = value;
				array[idx] = tmp;
			}
			if (this.fieldRemappers && this.fieldRemappers[model]) {
				this.state.model[this.fieldRemappers[model]] = this.state.model[model];
			}
			this.setState({ model: this.state.model, rand: Math.random() });
		};
	}

	renderInputBox(model, type, label, placeholder, width, min, max, error_min, error_max) {
		let id = `cwp-input-${Math.floor(Math.random()*1e9)}`;
		return (
			<div className="cwp-input-cont" style={{ width: width }}>
				<label for={id} className="cwp-i-label">{this.T(label)}</label>
				<input
					id={id}
					className={"cwp-i-input " + (this.state.model[model + '_error'] ? 'cwp-i-input-error' : '')}
					type={type}
					placeholder={this.T(placeholder)}
					aria-invalid={this.state.model[model + '_error'] ? "true" : "false"}
					aria-describedby={'error-message-' + model}
					value={this.state.model[model] == null ? '' : this.state.model[model]}
					onWheel={() => document.activeElement.blur()}
					onChange={this.textOnChange(model, min, max, error_min, error_max)}
					onKeyDown={this.textOnChange(model, min, max, error_min, error_max)}
					onKeyUp={this.textOnChange(model, min, max, error_min, error_max)}
					onBlur={() => {
						let val = this.state.model[model];
						if (typeof min == 'number' && typeof val == 'number' && !error_min) {
							val = Math.max(val, min);
						}
						this.state.model[model] = val;
						this.setState({ model: this.state.model, rand: Math.random() });
					}}
				></input>
				{this.state.model[model + '_error'] ? <div className="cwp-i-input-error-msg" aria-live="assertive" id={'error-message-' + model}>{this.T(this.state.model[model + '_error'])}</div> : undefined}
			</div>
		);
	}

	renderOrderItem(model, label, value, index, width, order) {
		return (
			<div className="cwp-order-value-cont" style={{ width: width }}>
				<button
					className={"cwp-ov-up-arrow " + (index == 0 ? "cwp-ov-first" : "")}
					onClick={this.reorderBubble(order, model, value, -1)}
					><img alt="" 
					src="/images/arrow-upward.svg"
				/></button>
				<div className="cwp-ov-item">
					{index + 1}. {this.T(label)}
				</div>
				<button
					className={"cwp-ov-down-arrow " + (index == order.length - 1 ? "cwp-ov-last" : "")}
					onClick={this.reorderBubble(order, model, value, 1)}			
				><img alt="" 
					src="/images/arrow-downward.svg"
				/></button>
			</div>
		);
	}


	// if defaultRankOrder is an array, treated as a ranking question
	renderOptions(model, options, colsDesktop, colsMobile, vertPadding, defaultRankOrder) {
		let order = null;
		if (defaultRankOrder) {
			order = this.state.model[model] || defaultRankOrder;
			options = [...options].sort((a, b) => order.indexOf(a.value) - order.indexOf(b.value));
		}

		let rowsDesktop = [[]];
		let rowsMobile = [[]];
		let index = 0;

		for (let option of options) {
			let rowD = rowsDesktop[rowsDesktop.length - 1];
			let rowM = rowsMobile[rowsMobile.length - 1];
			if (option.enabled_if) {
				let otherVal = this.state.model[option.enabled_if.model];
				option.disabled = true;
				if (option.enabled_if.value.indexOf(otherVal) >= 0 || otherVal == undefined) {
					option.disabled = false;
				}
			}
			if (rowD.length >= colsDesktop) {
				rowD = [];
				rowsDesktop.push(rowD);
			}
			if (rowM.length >= colsMobile) {
				rowM = [];
				rowsMobile.push(rowM);
			}
			rowD.push({
				key: `text-option-${model}-d-${index}`,
				...option,
			});
			rowM.push({
				key: `text-option-${model}-m-${index}`,
				...option,
			});
			index += 1;
		}

		let rowKeysDesktop = [];
		for (let i = 0; i < rowsDesktop.length; i++) {
			rowKeysDesktop.push(`text-option-row-${model}-d-${i}`);
		}

		let rowKeysMobile = [];
		for (let i = 0; i < rowsMobile.length; i++) {
			rowKeysMobile.push(`text-option-row-${model}-m-${i}`);
		}

		let widthDesktop = `calc(${100 / colsDesktop}% - ${(14 * (colsDesktop - 1)) / colsDesktop}px)`;
		let widthMobile = `calc(${100 / colsMobile}% - ${(14 * (colsMobile - 1)) / colsMobile}px)`;

		return (
			<div className="cwp-text-options">
				{rowsDesktop.map((row, index) => (
					<div key={rowKeysDesktop[index]} className="cwp-to-row-desktop">
						{row.map((option) =>
							option.isInput ? (
								this.renderInputBox(option.model, option.type, option.title, option.placeholder, widthDesktop, option.min, option.max, option.error_min, option.error_max)
							) : order ? (
								this.renderOrderItem(model, option.title, option.value, order.indexOf(option.value), widthDesktop, order)
							) : option.isToggle ? (
								<button
									key={option.key}
									aria-pressed={(this.state.model[model] || []).indexOf(option.value) >= 0 ? "toggled" : ""}
									className={
										"cwp-option " + ((this.state.model[model] || []).indexOf(option.value) >= 0 ? "toggled" : "") + (!option.disabled ? " " : " disabled")
									}
									disabled={option.disabled}
									style={{ width: widthDesktop, paddingTop: vertPadding, paddingBottom: vertPadding }}
									onClick={this.toggleOnClick(model, option.value)}
								>
									{option.i18nKey ? <Trans i18nKey={option.i18nKey}>{option.title}</Trans> : this.T(option.title)}
								</button>
							) : (
								<button
									key={option.key}
									disabled={option.disabled}
									aria-pressed={this.state.model[model] == option.value}
									className={"cwp-option " + (this.state.model[model] == option.value ? "selected" : "") + (!option.disabled ? " " : " disabled")}
									style={{ width: widthDesktop, paddingTop: vertPadding, paddingBottom: vertPadding }}
									onClick={this.buttonOnClick(model, option.value, option.set_other, option.enables)}
								>
									{option.i18nKey ? <Trans i18nKey={option.i18nKey}>{option.title}</Trans> : this.T(option.title)}
								</button>
							)
						)}
					</div>
				))}
				{rowsMobile.map((row, index) => (
					<div key={rowKeysMobile[index]} className="cwp-to-row-mobile">
						{row.map((option) =>
							option.isInput ? (
								this.renderInputBox(option.model, option.type, option.title, option.placeholder, widthMobile, option.min, option.max, option.error_min, option.error_max)
							) : order ? (
								this.renderOrderItem(model, option.title, option.value, order.indexOf(option.value), widthMobile, order)
							) : option.isToggle ? (
								<button
									key={option.key}
									className={
										"cwp-option " + ((this.state.model[model] || []).indexOf(option.value) >= 0 ? "toggled" : "") + (!option.disabled ? " " : " disabled")
									}
									style={{ width: widthMobile, paddingTop: vertPadding, paddingBottom: vertPadding }}
									onClick={this.toggleOnClick(model, option.value)}
								>
									{option.i18nKey ? <Trans i18nKey={option.i18nKey}>{option.title}</Trans> : this.T(option.title)}
								</button>
							) : (
								<button
									key={option.key} aria-pressed={this.state.model[model] == option.value ? "true" : "false"}
									className={"cwp-option " + (this.state.model[model] == option.value ? "selected" : "") + (!option.disabled ? " " : " disabled")}
									style={{ width: widthMobile, paddingTop: vertPadding, paddingBottom: vertPadding }}
									onClick={this.buttonOnClick(model, option.value, option.set_other, option.enables)}
								>
									{option.i18nKey ? <Trans i18nKey={option.i18nKey}>{option.title}</Trans> : this.T(option.title)}
								</button>
							)
						)}
					</div>
				))}
			</div>
		);
	}

	upgradeYearRanges() {
		let ranges = [
			{ min: 2011, max: 9999 },
			{ min: 2001, max: 2010 },
			{ min: 1981, max: 2000 },
			{ min: 1961, max: 1980 },
			{ min: 1941, max: 1960 },
			{ min: 0, max: 1940 }
		];
		const year_built = this.state?.model?.year_built ?? 1960;
		for (let i=0; i<ranges.length; i++) {
			if (year_built > ranges[i].max) {
				ranges.splice(i, 1);
				i --;
				continue;
			}
		}
		ranges[ranges.length-1].min = 0;
		if ((ranges[ranges.length-1]?.max - year_built) < 5) {
			ranges.splice(ranges.length-1, 1);
		}
		ranges[ranges.length-1].min = 0;
		let ret = [];
		for (let range of ranges) {
			if (range.min === 0 && range.max === 9999)  {
			}
			else if (range.max === 9999) {
				ret.push({ title: `${range.min} to present`, value: range.min+1 });
			}
			else if (range.min === 0) {
				if (range.max === year_built) {
					ret.push({ title: `${range.max}`, value: Math.max(range.max-20, year_built) });
				}
				else {
					ret.push({ title: `${year_built} to ${range.max}`, value: Math.max(Math.floor((year_built + range.max)*0.5), year_built) });
				}
			}
			else {
				ret.push({ title: `${range.min} to ${range.max}`, value: Math.floor((range.min + range.max)*0.5) });
			}
		}
		return ret;
	}

	renderQuestion(questionNo, model) {

		if (model.indexOf("~") === 0) {
			switch (model) {
				case "~areas":
					return <div className="cwp-question-card-cont"><div className="cwp-qc-sub-header">
						<img alt="" src="/images/cwp-qc-areas.svg"/> {this.T("Areas")}
					</div></div>;
				case "~windows":
					return <div className="cwp-question-card-cont"><div className="cwp-qc-sub-header">
						<img alt="" src="/images/cwp-qc-windows.svg"/> {this.T("Windows")}
					</div></div>;
				case "~exterior_doors":
					return <div className="cwp-question-card-cont"><div className="cwp-qc-sub-header">
						<img alt="" src="/images/cwp-qc-exterior_doors.svg"/> {this.T("Exterior Doors")}
					</div></div>;
				case "~ceiling_insulation":
					return <div className="cwp-question-card-cont"><div className="cwp-qc-sub-header">
						<img alt="" src="/images/cwp-qc-ceiling-insulation.svg"/> {this.T("Ceiling/Attic Insulation")}
					</div></div>;
				case "~foundation_insulation":
					return <div className="cwp-question-card-cont"><div className="cwp-qc-sub-header">
						<img alt="" src="/images/cwp-qc-foundation-insulation.svg"/> {this.T("Foundation Insulation")}
					</div></div>;
				case "~wall_insulation":
					return <div className="cwp-question-card-cont"><div className="cwp-qc-sub-header">
						<img alt="" src="/images/cwp-qc-wall-insulation.svg"/> {this.T("Wall Insulation")}
					</div></div>;
				default:
					return;
			}
		}

		let title = ``;
		let content = <div className="cwp-qc-content"></div>;

		switch (model) {
			case "house_type":
				title += "What type of house do you have?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{
									i18nKey: "type_of_house__single_detached",
									title: (
										<span>
											<img alt="" src="/images/single-home.svg" />
											<br />
											Single
											<br />
											Detached
										</span>
									),
									value: "single detached",
								},
								{
									i18nKey: "type_of_house__semi_detached",
									title: (
										<span>
											<img alt="" src="/images/single-home.svg" />
											<img alt="" src="/images/single-home.svg" />
											<br />
											Double or Semi-
											<br />
											Detached
										</span>
									),
									value: "double/semi-detached",
								},
								{
									i18nKey: "type_of_house__row_house_middle_unit",
									title: (
										<span>
											<img alt="" src="/images/row-house-middle-unit.svg" />
											<br />
											Row house -<br />
											Middle unit
										</span>
									),
									value: "row house, middle unit",
								},
								{
									i18nKey: "type_of_house__row_house_end_unit",
									title: (
										<span>
											<img alt="" src="/images/row-house-end-unit.svg" />
											<br />
											Row house -<br />
											End unit
										</span>
									),
									value: "row house, end unit",
								},
								{
									i18nKey: "type_of_house__duplex",
									title: (
										<span>
											<img alt="" src="/images/duplex-home.svg" style={{height: "41px"}} />
											<br />
											Duplex
										</span>
									),
									value: "duplex",
								},
								{
									i18nKey: "type_of_house__mobile_home",
									title: (
										<span>
											<img alt="" src="/images/mobile-home.svg" />
											<br />
											Mobile
											<br />
											Home
										</span>
									),
									value: "mobile home",
								},
							],
							5,
							2,
							"20px"
						)}
					</div>
				);
				break;

			case "year_built":
				title += "What year was your house built?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Enter year", placeholder: "YYYY", type: "number", model: model, min: 1000, error_min: "Year built must be 4 digits (e.g. 1974)", max: moment().year() }], 3, 1)}
						<div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>If you are unsure, you can select from the following estimated year ranges below</Trans></div>
						{this.renderOptions(
							model,
							[
								{ title: "2011 to present", value: 2012 },
								{ title: "2001 to 2010", value: Math.floor((2001 + 2010)*0.5) },
								{ title: "1981 to 2000", value: Math.floor((1981 + 2000)*0.5) },
								{ title: "1961 to 1980", value: Math.floor((1961 + 1980)*0.5) },
								{ title: "1941 to 1960", value: Math.floor((1941 + 1960)*0.5) },
								{ title: "1940 or before", value: 1920 }
							],
							6,
							2
						)}
					</div>
				);
				break;

			case "storeys":
				if (this.state.model.foundation_type === 'split') {
					return;
				}
				title += "How many floors above grade does your home have?";
				content = [
					<div className="cwp-subheader-1">{this.T("Do not count basement/ crawlspace.")}</div>,
					<div className="cwp-subheader-2">{this.T("For split-level homes, include the lower level.")}</div>,
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "1", value: 1 },
								{ title: "1.5", value: 1.5 },
								{ title: "2", value: 2 },
								{ title: "2.5", value: 2.5 },
								{ title: "3", value: 3 },
								{ title: "3.5", value: 3.5 },
								{ title: "4", value: 4 },
							],
							7,
							3
						)}
					</div>
				];
				break;

			case "do_rent":
				title += "Do you own or rent your home?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Rent", value: true },
								{ title: "Own", value: false },
							],
							2,
							2
						)}
					</div>
				);
				break;

			case "has_hrv":
				title += "Do you have a heat recovery ventilator (HRV)?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Yes", value: true },
								{ title: "No", value: false },
							],
							2,
							2
						)}
					</div>
				);
				break;

			case "occupancy":
				title += "How many people normally live in your home?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Number of occupants", placeholder: "Enter quantity", type: "number", model: model }], 2, 1)}
					</div>
				);
				break;

			case "customer_priorities":
				title += "Please rank your priorities when it comes to making energy efficiency decisions";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Saving Money", value: "money" },
								{ title: "Improving Comfort", value: "comfort" },
								{ title: "Increasing Home Value", value: "value" },
								{ title: "Reducing Environmental Impact", value: "environment" },
							],
							1,
							1,
							null,
							["money", "comfort", "value", "environment"]
						)}
					</div>
				);
				break;

			case "draft":
				title += "How drafty is your home?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Not drafty", value: 1 },
								{ title: "A little drafty", value: 2 },
								{ title: "Very drafty", value: 3 },
								{ title: '- Unspecified -', value: null }
							],
							4,
							1
						)}
					</div>
				);
				break;

			case "total_floor_area_sqft":
				title += "What is the total floor area of your home? Only include areas you heat.";
				content = (
					<><div className="cwp-qc-content">
						{/*<div className="cwp-qc-unit-title">{this.T('Select a unit')}</div>*/}
						{/*this.renderOptions(
							model+"_units",
							[
								{ title: "ft²", value: "sqft" },
								{ title: "m²", value: "sqm"},
							],
							7,
							1
						)*/}
						{this.renderOptions(model, [{ isInput: true, title: "Area (" + (this.state.model.total_floor_area_sqft_units === "sqm" ? "m²" : "ft²") + ")", placeholder: "Enter area", type: "number", model: model }], 3, 1)}
						<div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>If you are unsure, you can select from the following estimated area ranges below</Trans></div>
							{
								this.renderOptions(
									model,
									this.state.model.total_floor_area_sqft_units == "sqm" ? 
									[
										{ title: "400-500 m²", value: 450 },
										{ title: "300-400 m²", value: 350 },
										{ title: "200-300 m²", value: 250 },
										{ title: "150-200 m²", value: 175 },
										{ title: "100-150 m²", value: 125 },
										{ title: "less than 100 m²", value: 75 },
									]
									: [
										{ title: "4000-5000 ft²", value: 4500 },
										{ title: "3000-4000 ft²", value: 3500 },
										{ title: "2000-3000 ft²", value: 2500 },
										{ title: "1500-2000 ft²", value: 1750 },
										{ title: "1000-1500 ft²", value: 1250 },
										{ title: "less than 1000 ft²", value: 750 },
									],
									3,
									1
								)
							}
					</div></>
				);	
				break;
			
			case "windows_upgraded":
				title += "Were your windows ever upgraded?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Yes", value: "yes" },
								{ title: "No, they're original", value: "no" },
								{ title: "I think so", value: "maybe" },
								{ title: "I don't know", value: "unsure" },
							],
							4,
							1
						)}
					</div>
				);
				break;
			
			case "has_heat_pump":
				title += "Do you have a heat pump?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Yes", value: true },
								{ title: "No", value: false },
								{ title: "I don't know", value: null },
							],
							3,
							1
						)}
					</div>
				);
				break;
			
			case "heat_pump_type":
				if (this.state.model["has_heat_pump"] !== true) {
					return;
				}
				title += "What kind of heat pump do you have?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Ductless Mini Split", value: "ductless-mini-split" },
								{ title: "Centrally-Ducted", value: "central-duckted" },
								{ title: "Ducted Mini Split", value: "ducted-mini-split" },
								{ title: "I don't know", value: "unsure" },
							],
							2,
							1
						)}
					</div>
				);
				break;

			case "heat_pump_year":
				if (this.state.model["has_heat_pump"] !== true) {
					return;
				}
				title += "Do you know what year your heat pump was installed?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Enter year", placeholder: "YYYY", type: "number", model: model, min: this.state.model.year_built, error_min: "Must be newer than the house.", max: moment().year() }], 3, 1)}
						{this.upgradeYearRanges().length ? <div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>If you are unsure, you can select from the following estimated year ranges below</Trans></div> : undefined}
						{this.renderOptions(
							model,
							[
								{ "title": "2020 to present", value: 2020 },
								{ "title": "2015 to 2019", value: 2017 },
								{ "title": "2010 to 2014", value: 2012 },
								{ "title": "Before 2010", value: 2005 }
							],
							6,
							2
						)}
					</div>
				);
				break;

			case "non_hp_primary_heating_system":
				title += 'What kind of heating system do you use the most?' + (this.state.model["has_heat_pump"] ? ' (Not considering your heat pump)' : '');
				content = (<div className='cwp-qc-content'>
					<div className="cwp-sub-title">{this.T("If you don't see your system type, choose the most similar")}</div>
					{this.renderOptions(model, getList("v2PrimaryHeatingSystemType", "v2PrimaryHeatingSystemFuelType", "primary_heating_fuel_type").filter((e) => e.value !== 'heat pump'), 3, 1)}
				</div>);
				break;

			case "non_hp_primary_heating_fuel_type":
				if (!this.state.model["non_hp_primary_heating_system"]) {
					return;
				}
				title += 'What fuel source does your heating system use?' + (this.state.model["has_heat_pump"] ? ' (Not considering your heat pump)' : '');
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, getList("v2PrimaryHeatingSystemFuelType"), 3, 1)}
				</div>);
				break;

			case "non_hp_primary_heating_system_year":
				if (!this.state.model["non_hp_primary_heating_system"]) {
					return;
				}
				{
					let list = getList("v2PrimaryHeatingSystemType");
					let hsName = this.T(list.filter((e) => e.value === this.state.model["non_hp_primary_heating_system"])?.[0]?.title ?? 'heating system');
					title += this.T("Do you know when your") + " " + hsName + " " + this.T("was last upgraded?");
				}
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Enter year", placeholder: "YYYY", type: "number", model: model, min: this.state.model.year_built, error_min: "Must be newer than the house.", max: moment().year() }], 3, 1)}
						{this.upgradeYearRanges().length ? <div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>If you are unsure, you can select from the following estimated year ranges below</Trans></div> : undefined}
						{this.renderOptions(
							model,
							this.upgradeYearRanges(),
							6,
							2
						)}
					</div>
				);
				break;

			case "exterior_doors_upgraded":
				title += "Were your exterior doors ever upgraded?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Yes", value: "yes" },
								{ title: "No, they're original", value: "no" },
								{ title: "I think so", value: "maybe" },
								{ title: "I don't know", value: "unsure" },
							],
							4,
							1
						)}
					</div>
				);
				break;

			case "preferred_area_units":
				title += "What is your preferred unit for floor areas?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "square feet (ft²)", value: "sqft" },
								{ title: "square meters (m²)", value: "sqm"},
							],
							5,
							1
						)}
					</div>
				);
				break;

			case "footprint":
				title += "What is the area of your first floor?";
				content = (
					<div className="cwp-qc-content">
						{/*<div className="cwp-qc-unit-title">{this.T('Select a unit')}</div>*/}
						{/*this.renderOptions(
							model+"_units",
							[
								{ title: "ft²", value: "sqft" },
								{ title: "m²", value: "sqm"},
							],
							7,
							1
						)*/}
						{this.renderOptions(model, [{ isInput: true, title: "Area (" + (this.state.model.footprint_units === "sqm" ? "m²" : "ft²") + ")", placeholder: "Enter area", type: "number", model: model }], 2, 1)}
					</div>
				);
				break;

			case "foundation_type":
				title += "What type of foundation do you have?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{
									i18nKey: "foundtype__basement",
									title: (
										<span>
											<img alt="" height={"72px"} width="auto" src="/images/cwp-foundtype-basement.svg" />
											<br />
											Basement
										</span>
									),
									value: "basement",
								},
								{
									i18nKey: "foundtype__crawlspace",
									title: (
										<span>
											<img alt="" height={"72px"} width="auto" src="/images/cwp-foundtype-crawlspace.svg" />
											<br />
											Crawlspace
										</span>
									),
									value: "crawlspace",
								},
								{
									i18nKey: "foundtype__slab",
									title: (
										<span>
											<img alt="" height={"72px"} width="auto" src="/images/cwp-foundtype-slab.svg" />
											<br />
											Slab on Grade
										</span>
									),
									value: "slab",
								},
								{
									i18nKey: "foundtype__split",
									title: (
										<span>
											<img alt="" height={"72px"} width="auto" src="/images/cwp-foundtype-split.svg" />
											<br />
											Split Level
										</span>
									),
									value: "split",
								},
								{
									i18nKey: "foundtype__unknown",
									title: (
										<div>
											<br/>
											<br/>
											<div style={{fontSize: "72px"}}>?</div><br/><br/>
											Unknown
										</div>
									),
									value: "unknown",
								}
							],
							5,
							2,
							"20px"
						)}
					</div>
				);
				break;

			case "heated_basement":
				if (this.state.model["foundation_type"] !== "basement") {
					return;
				}
				title += 'Is your basement heated?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ title: 'Yes', value: true },
						{ title: 'No', value: false }
					], 2, 2)}
				</div>);
				break;

			case "finished_basement":
				if (this.state.model["foundation_type"] !== "basement" && this.state.model["foundation_type"] !== "crawlspace") {
					return;
				}
				if (this.state.model["foundation_type"] === 'basement') {
					title += 'Is your basement finished?';
				}
				else {
					title += 'Is your crawlspace finished?';
				}
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ title: 'Yes', value: true },
						{ title: 'No', value: false }
					], 2, 2)}
				</div>);
				break;
			
			case "has_air_conditioner":
				title += 'Do you have a cooling system/ air conditioning?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ title: 'Yes', value: true },
						{ title: 'No', value: false }
					], 2, 2)}
				</div>);
				break;

			case "basement_area_percent":
				if (!this.state.model["heated_basement"]) {
					return;
				}
				title += 'Approximately how much basement area do you heat?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ title: 'Same as 1st floor area', value: 100 },
						{ title: '3/4 1st floor area', value: 75 },
						{ title: '1/2 1st floor area', value: 50 },
						{ title: '1/4 1st floor area', value: 25 },
						{ title: 'None', value: 0 }
					], 3, 1)}
				</div>);
				break;

			case "heated_crawlspace":
				if (this.state.model["foundation_type"] != "crawlspace") {
					return;
				}
				title += 'Is your crawlspace heated?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ title: 'Yes', value: true },
						{ title: 'No', value: false }
					], 2, 2)}
				</div>);
				break;

			/*case "crawlspace_area_percent":
				if (!this.state.model["heated_crawlspace"] || this.state.model["foundation_type"] != "crawlspace") {
					return;
				}
				title += 'Approximately how much crawlspace area do you heat?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ title: 'Same as 1st floor area', value: 100 },
						{ title: '3/4 1st floor area', value: 75 },
						{ title: '1/2 1st floor area', value: 50 },
						{ title: '1/4 1st floor area', value: 25 },
						{ title: 'None', value: 0 }
					], 3, 1)}
				</div>);
				break;*/

			case "foundation_insulation_nominal":
				if (this.state.model["foundation_type"] != "basement") {
					return;
				}
				title += 'How much insulation is on your foundation walls?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ isInput: true, title: 'Insulation R-Value', placeholder: 'Enter R-Value', type: 'number', 'model': model }
					], 3, 1)}
					<div className='cwp-sub-title'><Trans i18nKey="cwp-sub-title-r-select">If you are unsure, you can select from the following estimated R-Value ranges below</Trans></div>
					{this.renderOptions(model, [
						{ title: 'Below average - Approx. R5', value: 5 },
						{ title: 'Average - Approx. R10', value: 10 },
						{ title: 'Above average - R16+', value: 16 },
						{ title: 'Unsure', value: null }
					], 3, 1)}
				</div>);
				break;

			case "floor_above_crawlspace_insulation_nominal":
				if (this.state.model["foundation_type"] != "crawlspace") {
					return;
				}
				title += 'How much insulation is in the floor above your crawlspace?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ isInput: true, title: 'Insulation R-Value', placeholder: 'Enter R-Value', type: 'number', 'model': model }
					], 3, 1)}
					<div className='cwp-sub-title'><Trans i18nKey="cwp-sub-title-r-select">If you are unsure, you can select from the following estimated R-Value ranges below</Trans></div>
					{this.renderOptions(model, [
						{ title: 'Below average - Approx. R5', value: 5 },
						{ title: 'Average - Approx. R10', value: 10 },
						{ title: 'Above average - R20+', value: 20 },
						{ title: 'Unsure', value: null }
					], 3, 1)}
				</div>);
				break;

			case "ceiling_insulation_nominal":
				title += 'How much insulation is in your ceiling/attic?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ isInput: true, title: 'Insulation R-Value', placeholder: 'Enter R-Value', type: 'number', 'model': model }
					], 3, 1)}
					<div className='cwp-sub-title'><Trans i18nKey="cwp-sub-title-r-select">If you are unsure, you can select from the following estimated R-Value ranges below</Trans></div>
					{this.renderOptions(model, [
						{ title: 'Below average - Approx. R15', value: 15 },
						{ title: 'Average - Approx. R30', value: 30 },
						{ title: 'Above average - R60+', value: 60 },
						{ title: 'Unsure', value: null }
					], 3, 1)}
				</div>);
				break;

			case "main_wall_insulation_nominal":
				title += 'How much insulation is in your walls?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ isInput: true, title: 'Insulation R-Value', placeholder: 'Enter R-Value', type: 'number', 'model': model }
					], 3, 1)}
					<div className='cwp-sub-title'><Trans i18nKey="cwp-sub-title-r-select">If you are unsure, you can select from the following estimated R-Value ranges below</Trans></div>
					{this.renderOptions(model, [
						{ title: 'Below average - Approx. R12', value: 12 },
						{ title: 'Average - Approx. R18', value: 18 },
						{ title: 'Above average - R24+', value: 24 },
						{ title: 'Unsure', value: null }
					], 3, 1)}
				</div>);
				break;

			case "exterior_doors":
				title += "How many exterior doors do you have?";
				content = (
					<div className="cwp-qc-content">
						<div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>Include Sliding Doors</Trans></div>
						{this.renderOptions(model, [{ isInput: true, title: "Doors", placeholder: "Enter number of exterior doors", type: "number", model: model }], 2, 1)}
					</div>
				);
				break;

			case "exterior_doors_year":
				if (this.state.model.exterior_doors_upgraded !== 'yes' && this.state.model.exterior_doors_upgraded !== 'maybe') {
					return;
				}
				title += "Do you know when your exterior doors were upgraded?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Enter year", placeholder: "YYYY", type: "number", model: model, min: this.state.model.year_built, error_min: "Must be newer than the house.", max: moment().year() }], 3, 1)}
						{this.upgradeYearRanges().length ? <div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>If you are unsure, you can select from the following estimated year ranges below</Trans></div> : undefined}
						{this.renderOptions(
							model,
							this.upgradeYearRanges(),
							6,
							2
						)}
					</div>
				);
				break;

			case "ceiling_insulation_upgraded":
				title += "Was your ceiling/attic insulation ever upgraded?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Yes", value: "yes" },
								{ title: "No, it is original", value: "no" },
								{ title: "I think so", value: "maybe" },
								{ title: "I don't know", value: "unsure" },
							],
							4,
							1
						)}
					</div>
				);
				break;

			case "ceiling_insulation_year":
				if (this.state.model.ceiling_insulation_upgraded !== 'yes' && this.state.model.ceiling_insulation_upgraded !== 'maybe') {
					return;
				}
				title += "Do you know when your ceiling/attic insulation was upgraded?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Enter year", placeholder: "YYYY", type: "number", model: model, min: this.state.model.year_built, error_min: "Must be newer than the house.", max: moment().year() }], 3, 1)}
						{this.upgradeYearRanges().length ? <div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>If you are unsure, you can select from the following estimated year ranges below</Trans></div> : undefined}
						{this.renderOptions(
							model,
							this.upgradeYearRanges(),
							6,
							2
						)}
					</div>
				);
				break;

			case "foundation_insulation_upgraded":
				title += "Was your foundation insulation ever upgraded?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Yes", value: "yes" },
								{ title: "No, it is original", value: "no" },
								{ title: "I think so", value: "maybe" },
								{ title: "I don't know", value: "unsure" },
							],
							4,
							1
						)}
					</div>
				);
				break;

			case "foundation_insulation_year":
				if (this.state.model.foundation_insulation_upgraded !== 'yes' && this.state.model.foundation_insulation_upgraded !== 'maybe') {
					return;
				}
				title += "Do you know when your foundation insulation was upgraded?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Enter year", placeholder: "YYYY", type: "number", model: model, min: this.state.model.year_built, error_min: "Must be newer than the house.", max: moment().year() }], 3, 1)}
						{this.upgradeYearRanges().length ? <div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>If you are unsure, you can select from the following estimated year ranges below</Trans></div> : undefined}
						{this.renderOptions(
							model,
							this.upgradeYearRanges(),
							6,
							2
						)}
					</div>
				);
				break;

			case "wall_insulation_upgraded":
				title += "Was your wall insulation ever upgraded?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Yes", value: "yes" },
								{ title: "No, it is original", value: "no" },
								{ title: "I think so", value: "maybe" },
								{ title: "I don't know", value: "unsure" },
							],
							4,
							1
						)}
					</div>
				);
				break;

			case "wall_insulation_year":
				if (this.state.model.wall_insulation_upgraded !== 'yes' && this.state.model.wall_insulation_upgraded !== 'maybe') {
					return;
				}
				title += "Do you know when your wall insulation was upgraded?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Enter year", placeholder: "YYYY", type: "number", model: model, min: this.state.model.year_built, error_min: "Must be newer than the house.", max: moment().year() }], 3, 1)}
						{this.upgradeYearRanges().length ? <div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>If you are unsure, you can select from the following estimated year ranges below</Trans></div> : undefined}
						{this.renderOptions(
							model,
							this.upgradeYearRanges(),
							6,
							2
						)}
					</div>
				);
				break;

			case "windows":
				title += "How many windows do you have?";
				content = (
					<div className="cwp-qc-content">
						<div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>Count very large or solarium-type windows in terms of how many average-sized windows they represent</Trans></div>
						{this.renderOptions(model, [{ isInput: true, title: "Windows", placeholder: "Enter number of windows", type: "number", model: model }], 2, 1)}
					</div>
				);
				break;

			case "windows_year":
				if (this.state.model.windows_upgraded !== 'yes' && this.state.model.windows_upgraded !== 'maybe') {
					return;
				}
				title += "Do you know when your windows were upgraded?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Enter year", placeholder: "YYYY", type: "number", model: model, min: this.state.model.year_built, error_min: "Must be newer than the house.", max: moment().year() }], 3, 1)}
						{this.upgradeYearRanges().length ? <div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>If you are unsure, you can select from the following estimated year ranges below</Trans></div> : undefined}
						{this.renderOptions(
							model,
							this.upgradeYearRanges(),
							6,
							2
						)}
					</div>
				);
				break;

			case "estar_windows_percent":
				title += "How many of your windows are ENERGY STAR (or energy efficient)?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "All", value: 100 },
								{ title: "3/4", value: 75 },
								{ title: "1/2", value: 50 },
								{ title: "1/4", value: 25 },
								{ title: "None", value: 0 },
								{ title: 'Unsure', value: null }
							],
							3,
							3
						)}
					</div>
				);
				break;

			case "estar_doors_percent":
				title += "How many of your doors are ENERGY STAR (or energy efficient)?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "All", value: 100 },
								{ title: "3/4", value: 75 },
								{ title: "1/2", value: 50 },
								{ title: "1/4", value: 25 },
								{ title: "None", value: 0 },
								{ title: 'Unsure', value: null }
							],
							3,
							3
						)}
					</div>
				);
				break;

			case "primary_heating_system":
				title += 'What kind of primary heating system do you have?';
				content = (<div className='cwp-qc-content'>
					<div className="cwp-sub-title">{this.T("If you don't see your system type, choose the most similar")}</div>
					{this.renderOptions(model, getList("v2PrimaryHeatingSystemType", "v2PrimaryHeatingSystemFuelType", "primary_heating_fuel_type"), 3, 1)}
				</div>);
				break;

			case "primary_heating_fuel_type":
				if (!this.state.model["primary_heating_system"]) {
					return;
				}
				title += 'What fuel type does your primary heating system use?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, getList("v2PrimaryHeatingSystemFuelType"), 3, 1)}
				</div>);
				break;

			case "primary_heating_system_year":
				title += "Do you know when your primary heating system was last upgraded?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Enter year", placeholder: "YYYY", type: "number", model: model, min: this.state.model.year_built, error_min: "Must be newer than the house.", max: moment().year() }], 3, 1)}
						{this.upgradeYearRanges().length ? <div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>If you are unsure, you can select from the following estimated year ranges below</Trans></div> : undefined}
						{this.renderOptions(
							model,
							this.upgradeYearRanges(),
							6,
							2
						)}
					</div>
				);
				break;

			case "secondary_heating_system_year":
				if (!this.state.model["has_secondary_heating_system"]) {
					return;
				}
				title += "Do you know when your secondary heating system was last upgraded?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Enter year", placeholder: "YYYY", type: "number", model: model, min: this.state.model.year_built, error_min: "Must be newer than the house.", max: moment().year() }], 3, 1)}
						{this.upgradeYearRanges().length ? <div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>If you are unsure, you can select from the following estimated year ranges below</Trans></div> : undefined}
						{this.renderOptions(
							model,
							this.upgradeYearRanges(),
							6,
							2
						)}
					</div>
				);
				break;

			case "heating_system_capacity_btu_hr":
				if (this.state.model["primary_heating_system"] === "heat pump") {
					return;
				}
				title += 'What is the capacity of your primary heating system?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ isInput: true, title: 'Capacity in BTU/hr', placeholder: 'Enter Capacity', type: 'number', 'model': model }
					], 2, 1)}
				</div>);
				break;

			case "primary_heating_system_efficiency":
				if (this.state.model["primary_heating_system"] === "heat pump") {
					return;
				}
				title += 'What is the steady state efficiency of your primary heating system?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ isInput: true, title: 'Steady State Efficiency', placeholder: 'Enter Efficiency', type: 'number', 'model': model }
					], 2, 1)}
				</div>);
				break;

			case "primary_heating_system_seasonal_efficiency":
				if (this.state.model["primary_heating_system"] === "heat pump") {
					return;
				}
				title += 'What is the seasonal efficiency of your primary heating system?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ isInput: true, title: 'Seasonal Efficiency', placeholder: 'Enter Efficiency', type: 'number', 'model': model }
					], 2, 1)}
				</div>);
				break;

			case "hp_source":
				if (this.state.model["primary_heating_system"] !== "heat pump") {
					return;
				}
				title += 'What is the source of your heat pump? (Almost all are Air)';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, getList("v2PrimaryHeatingSystemHeatpumpSource"), 3, 1)}
				</div>);
				break;

			/*case "hp_capacity_btu_hr":
				if (this.state.model["primary_heating_system"] != "heat pump") {
					return;
				}
				title += 'What is the capacity of your heat pump?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ isInput: true, title: 'Capacity in BTU/hr', placeholder: 'Enter Capacity', type: 'number', 'model': model }
					], 2, 1)}
				</div>);
				break;

			case "hp_efficiency":
				if (this.state.model["primary_heating_system"] != "heat pump") {
					return;
				}
				title += 'What is the HSPF Rating of your heat pump?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ isInput: true, title: 'HSPF Rating', placeholder: 'Enter Efficiency', type: 'number', 'model': model }
					], 2, 1)}
				</div>);
				break;*/

			/*case "hp_capacity_btu_hr":
				title += "What is the capacity of your heat pump?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Capacity in BTU/hr", placeholder: "Enter Capacity", type: "number", model: model }], 2, 1)}
					</div>
				);
				break;*/

			case "has_secondary_heating_system":
				title += 'Do you have a secondary heating system?'
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ "title": "Yes", "value": true },
						{ "title": "No", "value": false }
					], 2, 2)}
				</div>);
				break;

			case "secondary_heating_system":
				if (!this.state.model["has_secondary_heating_system"]) {
					return;
				}
				title += 'What kind of secondary heating system do you have?'
				content = (<div className='cwp-qc-content'>
					<div className="cwp-sub-title">{this.T("If you don't see your system type, choose the most similar")}</div>
					{this.renderOptions(model, getList("v2SecondaryHeatingSystemType", "v2SecondaryHeatingSystemFuelType", "secondary_heating_fuel_type"), 3, 1)}
				</div>);
				break;

			case "secondary_heating_fuel_type":
				if (!this.state.model["has_secondary_heating_system"]) {
					return;
				}
				if (!this.state.model["secondary_heating_system"]) {
					return;
				}
				title += 'What fuel type does your secondary heating system use?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, getList("v2SecondaryHeatingSystemFuelType"), 3, 1)}
				</div>);
				break;

			case "secondary_hp_source":
				if (!this.state.model["has_secondary_heating_system"]) {
					return;
				}
				if (this.state.model["secondary_heating_system"] != "heat pump") {
					return;
				}
				title += 'What is the source of your secondary heat pump? (Almost all are Air)';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, getList("v2SecondaryHeatingSystemHeatpumpSource"), 3, 1)}
				</div>);
				break;

			/*case "secondary_hp_capacity_btu_hr":
				if (!this.state.model["has_secondary_heating_system"]) {
					return;
				}
				if (this.state.model["secondary_heating_system"] != "heat pump") {
					return;
				}
				title += 'What is the capacity of your secondary heat pump?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ isInput: true, title: 'Capacity in BTU/hr', placeholder: 'Enter Capacity', type: 'number', 'model': model }
					], 2, 1)}
				</div>);
				break;

			case "secondary_hp_efficiency":
				if (!this.state.model["has_secondary_heating_system"]) {
					return;
				}
				if (this.state.model["secondary_heating_system"] != "heat pump") {
					return;
				}
				title += 'What is the HSPF Rating of your secondary heat pump?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ isInput: true, title: 'HSPF Rating', placeholder: 'Enter Efficiency', type: 'number', 'model': model }
					], 2, 1)}
				</div>);
				break;

			case "secondary_hp_capacity_btu_hr":
				title += "What is the capacity of your secondary heat pump?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Capacity in BTU/hr", placeholder: "Enter Capacity", type: "number", model: model }], 2, 1)}
					</div>
				);
				break;*/

			case "water_heating_system":
				title += 'What type of water heating system do you have?';
				content = (<div className='cwp-qc-content'>
					<div className="cwp-sub-title">{this.T("If you don't see your system type, choose the most similar")}</div>
					{this.renderOptions(model, getList('v2WaterHeatingSystemType', 'v2WaterHeatingSystemFuelType', 'water_heating_fuel_type'), 3, 1)}
				</div>);
				break;

			case "water_heating_fuel_type":
				title += 'What fuel type does your water heating system use?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, getList('v2WaterHeatingSystemFuelType'), 3, 1)}
				</div>);
				break;

			case "water_heating_system_year":
				title += "Do you know when your water heating system was last upgraded?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Enter year", placeholder: "YYYY", type: "number", model: model, min: this.state.model.year_built, error_min: "Must be newer than the house.", max: moment().year() }], 3, 1)}
						{this.upgradeYearRanges().length ? <div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>If you are unsure, you can select from the following estimated year ranges below</Trans></div> : undefined}
						{this.renderOptions(
							model,
							this.upgradeYearRanges(),
							6,
							2
						)}
					</div>
				);
				break;
			
			case "water_heating_rented_or_owned":
				title += "Is your water heated rented or owned?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Rented", value: "rented" },
								{ title: "Owned", value: "owned" },
								{ title: "I Don’t Know", value: "unsure" },
							],
							3,
							1
						)}
					</div>
				);
				break;

			case "water_heating_efficiency":
				title += "What is the efficiency rating of your water heating system?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "HSPF Rating", placeholder: "Enter Efficiency", type: "number", model: model }], 2, 1)}
					</div>
				);
				break;

			case "hot_water_usage":
				title += "How much hot water does your household use?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Less than average", value: "low" },
								{ title: "Average", value: "medium" },
								{ title: "Above average", value: "high" },
							],
							3,
							1
						)}
					</div>
				);
				break;

			case "primary_fridge_type":
				title += "Tell us about your primary refrigerator";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Medium-size refrigerator", value: "medium" },
								{ title: "Large refrigerator", value: "large" },
								{ title: "Chest freezer", value: "chest" },
							],
							3,
							1
						)}
					</div>
				);
				break;

			case "primary_fridge_age":
				title += "How old is your primary refrigerator?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Newer than 10 years", value: "new" },
								{ title: "Older than 10 years", value: "10 years" },
								{ title: "Older than 20 years", value: "old" },
							],
							3,
							1
						)}
					</div>
				);
				break;

			case "thermostat_types":
				title += 'What type of thermostat(s) do you have? Choose all that apply.'
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ isToggle: true, title: 'Traditional', value: 'tradition' },
						{ isToggle: true, title: 'Programmable', value: 'programmable' },
						{ isToggle: true, title: 'Smart', value: 'smart' }
					], 3, 1)}
				</div>);
				break;

			case "air_conditioner_type":
				if (!this.state.model['has_air_conditioner']) {
					return;
				}
				title += 'What type of cooling / air conditioner do you have?'
				content = (<div className='cwp-qc-content'>
					<div className="cwp-sub-title">{this.T("If you don't see your system type, choose the most similar")}</div>
					{this.renderOptions(model, [
						{ title: 'Central single package system', value: "central single package system" },
						{ title: 'Central Split System (Ducted)', value: "central split system" },
						{ title: 'Conventional A/C', value: "conventional a/c" },
						{ title: 'Mini-split Heat Pump (ductless)', value: "mini-split ductless" },
						{ title: 'Window A/C', value: "window a/c" },
						{ title: 'Not Installed', value: "not installed" },
					], 3, 1)}
				</div>);
				break;

			case "air_conditioner_year":
				if (!this.state.model['has_air_conditioner'] || !this.state.model["air_conditioner_type"] || this.state.model["air_conditioner_type"] == 'not installed') {
					return;
				}
				title += "Do you know when your cooling system was last upgraded?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Enter year", placeholder: "YYYY", type: "number", model: model, min: this.state.model.year_built, error_min: "Must be newer than the house.", max: moment().year() }], 3, 1)}
						{this.upgradeYearRanges().length ? <div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>If you are unsure, you can select from the following estimated year ranges below</Trans></div> : undefined}
						{this.renderOptions(
							model,
							this.upgradeYearRanges(),
							6,
							2
						)}
					</div>
				);
				break;

			case "lights_hours":
				title += 'On average, how long are your lights on every day?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ isInput: true, title: 'Hours out of 24', placeholder: 'Enter Hours', type: 'number', 'model': model }
					], 3, 3)}
					<div className='cwp-sub-title'>If you are unsure, you can select from the following estimated hour ranges below</div>
					{this.renderOptions(model, [
						{ title: '1-2 Hours', value: 1 },
						{ title: '2-4 Hours', value: 3 },
						{ title: '4-6 Hours', value: 5 },
						{ title: '6-10 Hours', value: 8 },
						{ title: '10+ Hours', value: 12 }
					], 3, 2)}
				</div>);
				break;

			case "washing_machine_year":
				title += 'How old is your washing machine?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ title: 'Newer than 10 years', value: 'new' },
						{ title: 'Older than 10 years', value: '10 years' },
						{ title: 'Older than 20 years', value: 'old' }
					], 3, 1)}
				</div>);
				break;

			case "secondary_fridge_type":
				title += "Do you have a second refrigerator/freezer?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Medium-size refrigerator", value: "medium" },
								{ title: "Large refrigerator", value: "large" },
								{ title: "Chest freezer", value: "chest" },
								{ title: "None", value: "none" },
							],
							3,
							1
						)}
					</div>
				);
				break;

			case "secondary_fridge_year":
				title += "How old is your second refrigerator/freezer?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Newer than 10 years", value: "new" },
								{ title: "Older than 10 years", value: "10 years" },
								{ title: "Older than 20 years", value: "old" },
							],
							3,
							1
						)}
					</div>
				);
				break;

			case "lights_type":
				title += "Tell us about your lights";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Incandescent Bulbs", value: "incandescent" },
								{ title: "Fluorescent Lamps", value: "fluorescent" },
								{ title: "Compact Fluorescent Lamps", value: "compact fluorescent" },
								{ title: "Halogen Lamps", value: "halogen" },
								{ title: "Light Emitting Diode", value: "led" },
							],
							3,
							1
						)}
					</div>
				);
				break;

			case "lights_count":
				title += "Approximately how many lights do you have?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Number of lights", placeholder: "Enter Count", type: "number", model: model }], 3, 1)}
						<div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>If you are unsure, you can select from the following estimated light count ranges below</Trans></div>
						{this.renderOptions(
							model,
							[
								{ title: "3-5", value: 4 },
								{ title: "6-10", value: 8 },
								{ title: "11-15", value: 13 },
								{ title: "15-19", value: 17 },
								{ title: "20+", value: 24 },
							],
							3,
							3
						)}
					</div>
				);
				break;

			case "hp_type":
				if (this.state.model["primary_heating_system"] !== "heat pump") {
					return;
				}
				title += 'What Type of Primary Heat Pump do you have?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, getList("v2PrimaryHeatingSystemHeatpumpType"), 3, 1)}
				</div>);
				break;

			case "has_electric_vehicle_charger":
				title += "Does your home have an electric vehicle charger?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "Yes", value: true },
								{ title: "No", value: false },
							],
							3,
							1
						)}
					</div>
				);
				break;

			case "has_solar_pv_system":
				title += "Does your home have a solar photovoltaic (PV) system?";
				content = (
					<div className="cwp-qc-content">
						<div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>Solar PV sytems convert solar energy to electricity.</Trans></div>
						{this.renderOptions(
							model,
							[
								{ title: "Yes", value: true },
								{ title: "No", value: false },
							],
							3,
							1
						)}
					</div>
				);
				break;

			/*case "hp_year":
				if (this.state.model["primary_heating_system"] != "heat pump") {
					return;
				}
				title += 'How old is your Primary Heat Pump?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ isInput: true, title: 'Heat Pump Age (Years)', placeholder: 'Enter Years', type: 'number', 'model': model }
					], 3, 3)}
					<div className='cwp-sub-title'>If you are unsure, you can select from the following estimated age ranges below</div>
					{this.renderOptions(model, [
						{ title: '7 years or less', value: 3 },
						{ title: '8-15 years', value: 11 },
						{ title: '16+ years', value: 20 }
					], 3, 1)}
				</div>);
				break;

			case "hp_area_percent":
				if (this.state.model["primary_heating_system"] != "heat pump") {
					return;
				}
				title += 'How much of your home does your Primary Heat Pump heat?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ title: 'All', value: 100 },
						{ title: '3/4', value: 75 },
						{ title: '1/2', value: 50 },
						{ title: '1/4', value: 25 },
						{ title: 'None', value: 0 }
					], 3, 3)}
				</div>);
				break;*/

			case "secondary_hp_type":
				if (!this.state.model["has_secondary_heating_system"]) {
					return;
				}
				if (this.state.model["secondary_heating_system"] != "heat pump") {
					return;
				}
				title += 'What Type of Secondary Heat Pump do you have?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, getList("v2SecondaryHeatingSystemHeatpumpType"), 3, 1)}
				</div>);
				break;

			/*case "secondary_hp_year":
				if (!this.state.model["has_secondary_heating_system"]) {
					return;
				}
				if (this.state.model["secondary_heating_system"] != "heat pump") {
					return;
				}
				title += 'How old is your Secondary Heat Pump?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ isInput: true, title: 'Heat Pump Age (Years)', placeholder: 'Enter Years', type: 'number', 'model': model }
					], 3, 3)}
					<div className='cwp-sub-title'>If you are unsure, you can select from the following estimated age ranges below</div>
					{this.renderOptions(model, [
						{ title: '7 years or less', value: 3 },
						{ title: '8-15 years', value: 11 },
						{ title: '16+ years', value: 20 }
					], 3, 1)}
				</div>);
				break;

			case "secondary_hp_area_percent":
				if (!this.state.model["has_secondary_heating_system"]) {
					return;
				}
				if (this.state.model["secondary_heating_system"] != "heat pump") {
					return;
				}
				title += 'How much of your home does your Secondary Heat Pump heat?';
				content = (<div className='cwp-qc-content'>
					{this.renderOptions(model, [
						{ title: 'All', value: 100 },
						{ title: '3/4', value: 75 },
						{ title: '1/2', value: 50 },
						{ title: '1/4', value: 25 },
						{ title: 'None', value: 0 }
					], 3, 3)}
				</div>);
				break;

			case "secondary_hp_year":
				title += "How old is your Secondary Heat Pump?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(model, [{ isInput: true, title: "Heat Pump Age (Years)", placeholder: "Enter Years", type: "number", model: model }], 3, 3)}
						<div className="cwp-sub-title"><Trans i18nKey={'cwp-sub-title-' + model}>If you are unsure, you can select from the following estimated age ranges below</Trans></div>
						{this.renderOptions(
							model,
							[
								{ title: "7 years or less", value: 3 },
								{ title: "8-15 years", value: 11 },
								{ title: "16+ years", value: 20 },
							],
							3,
							1
						)}
					</div>
				);
				break;

			case "secondary_hp_area_percent":
				title += "How much of your home does your Secondary Heat Pump heat?";
				content = (
					<div className="cwp-qc-content">
						{this.renderOptions(
							model,
							[
								{ title: "All", value: 100 },
								{ title: "3/4", value: 75 },
								{ title: "1/2", value: 50 },
								{ title: "1/4", value: 25 },
								{ title: "None", value: 0 },
							],
							3,
							3
						)}
					</div>
				);
				break;*/

			default:
				break;
		}

		return (
			<div className="cwp-question-card-cont">
				<div className="cwp-question-card">
					<h2 className="cwp-qc-title">{this.T(title)}</h2>
					{content}
				</div>
			</div>
		);
	}

	render() {

		const stepDDOptions = [];
		for (let i=0; i<this.steps.length; i++) {
			stepDDOptions.push({ title: this.T(this.steps[i].title), value: i, stepNo: i+1, complete: this.state.step > i });
		}		

		if (this.props.introSurvey) {
			return (<div className="cwp-intro-survey">
				<div aria-live="polite" className='cwp-body'>
					{this.introSurveyData.questions.map((question, idx) => this.renderQuestion(idx, question))}
					<button className={'cwp-intro-survey-complete-btn'} disabled={!this.introSurveyComplete()} onClick={() => this.introSurveyClick()}>{this.T('Submit Survey')}</button>
				</div>
			</div>);
		}

		if (this.props.show) {
			if (!isLoggedIn()) {
				window.gotoPage('report');
				return (<div/>)
			}
			document.title = this.T('Homeowner Survey') + ' - ' + this.T('Climative Low Carbon Communities');
		}
		else {
			return (<div/>)
		}

		document.body.className = "customer-page " + this.theme.className;

		const lang = `${this.T('hmp-lang-code')}`.toLowerCase();

		if (!this.state.buildingInfoLoaded || this.state.is_saving) {
			return (<div role="alert" className={"customer-wizard-page " + this.theme.className}>
				<FocusLock>
					<div className="report-generating-alert">
						<center style={{position: "fixed", top: "calc(50% - 30px)", height: "60px", width: "100%"}}>
							{this.state.step >= (this.steps.length-1) ? 
							<h3 style={{color: "#000", "maxWidth": "280px"}}>{this.T('Your Home Energy Report is Being Updated ...')}</h3> :
							<h2 style={{color: "#000"}}>{this.T('Please wait ...')}</h2>}
							<i className="la la-spinner la-spin" style={{"fontSize": "96px", "color": "#164B6F"}}></i>
						</center>
					</div>
				</FocusLock>
			</div>);
		}

		return (
			<div className='customer-wizard-page'>
				<div className='cwp-non-footer'>
					<div className='hbb-cont'>
					{this.theme.advisorExperience ? (
							<div className="ho-back-button" role="navigation" aria-label="Breadcrumb">
								<ul className="d-flex">
									<li>
										<button
											autoFocus={true}
											onClick={() =>
												window.gotoPage('dashboard')
											}
										>
											<img alt="" src="/images/nav-back-arrow-blue.svg" />{' '}
											<b>{this.T('Dashboard')}</b>
										</button>
									</li>
									<li aria-current="page">
										<p>/ {this.T('Home Information Wizard')}</p>
									</li>
								</ul>
							</div>
						) : (
							<div className="ho-back-button" role="navigation" aria-label="Breadcrumb">
								<ul className="d-flex">
									<li>
										<button
											autoFocus={true}
											onClick={() =>
												window.gotoPage('report')
											}
										>
											<img alt="" src="/images/nav-back-arrow-blue.svg" />{' '}
											<b>{this.T('Home Energy Report')}</b>
										</button>
									</li>
									<li aria-current="page">
										<p>/ {this.T('Home Information Wizard')}</p>
									</li>
								</ul>
							</div>
						)}
					</div>
					{window.GET_FF('enf-survey-nav') ? <div className="cwp-skip-to-end-desktop-cont"><div className="cwp-skip-to-end-desktop-inner"><button className="cwp-skip-to-end-desktop" onClick={() => this.skipToEnd()}><img src="/images/skip-to-end.svg" /> {this.T('Skip to End')}</button></div></div> : undefined}
					{window.GET_FF('enf-survey-nav') ? (!this.state.finished && <div className='cwp-progress-desktop'>
						<div className='cwp-progress-inner'>
							<div className='cwp-pd-bar-done' style={{ width: `${100 * (this.state.step + 0.5) / this.steps.length}%` }}></div>
							<div className='cwp-pd-bar-bg'></div>
							{this.steps.map((step, idx) => (
								<button onClick={() => { this.jumpToStep(idx); }} aria-current={idx === this.state.step} className={'cwp-pd-step-btn cwp-pd-step ' + (idx <= this.state.step ? 'active' : '')} style={{ width: `${100 / this.steps.length}%` }}>
									<div className='cwp-pds-circle'>{idx < this.state.step ? (<img alt={`Step ${idx+1} complete`} src='/images/step-complete-check.svg' />) : `${idx + 1}`}</div>
									<div className='cwp-pds-title'>{this.T(step.title)}</div>
								</button>
							))}
						</div>
					</div>)
					: (!this.state.finished && <div className='cwp-progress-desktop'>
						<div className='cwp-progress-inner'>
							<div className='cwp-pd-bar-done' style={{ width: `${100 * (this.state.step + 0.5) / this.steps.length}%` }}></div>
							<div className='cwp-pd-bar-bg'></div>
							{this.steps.map((step, idx) => (
								<div aria-current={idx === this.state.step} className={'cwp-pd-step ' + (idx <= this.state.step ? 'active' : '')} style={{ width: `${100 / this.steps.length}%` }}>
									<div className='cwp-pds-circle'>{idx < this.state.step ? (<img alt={`Step ${idx+1} complete`} src='/images/step-complete-check.svg' />) : `${idx + 1}`}</div>
									<div className='cwp-pds-title'>{this.T(step.title)}</div>
								</div>
							))}
						</div>
					</div>)}
					{window.GET_FF('enf-survey-nav') ? (!this.state.finished && <div className='cwp-progress-mobile'>
						<div className='cwp-progress-inner'>
							<canvas width="54" height="54" ref={this.canvasRef} />
							<CDropdown
								style={'survey-steps'}
								options={stepDDOptions}
								curStep={this.state.step+1}
								onSelectStep={(step) => this.jumpToStep(step-1)}
								onSkipToEnd={() => this.skipToEnd()}
							/>
						</div>
					</div>)
					: (!this.state.finished && <div className='cwp-progress-mobile'>
						<div className='cwp-progress-inner'>
							<canvas width="54" height="54" ref={this.canvasRef} />
							<div className='cwp-pm-step-no'>{this.state.step + 1} {this.T('of')} {this.steps.length}</div>
							<div className='cwp-pm-step-title'>{this.T(this.steps[this.state.step].title)}</div>
							<div className='cwp-pm-next-step'>{this.T('Next:')} {(this.state.step + 1) < this.steps.length ? this.T(this.steps[this.state.step + 1].title) : this.T('Finish')}</div>
						</div>
					</div>)}
					{this.state.step === 0 ? <CDataDisclaimer copyId={"homeowner-survey-" + this.state.step} /> : undefined}
					{/*this.state.step == 0 ? <div className='cwp-info-text-cont'>
						<div className='cwp-info-text'><Trans i18nKey='cwp-info-text'>
							Thank you for providing information about your home.<br />
							<b>Note: All inputs are completely optional. You can change/ provide any of this information in your Assessment.</b>
							</Trans></div>
					</div> : undefined*/}
					{this.state.any_disabled_edits && this.state.step === 0 ? <div className="cwp-disabled-edits-cont"><div className="cwp-disabled-edits">
						<h2 className="cwp-de-title">{this.T("Known Building Information")}</h2>
						<div className="cwp-de-sub-title">{this.T("This data was provided by a high-confidence data provider and is not editable")}</div>
						<br/>
						{Object.keys(this.state.disabled_edits || {}).map((field) => <div className="cwp-de-field">
							<div className="cwp-def-label">{this.T(this.fieldTitles[field])}:</div><div className="cwp-def-value">{this.T(this.fieldFormatters[field](this.state.model[field]))}</div>
						</div>)}
					</div></div> : undefined}
					{!this.state.finished && (!this.state.any_disabled_edits || this.state.step > 0) && <div aria-live="polite" className='cwp-body'>
						{this.steps[this.state.step].questions.map((question, idx) => this.renderQuestion(this.steps[this.state.step].startNo + idx, question))}
					</div>}
					{!this.state.finished && <div className='cwp-button-cont'>
						<div className='cwp-button-cont-inner'>
							{this.state.step > 0 ? <button className={'cwp-back-button ' + (this.state.step > 0 ? 'enabled' : '')} onClick={() => this.prevStep()}><img alt="" src="/images/cwp-arrow-back.svg"/> {this.T('Back')}</button> : undefined}
							{(this.state.step < (this.steps.length - 1)) && (<button className={'cwp-continue-button ' + (this.state.step < (this.steps.length - 1) && !this.state.formError ? 'enabled' : '') + (this.state.step <= 0 ? ' full-size' : '')} onClick={() => this.nextStep()}>{this.T('NEXT')}</button>)}
							{(this.state.step >= (this.steps.length - 1)) && (<button className={'cwp-continue-button ' + (!this.state.formError ? 'enabled' : '')} onClick={() => this.nextStep()}>{this.T('SUBMIT')}</button>)}
						</div>
					</div>}
					{this.state.finished && <div className='cwp-finished'>
						<img alt="" src='/images/wizard-finished.svg' /><br />
						<div className='cwp-finished-title'><Trans i18nKey="cwp-finished-title">Thank you for your helpful submission.</Trans></div><br />
						<div className='cwp-finished-body'><Trans i18nKey="cwp-finished-body">This information will be shared with your energy advisor.<br/>
							<br/>
							Also, your Energy Savings Potential Report has been updated. You can access it at the top of the dashboard page, or by clicking below.
						</Trans></div>
						<br/>
						<button className="cwp-view-report-button" onClick={() => window.gotoPage('report')}>
							{this.T('View Report')}
						</button>
					</div>}
				</div>
				<footer className="cwp-footer">
					{this.theme.isSimptek ? <div style={{height: '16px'}} /> : undefined}
					<div className='cwp-fspace' />{this.theme.footer.copyright[lang]}<br />{!this.theme.isSimptek ? <span><Trans i18nKey="simptek-copyright">Powered by <a href="https://www.climative.ai">Climative</a></Trans></span> : undefined}
				</footer>
			</div>
		);

	}
}

////

function mapStateToProps(state) {
	let building = state.buildings.curBuilding.building || {};
	return {
		model: state.buildings.curModel,
		base64_report: state.buildings.loaded_b64_report && state.buildings.loaded_b64_report.base64_report,
		building: state.buildings.curBuilding.building,
		currentUser: state.auth.currentUser,
		lastPublished: state.buildings.curModel.lastPublished,
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CustomerWizardPage)));
