import moment from "moment";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./BuildingAcceptInvitePage.scss";
import { withTranslation, Trans } from "react-i18next";
import Themes from "./Themes";
import "./Themes.scss";
import axios from 'axios';
import config from "../../config";
import { toast } from 'react-toastify';
import { loginUser } from '../../actions/auth';

/////

let THEME;

//

class BuildingAcceptInvitePage extends PureComponent {
	constructor(props) {
		super(props);

		this.T = props.t;

		this.state = {
            loading: false
		};

		THEME = this.theme = Themes.autodetect();
	}

	static propTypes = {
		building: PropTypes.object,
	};

	static defaultProps = {
		building: {},
	};

    async rejectInvite(token) {
        try {
            let resp = await axios.post('/v3/users/invite/decline', {token});
            if (resp.status === 200) {
                return resp.data;
            }
            return false;
        }
        catch (err) {
            console.error('EAAcceptRejectPage: Error rejecting invite');
            return false;
        }
    }

    rejectInviteClick() {
        if (window.location.href.indexOf('?token=') > 0) {
            this.setState({ loading: true });
            (async () => {
                let token = window.location.href.split('?token=')[1];
                let res;
                if (res = await this.rejectInvite(token)) {
                    toast.success(this.T('Invite declined.'));
                    setTimeout(() => {
                        window.gotoPage('map');
                    }, 4000);
                    window.close();
                }
                else {
                    toast.error(this.T('Unable to decline invite.'));
                    setTimeout(() => {
                        window.gotoPage('map');
                    }, 4000);
                }
            })();
        }
    }

	componentDidMount() {
        this.rejectInviteClick();
	}

	componentDidUpdate(prevProps) {
	}

	render() {
		document.body.className = "customer-page " + this.theme.className;

		return (
			<div className="customer-dashboard-page">
				<div role="alert">
					<div className={'loading ' + (this.state.loading ? 'loading-show' : 'loading-hide')}>{this.state.loading && <p className="sr-only">{this.T("Loading")}</p>}<img alt="" src='/images/loading-transparent.gif'/>{this.state.loadingClaiming ? <div className="loading-claiming-msg">{this.T("This may take a minute, please wait...")}</div> : undefined}</div>
				</div>
			</div>
		);
	}
}

////

function mapStateToProps(state) {
	return {
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(BuildingAcceptInvitePage)));
