import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { HashRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ConnectedRouter } from 'connected-react-router';
import { getHistory } from '../index';
import { UserRoute, PublicRoute } from './RouteComponents';
import themes from './CustomerPages/Themes';
import '../styles/theme.scss';
import LayoutComponent from '../components/Layout';
import { loadCurrentUser, logoutCustomer, loginUser, getCustomerBuildings } from '../actions/auth';
import { clearBuildingRequest } from '../actions/buildings';
import BuildingAcceptInvitePage from './CustomerPages/BuildingAcceptInvitePage';
import BuildingRejectInvitePage from './CustomerPages/BuildingRejectInvitePage';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import CPrintableReport from './CustomerPages/components/CPrintableReport';
import axios from 'axios';
import i18next from 'i18next';

const { isAuthenticated, getCurrentPage, isLoggedIn } = require('../helpers/utils');
const ToastCloseButton = ({ closeToast }) => <i onClick={closeToast} className="la la-close notifications-close" />

// Page Change Event tracking
let lastURL;
window.addEventListener('popstate', function (event) {
  if (lastURL !== window.location.href) {
    if (window.location.href.indexOf("id_token=") < 0 && window.location.href.indexOf("&error_description") < 0) {
      if (!window.HIDE_INTERCOM) {
        window.Intercom("update");
      }
      if (window.TRACK_EVENT) {
        window.TRACK_EVENT({ eventId: 'page-change' });
      }
    }
    lastURL = window.location.href;
  }
});

// Front end feature flag checking
setInterval(() => {
  if (window.location.href.indexOf("?c7e-feature-flag=") >= 0) {
    const toks = window.location.href.split("?c7e-feature-flag=");
    window.localStorage.setItem('c7e-feature-flag', toks[1]);
    window.UpdateFeatureFlagHeaders();
    window.location.href = toks[0];
    window.location.reload();
  }
}, 250);

// Check if the user is signed in and has a valid token
export const signoutCheck = async () => {
  if (window.localStorage.getItem('token') && !isLoggedIn()) {
    await window.globalDispatch(logoutCustomer());
    await window.globalDispatch(clearBuildingRequest());
    window.gotoPage('map');
    toast.error(i18next.t('Session expired. Please log in again.'));
  }
};

// Sign out on experience change
const experienceChangeCheck = async (props) => {
  if (window.GET_FF('enf-sign-out-on-exp-change') && props.currentUser && props.currentUser.theme && props.currentUser.theme !== window.ORG_INFO.theme) {
    await props.dispatch(logoutCustomer());
    await props.dispatch(clearBuildingRequest());
    if (window.ORG_INFO?.theme_json?.advisorExperience) {
      window.location.href = '#/public/homeowner-map';
    }
    window.location.reload();
  }
};

// Receive b2c token and/or error
const b2cLoginTokenCheck = async (props, t) => {
  if (localStorage.getItem('last_login_start_from') === 'normal') {
    // normal flow moved to index.js to avoid flashing default theme
    localStorage.setItem('accepting_invite', 'false');
  }
  else if (localStorage.getItem('last_login_start_from') === 'invite') {
    localStorage.setItem('accepting_invite', 'true');
    // invite theme redirect moved to index.js to avoid falshing default theme
    if (window.location.href.indexOf("id_token=") >= 0) {
      const token = window.location.href.split("id_token=")[1];
      if (themes.autodetect()?.advisorExperience) {
        window.gotoPage('dashboard');
      }
      else {
        window.gotoPage('report');
      }
      await props.dispatch(loginUser({ token, is_b2c: true, is_customer: true }));
      await props.dispatch(getCustomerBuildings());
      toast.success(props.t('Invite accepted successfully.'));
      localStorage.setItem('accepting_invite', 'false');
    } else if (window.location.href.indexOf("&error_description") >= 0) {
      localStorage.setItem('accepting_invite', 'false');
      window.localStorage.setItem('b2c_error', true); // other tab will poll for this value to display error
      toast.error(props.t('Unable to login with 3rd party service.'));
      setTimeout(() => {
        const theme_url = localStorage.getItem('invite_redirect_theme');
        if (theme_url) {
          localStorage.setItem('invite_redirect_theme', '');
          window.location.href = '/' + theme_url + '#/public/homeowner-map';
        }
        else {
          window.gotoPage('map');
        }
      }, 4000);
    }
    else {
      localStorage.setItem('last_login_start_from', '');
      localStorage.setItem('accepting_invite', 'false');
    }
  }
  else {
    localStorage.setItem('accepting_invite', 'false');
  }
  return true;
};

// Redirect if user is not signed in and trying to access customer pages
const signedOutChecks = () => {
  if (getCurrentPage() !== 'map' && !isAuthenticated() && window.location.href.indexOf('-invitation') === -1 && localStorage.getItem('accepting_invite') !== 'true') {
    if (themes.autodetect()?.advisorExperience) {
      window.gotoPage('map');
    }
    else if (['report', 'notif', 'support-agent'].includes(getCurrentPage())) {
      window.gotoPage('report');
    }
  }
  return true;
}

class App extends React.PureComponent {

  constructor(props) {
    super(props);
    this.props.dispatch(loadCurrentUser());
  }

  async componentDidUpdate(prevProps) {
    await experienceChangeCheck(this.props);
  }

  render() {

    if (!b2cLoginTokenCheck(this.props)) {
      return <div />;
    }

    const IS_CUSTOMER = true;
    const IS_PUBLIC = ['map', 'report'].includes(getCurrentPage());
    const IN_ORG_SITE = !!window.ORG_INFO?.org_id;

    if (!signedOutChecks()) {
      return <div />;
    }

    return (
      <div>
        <CPrintableReport type='report' />
        <div role='status' aria-atomic='true' aria-live='polite' aria-relevant='additions'>
          <ToastContainer
            autoClose={5000}
            hideProgressBar
            closeButton={<ToastCloseButton />}
          />
        </div>
        <ConnectedRouter history={getHistory()}>
          <HashRouter>
            <Switch>
              <PublicRoute path="/accept-invitation" component={BuildingAcceptInvitePage} />
              <PublicRoute path="/decline-invitation" component={BuildingRejectInvitePage} />
              <PublicRoute path="/public/homeowner-map" dispatch={this.props.dispatch} component={LayoutComponent} />
              {IN_ORG_SITE && <Route path="/" exact render={() => <Redirect to="/public/homeowner-map" />} />}
              {!IN_ORG_SITE && <Route path="/" exact render={() => <Redirect to="/public/homeowner-map" />} />}
              {!IN_ORG_SITE && <Route path="/app" exact render={() => <Redirect to="/app/main" />} />}
              {!IN_ORG_SITE && <Route path='/e1' component={() => {
                themes.redirectToLogin(true);
                return (<div />);
              }} />}
              {isAuthenticated() ? <UserRoute path="/app" dispatch={this.props.dispatch} component={LayoutComponent} /> :
                <PublicRoute path="/app" dispatch={this.props.dispatch} component={LayoutComponent} />}
              {!IN_ORG_SITE && <Route path="/customer-login" exact render={() => <Redirect to="/public/homeowner-map" />} />}
              <Route path="/error" exact render={() => <Redirect to="/public/homeowner-map" />} />
              {(IS_PUBLIC || IS_CUSTOMER || IN_ORG_SITE) && <Redirect from="*" to="/public/homeowner-map" />}
            </Switch>
          </HashRouter>
        </ConnectedRouter>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  currentUser: store.auth.currentUser,
  loadingInit: store.auth.loadingInit,
});

export default withTranslation()(connect(mapStateToProps)(App));
