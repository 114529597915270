import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import '../Common.scss';

const { RandomKey, RandFromString } = require('../../../helpers/utils');

const iconColors = [
    '#A41034',
    '#AE6429',
    '#C29D00',
    '#026833',
    '#006085',
    '#3B2883',
    '#57116A',
    '#78244C',
    '#000000'
];

class ProfileIcon extends React.Component {
    constructor(props) {
        super(props);
        this.key = RandomKey();

        this.state = {
            firstName: props.firstName ?? '',
            lastName: props.lastName ?? '',
            email: props.email ?? '',
            color: this.getColor(props)
        }
    }

    getColor(props) {
        return iconColors[RandFromString(props.email ?? '', iconColors.length)];
    }

    componentDidUpdate(prevProps) {
        if (prevProps.firstName !== this.props.firstName || prevProps.lastName !== this.props.lastName || prevProps.email !== this.props.email) {
            this.setState({
                firstName: this.props.firstName ?? '',
                lastName: this.props.lastName ?? '',
                email: this.props.email ?? '',
                color: this.getColor(this.props)
            });
        }
    }

    render() {
        const initials = !this.state.firstName ?
            `${(this.state.email || ' ')[0]}${(this.state.email || '  ')[1]}`.toUpperCase() :
            `${(this.state.firstName || ' ')[0]}${(this.state.lastName || ' ')[0]}`.toUpperCase();
        return (
            <div key={this.key} className='en-profile-icon' style={{ backgroundColor: this.state.color }}>
                {initials}
            </div>
        );
    }
}

export default withRouter(connect(() => ({}))(ProfileIcon));