import moment from "moment";
import { getBuildingRequest, getBuildingRequestLM, downloadReportRequest } from "../../actions/buildings";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { acceptTermsAndConditions} from "../../actions/auth";
import { logoutCustomer  } from "../../actions/auth";
import axios from "axios";

import { withTranslation, Trans } from "react-i18next";

import "./CustomerTerms.scss";
import Themes from "./Themes";
import "./Themes.scss";
import i18next from 'i18next';

const { onBlurOutside } = require("../../helpers/utils");

/////

const CUSTOMER_TERMS_HTML = `
<style type="text/css">
    ul.terms-html {
        list-style-type: disc !important;
        margin-left: 0.25in;
    }
</style>
<div className="terms-html">
<p align="center" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051406"></a>
<font color="#000000"><font face="Calibri, serif"><font size="4" style="font-size: 16pt"><b>Climative
Terms and Conditions of Use</b></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a891809"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Acceptance
of the Website Terms and Conditions of Use</b></u></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051407"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">These
website terms and conditions of use for climative.ai constitute a
legal agreement and are entered into by and between you and Climative
(&quot;<b>Company</b>,&quot; &quot;<b>we</b>,&quot; &quot;<b>us</b>,&quot;
&quot;<b>our</b>&quot;). The following terms and conditions (these
&quot;<b>Terms and Conditions</b>&quot;), govern your access to and
use of, including any content, functionality, and services offered on
or through climative.ai (the &quot;<b>Website</b>&quot;).</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051408"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><b>BY
USING THE WEBSITE OR BY CLICKING TO ACCEPT THE TERMS AND CONDITIONS,
YOU ACCEPT AND AGREE TO BE BOUND AND COMPLY WITH THESE TERMS AND
CONDITIONS AND OUR PRIVACY POLICY, FOUND AT CLIMATIVE.AI,
INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS
AND CONDITIONS OR THE PRIVACY POLICY, YOU MUST NOT ACCESS OR USE THE
WEBSITE.</b></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051409"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">By
using this Website, you represent and warrant that you are of the
legal age of majority under applicable law to form a binding contract
with Climative and meet all of the foregoing eligibility
requirements. If you do not meet all of these requirements, you must
not access or use the Website.</font></font></font></p>
<p class="western" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><b>For
more information about your data and how Climative uses it
(including, Who Can See My Home Rating and Home Energy Report? Where
Does Climative Find Information About My Home? How can I Hide my
Data? What user authentication does Climative use?  and What types of
data are kept on the Climative platform?), please see</b></font></font></font><font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">
</font></font></font><font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><b>the
“About my Data” article on our knowledge base at Climative.ai</b></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a456309"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Modifications
to the Terms and Conditions and to the Website </b></u></font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051480"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">We
reserve the right in our sole discretion to revise and update these
terms and conditions from time to time. Any and all such
modifications are effective immediately upon posting and apply to all
access to and continued use of the Website. You agree to periodically
review the terms and conditions in order to be aware of any such
modifications and your continued use shall be your acceptance of
these. </font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051484"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">The
information and material on this Website may be changed, withdrawn,
or terminated at any time in our sole discretion without notice. We
will not be liable if, for any reason, all or any part of the Website
is restricted to users or unavailable at any time or for any period.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1004439"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Your
Use of the Website and Account Set-Up and Security</b></u></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051413"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">The
security of your personal information is very important to us. We use
physical, electronic, and administrative measures designed to secure
your personal information from accidental loss and from unauthorized
access, use, alteration, and disclosure. </font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a292411"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">The
safety and security of your information also depends on you. Users
are responsible for obtaining their own access to the Website. Users
are required to ensure that all persons who access the Website
through a user's internet connection are aware of these Terms and
Conditions and comply with them. The Website, including content or
areas of the Website, may require user registration. It is a
condition of your use of the Website that all the information you
provide on the Website is correct, current, and complete. </font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a611263"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Unfortunately,
the transmission of information via the Internet is not completely
secure. Although we do our best to protect your personal information,
we cannot guarantee the security of your personal information
transmitted to our Website. Any transmission of personal information
is at your own risk. We are not responsible for circumvention of any
privacy settings or security measures contained on the Website.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051511"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Your
provision of registration information and any submissions you make to
the Website through any functionality (collectively, &quot;<b>Interactive
Functions</b>&quot;) constitutes your consent to all actions we take
with respect to such information consistent with our Privacy Policy,
found at Climative.ai.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051415"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Any
username, password, or any other piece of information chosen by you,
or provided to you as part of our security procedures, must be
treated as confidential, and you must not disclose it to any other
person or entity. You must exercise caution when accessing your
account from a public or shared computer so that others are not able
to view or record your password or other personal information. You
understand and agree that should you be provided an account, your
account is personal to you and you agree not to provide any other
person with access to this Website or portions of it using your
username, password, or other security information. You agree to
notify us immediately of any unauthorized access to or use of your
username or password or any other breach of security. You also agree
to ensure that you logout from your account at the end of each
session. You are responsible for any password misuse or any
unauthorized access. </font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051416"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">We
reserve the right at any time and from time to time, to disable or
terminate your account, any username, password, or other identifier,
whether chosen by you or provided by us, in our sole discretion for
any or no reason, including any violation of any provision of these
Terms and Conditions.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051485"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">You
are prohibited from attempting to circumvent and from violating the
security of this Website, including, without limitation: (a)
accessing content and data that is not intended for you; (b)
attempting to breach or breaching the security and/or authentication
measures which are not authorized; (c) restricting, disrupting, or
disabling service to users, hosts, servers, or networks; (d)
illicitly reproducing TCP/IP packet header; (e) disrupting network
services and otherwise disrupting Website owner's ability to monitor
the Website; (f) using any robot, spider, or other automatic device,
process, or means to access the Website for any purpose, including
monitoring or copying any of the material on the Website; (g)
introducing any viruses, trojan horses, worms, logic bombs, or other
material that is malicious or technologically harmful; (h) attacking
the Website via a denial-of-service attack, distributed
denial-of-service attack, flooding, mailbombing, or crashing; and (i)
otherwise attempting to interfere with the proper working of the
Website.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051401"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Intellectual
Property Rights and Ownership</b></u></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051417"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">You
understand and agree that the Website and its entire contents,
features, and functionality, including, but not limited to, all
information, software, code, data text, displays, graphics,
photographs, images, video, audio, music, broadcast, design,
presentation, website layout, selection, and arrangement, are owned
by the Company, its licensors, or other providers of such material
and are protected in all forms by intellectual property laws
including, without limitation, copyright, trademark, patent, trade
secret, and any other proprietary rights. </font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051487"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Climative
and all related names, logos, product and service names, designs,
images, and slogans are trademarks of Climative or its affiliates or
licensors. You must not use such marks without the prior written
permission of the Company. Other names, logos, product and service
names, designs, images, and slogans mentioned, or which appear on
this Website are the trademarks of their respective owners. Use of
any such property, except as expressly authorized, shall constitute
an infringement or violation of the rights of the property owner and
may be a violation of federal or other laws and could subject the
infringer to legal action.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051418"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">You
may only use the Website for your personal and non-commercial use.
You shall not directly or indirectly reproduce, compile for an
internal database, distribute, modify, create derivative works of,
publicly display, publicly perform, republish, download, store, or
transmit any of the material on our Website, in any form or medium
whatsoever except: </font></font></font>
</p>
<ol>
	<ol type="a">
		<li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a667288"></a>
		<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">your
		computer and browser may temporarily store or cache copies of
		materials being accessed and viewed;</font></font></font></p></li>
		<li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a508401"></a>
		<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">a
		reasonable number of copies for personal use only may be printed
		keeping any proprietary notices thereon, which may only be used for
		non-commercial and lawful personal use and not for further
		reproduction, publication, or distribution of any kind on any
		medium whatsoever;/and</font></font></font></p></li>
		<li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a625522"></a>
		<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">one
		single user copy may be downloaded with any proprietary notices
		intact, for your own personal, non-commercial use, conditional on
		your agreement to be bound by our end user licence agreement for
		such downloads.</font></font></font></p></li>
	</ol>
</ol>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051419"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Users
are not<b> </b>permitted to modify copies of any materials from this
Website nor delete or alter any copyright, trademark, or other
proprietary rights notices from copies of materials from this site.
You must not access or use for any commercial purposes any part of
the Website or any services or materials available through the
Website.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051422"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">If
you print off, copy, or download any part of our Website in breach of
these Terms and Conditions, your right to use the Website will cease
immediately and you must, at our option, return or destroy any copies
of the materials you have made. You have no right, title, or interest
in or to the Website or to any content on the Website, and all rights
not expressly granted are reserved by the Company. Any use of the
Website not expressly permitted by these Terms and Conditions is a
breach of these Terms and Conditions and may infringe or violate
copyright, trademark, and other intellectual property or other
proprietary laws.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051488"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Conditions
of Use and User Submissions and Site Content Standards</b></u></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051424"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">As
a condition of your access and use of the Website, you agree that you
may use the Website only for lawful purposes and in accordance with
these Terms and Conditions. </font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051516"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">The
following site content standards apply to any and all content,
material, and information a user submits, posts, publishes, displays,
or transmits (collectively, &quot;<b>submit</b>&quot;) to the website
or to other users or persons (collectively, &quot;<b>User
Submissions</b>&quot;) and any and all Interactive Functions. Any and
all User Submissions must also comply with all applicable federal,
provincial, local, and international laws, regulations, and terms of
service. </font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051489"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Without
limiting the foregoing, you warrant and agree that your use of the
Website and any User Submissions shall not:</font></font></font></p>
<ol>
	<ol type="a">
		<li value="1"><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051517"></a>
		<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">In
		any manner violate any applicable federal, provincial, local, or
		international law or regulation including, without limitation, any
		laws regarding the export of data or software, patent, trademark,
		trade secret, copyright, or other intellectual property, legal
		rights (including the rights of publicity and privacy of others) or
		contain any material that could give rise to any civil or criminal
		liability under applicable laws or regulations or that otherwise
		may be in conflict with these Terms and Conditions and our Privacy
		Policy found at Climative.ai.</font></font></font></p></li>
	</ol>
</ol>
<ol>
	<ol type="a" start="2">
		<li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a459036"></a>
		<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">In
		any manner violate the terms of use of any third-party website that
		is linked to the Website, including but not limited to, any
		third-party social media website.</font></font></font></p></li>
		<li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051518"></a>
		<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Include
		or contain any material that is exploitive, obscene, harmful,
		threatening, abusive, harassing, hateful, defamatory, sexually
		explicit or pornographic, violent, inflammatory, or discriminatory
		based on race, sex, religion, nationality, disability, sexual
		orientation, or age or other such legally prohibited ground or be
		otherwise objectionable, such determination to be made in Company's
		sole discretion.</font></font></font></p></li>
		<li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051519"></a>
		<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Involve
		stalking, attempting to exploit or harm any individual (including
		minors) in any way by exposing them to inappropriate content or
		otherwise or ask for personal information as prohibited under
		applicable laws, regulations, or code.</font></font></font></p></li>
		<li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051520"></a>
		<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Involve,
		provide, or contribute any false, inaccurate, or misleading
		information. </font></font></font>
		</p></li>
		<li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051521"></a>
		<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Include
		sending, knowingly receiving, uploading, downloading, using, or
		reusing any material that does not comply with the Conditions of
		Use and User Submissions and Site Content Standards. </font></font></font>
		</p></li>
		<li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a112516"></a>
		<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Impersonate
		or attempt to impersonate the Company, a Company employee, another
		user, or any other person or entity (including, without limitation,
		by using email addresses or screen names associated with any of the
		foregoing).</font></font></font></p></li>
		<li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051523"></a>
		<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Transmit,
		or procure the sending of, any advertisements or promotions without
		our prior written consent, sales, or encourage any other commercial
		activities, including, without limitation, any &quot;spam&quot;,
		&quot;junk mail&quot;, &quot;chain letter&quot;, contests,
		sweepstakes and other sales promotions, barter, or advertising or
		any other similar solicitation. </font></font></font>
		</p></li>
		<li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051524"></a>
		<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Encourage
		any other conduct that restricts or inhibits anyone's use or
		enjoyment of the Website, or which, as determined by us, may harm
		Climative or users of the Website or expose them to liability.</font></font></font></p></li>
		<li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051525"></a>
		<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Cause
		annoyance, inconvenience, or needless anxiety or be likely to
		upset, embarrass, or alarm any other person.</font></font></font></p></li>
		<li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051526"></a>
		<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Promote
		any illegal activity, or advocate, promote, or assist any unlawful
		act.</font></font></font></p></li>
		<li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051527"></a>
		<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Give
		the impression that they originate from or are endorsed by us or
		any other person or entity, if this is not the case.</font></font></font></p></li>
	</ol>
</ol>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a583166"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>User
Submissions: Grant of Licence</b></u></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051427"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">The
Website may contain Interactive Functions allowing User Submissions
on or through the Website.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051490"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">None
of the User Submissions you submit to the Website will be subject to
any confidentiality by the Company. By providing any User Submission
to the Website, you grant us and our licensees, successors, and
assigns the right to a world-wide, royalty free, perpetual,
irrevocable, non-exclusive licence to use, reproduce, modify,
perform, display, distribute, and otherwise disclose to third parties
any such material for any purpose and according to your account
settings and/or incorporate such material into any form, medium or
technology throughout the world without compensation to you. You
further waive any moral rights or other rights of authorship as a
condition of submitting any User Submission.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051430"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">By
submitting the User Submissions, you declare and warrant that you own
or have the necessary rights to submit the User Submissions and have
the right to grant the licence hereof to us and our affiliates and
service providers, and each of their and our respective licensees,
successors, and assigns to the User Submissions and comply with these
Terms and Conditions. You represent and warrant that all User
Submissions comply with applicable laws and regulations and the
Conditions of Use and User Submissions and Site Content Standards set
out in these Terms and Conditions.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051431"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">You
understand and agree that you, not Climative, are fully responsible
for any User Submissions you submit or contribute, and you are fully
responsible and legally liable, including to any third party, for
such content and its accuracy. We are not responsible or legally
liable to any third party for the content or accuracy of any User
Submissions submitted by you or any other user of the Website. </font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a454764"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Site
Monitoring and Enforcement, Suspension, and Termination</b></u></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051433"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Company
has the right, without provision of notice to:</font></font></font></p>
<ul class="terms-html">
	<li><p align="left" style="line-height: 100%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Remove
	or refuse to post on the Website any User Submissions for any or no
	reason in our sole discretion.</font></font></font></p></li>
	<li><p align="left" style="line-height: 100%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">At
	all times, take such actions with respect to any User Submission
	deemed necessary or appropriate in our sole discretion, including,
	without limitation, for violating these Terms and Conditions.</font></font></font></p></li>
	<li><p align="left" style="line-height: 100%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Take
	appropriate legal action, including, without limitation, referral to
	law enforcement or regulatory authority, or notifying the harmed
	party of any illegal or unauthorized use of the Website. Without
	limiting the foregoing, we have the right to fully cooperate with
	any law enforcement authorities or court order requesting or
	directing us to disclose the identity or other information of anyone
	posting any materials on or through the Website.</font></font></font></p></li>
	<li><p align="left" style="line-height: 100%; margin-bottom: 0.08in">
	<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Terminate
	or suspend your access to all or part of the Website for any or no
	reason, including, without limitation, any violation of these Terms
	and Conditions.</font></font></font></p></li>
</ul>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051434"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">YOU
WAIVE AND HOLD HARMLESS CLIMATIVE FROM ANY AND ALL CLAIMS RESULTING
FROM ANY ACTION TAKEN BY CLIMATIVE RELATING TO ANY, INVESTIGATIONS BY
EITHER CLIMATIVE OR BY LAW ENFORCEMENT AUTHORITIES.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051435"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">We
have no obligation, nor any responsibility to any party to monitor
the Website or its use, and do not and cannot undertake to review
material that you or other users submit to the Website. We cannot
ensure prompt removal of objectionable material after it has been
posted and we have no liability for any action or inaction regarding
transmissions, communications, or content provided by any user or
third party, subject to applicable laws. </font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051491"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>No
Reliance </b></u></font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051438"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">The
content on our Website is provided for general information purposes
only. It is not intended to amount to advice on which you should
rely. You must obtain more specific or professional advice before
taking, or refraining from, any action or inaction on the basis of
the content on our site.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051492"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Although
we make reasonable efforts to update the information on our Website,
we make no representations, warranties, or guarantees, whether
express or implied, that the content on our Website is accurate,
complete, or up to date. Your use of the Website is at your own risk
and Climative has no responsibility or liability whatsoever for your
use of this Website.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051493"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">This
Website may include content provided by third parties, including from
other users and third-party licensors. All statements and/or opinions
expressed in any such third-party content, other than the content
provided by the Company, are solely the opinions and the
responsibility of the person or entity providing those materials.
Such materials do not necessarily reflect the opinion of the Company.
Climative has no responsibility or liability whatsoever to you, or
any third party, for the content or accuracy of any third-party
materials.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a212305"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Privacy</b></u></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051441"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">By
submitting your personal information and using our Website, you
consent to the collection, use, reproduction, hosting, transmission,
and disclosure of any such user content submissions in compliance
with our Privacy Policy, found at Climative.ai as we deem necessary
for use of the Website and provision of services. </font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Support
Agent Access</b></u></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">To
provide assistance when you encounter issues, users with Support
Agent permissions for the specific experience you are using will have
access to view and edit the same information visible to you within
the application.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Each
&quot;experience&quot; in our application is configured separately
for different partner organizations. For example, a
state/province/municipality may sponsor a branded experience.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">The
only users with Support Agent permissions for a particular experience
are:</font></font></font></p>
<ul class="terms-html">
	<li><p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
	<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Authorized
	representatives from <b>Climative</b>, the developer of the
	application.</font></font></font></p></li>
	<li><p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
	<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Authorized
	members of the <b>sponsor organization</b> that has purchased and
	configured the experience you are using.</font></font></font></p></li>
</ul>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">By
using the application, you acknowledge and consent to this level of
access for support and troubleshooting purposes.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a452006"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Third-Party
Websites </b></u></font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051495"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">For
your convenience, this Website may provide links or pointers to
third-party sites. We make no representations about any other
websites that may be accessed from this Website. If you choose to
access any such sites, you do so at your own risk. We have no control
over the contents of any such third-party sites and accept no
responsibility for such sites or for any loss or damage that may
arise from your use of them. You are subject to any terms and
conditions of such third-party sites. </font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051530"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Geographic
Restrictions</b></u></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051451"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">We
provide this Website for use only by persons located in Canada. This
Website is not intended for use in any jurisdiction where its use is
not permitted. If you access the Website from outside Canada, you do
so at your own risk and you are responsible for compliance with local
laws of your jurisdiction.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a107208"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Disclaimer
of Warranties</b></u></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051452"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">YOU
UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE, ITS CONTENT, AND
ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE IS AT
YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
FOUND OR ATTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN &quot;AS IS&quot;
AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OR
CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT
LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT
AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER
APPLICABLE LAW.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051497"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">NEITHER
CLIMATIVENOR ITS PARENT, SUBSIDIARIES, AFFILIATES, OR THEIR
RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS,
CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE ANY
WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE
COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY,
OR AVAILABILITY OF THE WEBSITE OR ITS CONTENTS. WITHOUT LIMITING THE
FOREGOING, NEITHER CLIMATIVENOR ITS PARENT, SUBSIDIARIES, AFFILIATES
OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR
SUCCESSORS REPRESENT OR WARRANT THAT THE WEBSITE, ITS CONTENT, OR ANY
SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE WILL BE
ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL
BE CORRECTED, THAT OUR WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE
ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051498"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">WE
CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE
FOR DOWNLOADING FROM THE INTERNET OR THE WEBSITE WILL BE FREE OF
VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY
RESPONSIBLE FOR YOUR USE OF THE WEBSITE AND YOUR COMPUTER, INTERNET,
AND DATA SECURITY. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT
BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK,
DISTRIBUTED DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING,
MAILBOMBING, OR CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS,
OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR
COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS
FOUND OR ATTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY
MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a136928"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Limitation
on Liability</b></u></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051457"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">EXCEPT
WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO CIRCUMSTANCE
WILL CLIMATIVENOR ITS PARENT, SUBSIDIARIES, AFFILIATES, OR THEIR
RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS,
CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS BE LIABLE
FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION,
FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL,
OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY,
PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF
PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS
OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT, BREACH OF
CONTRACT, BREACH OF PRIVACY, OR OTHERWISE, EVEN IF THE PARTY WAS
ALLEGEDLY ADVISED OR HAD REASON TO KNOW, ARISING OUT OF OR IN
CONNECTION WITH YOUR USE, OR INABILITY TO USE, OR RELIANCE ON, THE
WEBSITE, ANY LINKED WEBSITES OR SUCH OTHER THIRD-PARTY WEBSITES, NOR
ANY WEBSITE CONTENT, MATERIALS, POSTING, OR INFORMATION THEREON.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051459"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Indemnification</b></u></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051460"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">To
the maximum extent permitted by applicable law, you agree to defend,
indemnify, and hold harmless the Company, its parent, subsidiaries,
affiliates, and their respective directors, officers, employees,
agents, service providers, contractors, licensors, suppliers,
successors, and assigns from and against any claims, liabilities,
damages, judgments, awards, losses, costs, expenses, or fees
(including reasonable legal fees) arising out of or relating to your
breach of these Terms and Conditions or your use of the Website,
including, but not limited to, your User Submissions, third-party
sites, any use of the Website's content, services, and products other
than as expressly authorized in these Terms and Conditions.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a699054"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Governing
Law and Choice of Forum</b></u></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051462"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">The
Website and these Terms and Conditions will be governed by and
construed in accordance with the laws of the Province of New
Brunswick and the federal laws of Canada applicable therein, without
giving effect to any choice or conflict of law provision, principle,
or rule (whether of the laws of the Province of Ontario or any other
jurisdiction) and notwithstanding your domicile, residence, or
physical location.  </font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051499"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Any
action or proceeding arising out of or relating to this Website and
under these Terms and Conditions will be instituted in the courts of
the Province of Ontario and/or the Federal Court of Canada, and each
party irrevocably submits to the exclusive jurisdiction of such
courts in any such action or proceeding. You waive any and all
objections to the exercise of jurisdiction over you by such courts
and to the venue of such courts.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051531"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Waiver
</b></u></font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a406642"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">No
failure to exercise, or delay in exercising, any right, remedy,
power, or privilege arising from these Terms and Conditions operates,
or may be construed, as a waiver thereof; and no single or partial
exercise of any right, remedy, power, or privilege hereunder
precludes any other or further exercise thereof or the exercise of
any other right, remedy, power, or privilege.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051507"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Severability</b></u></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051508"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">If
any term or provision of these Terms and Conditions is invalid,
illegal, or unenforceable in any jurisdiction, such invalidity,
illegality, or unenforceability shall not affect any other term or
provision of these Terms and Conditions or invalidate or render
unenforceable such term or provision in any other jurisdiction.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a361445"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Entire
Agreement</b></u></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051469"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">The
Terms and Conditions and our Privacy Policy constitute the sole and
entire agreement between you and Climative regarding the Website and
supersedes all prior and contemporaneous understandings, agreements,
representations, and warranties, both written and oral, regarding
such subject matter. </font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051470"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Reporting
and Contact</b></u></font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051471"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">This
website is operated by Climative.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051510"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Should
you become aware of misuse of the website including libelous or
defamatory conduct, you must report it to Climative at
info@climative.ai. </font></font></font>
</p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051473"></a>
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">All
other feedback, comments, requests for technical support, and other
communications relating to the Website should be directed to
info@climative.ai.</font></font></font></p>
<p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
<font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">2025-03-17</font></font></font></p>
<p lang="en-US" align="left" style="line-height: 100%; margin-top: 0.08in; margin-bottom: 0in">
<br/>

</p>
<p class="western" align="left" style="line-height: 100%; margin-top: 0.03in; margin-bottom: 0.03in; background: #ffffff">
<br/>
<br/>

</p>
</div>
`;

const CUSTOMER_TERMS_HTML_FR = `
<style type="text/css">
    ul.terms-html {
        list-style-type: disc !important;
        margin-left: 0.25in;
    }
</style>
<div className="terms-html">
    <p align="center" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051406"></a>
    <font color="#000000"><font face="Calibri, serif"><font size="4" style="font-size: 16pt"><b>Conditions d'utilisation de Climative</b></font></font></font></p>
    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a891809"></a>
    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Acceptation des conditions d'utilisation du site Web</b></u></font></font></font></p>
    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051407"></a>
    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Les conditions d'utilisation de ce site Web pour climative.ai constituent un
    accord légal entre vous et Climative ("<b>Entreprise</b>", "<b>nous</b>", "<b>notre</b>"). Les conditions suivantes (ces "<b>Conditions d'utilisation</b>") régissent votre accès et votre utilisation, y compris tout contenu, fonctionnalité et service offerts sur ou par le biais de climative.ai (le "<b>Site Web</b>").</font></font></font></p>
    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051408"></a>
    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><b>EN UTILISANT LE SITE WEB OU EN CLIQUANT POUR ACCEPTER LES CONDITIONS D'UTILISATION,
    VOUS ACCEPTEZ D'ÊTRE LIÉ ET DE VOUS CONFORMER À CES CONDITIONS D'UTILISATION ET À NOTRE POLITIQUE DE CONFIDENTIALITÉ, DISPONIBLE SUR CLIMATIVE.AI, INCORPORÉE PAR RÉFÉRENCE DANS LES PRÉSENTES. SI VOUS N'ACCEPTEZ PAS CES CONDITIONS D'UTILISATION OU LA POLITIQUE DE CONFIDENTIALITÉ, VOUS NE DEVEZ PAS ACCÉDER NI UTILISER LE SITE WEB.</b></font></font></font></p>
    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051409"></a>
    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">En utilisant ce Site Web, vous déclarez et garantissez que vous êtes en âge légal de conclure un contrat contraignant avec Climative et remplissez toutes les conditions d'éligibilité susmentionnées. Si vous ne remplissez pas toutes ces conditions, vous ne devez pas accéder ni utiliser le Site Web.</font></font></font></p>
    <p class="western" style="line-height: 100%; margin-bottom: 0in"><font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><b>Pour
    plus d'informations sur vos données et la façon dont Climative les utilise
    (y compris, Qui peut voir ma cote énergétique de maison et mon rapport énergétique ? Où Climative trouve-t-il les informations sur ma maison ? Comment puis-je cacher mes données ? Quelle authentification des utilisateurs Climative utilise-t-il ? et Quels types de données sont conservés sur la plateforme Climative ?), veuillez consulter</b></font></font></font><font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">
    </font></font></font><font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><b>l'article "À propos de mes données" dans notre base de connaissances sur Climative.ai</b></font></font></font></p>
    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a456309"></a>
    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Modifications des Conditions d'utilisation et du Site Web</b></u></font></font></font>
    </p>
    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051480"></a>
    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Nous nous réservons le droit, à notre seule discrétion, de réviser et de mettre à jour ces conditions d'utilisation de temps à autre. Toute modification de ces conditions entre en vigueur immédiatement après sa publication et s'applique à tout accès et à toute utilisation continue du Site Web. Vous acceptez de revoir périodiquement les conditions d'utilisation afin de prendre connaissance de toute modification et votre utilisation continue constituera votre acceptation de celles-ci. </font></font></font>
    </p>
    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051484"></a>
    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Les informations et le matériel de ce Site Web peuvent être modifiés, retirés ou résiliés à tout moment, à notre seule discrétion et sans préavis. Nous ne serons pas responsables si, pour quelque raison que ce soit, tout ou une partie du Site Web est restreint aux utilisateurs ou indisponible à tout moment ou pour toute période.</font></font></font></p>
    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1004439"></a>
    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Votre utilisation du Site Web et l'activation de votre compte et sécurité</b></u></font></font></font></p>
    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051413"></a>
    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">La sécurité de vos informations personnelles est très importante pour nous. Nous utilisons des mesures physiques, électroniques et administratives conçues pour sécuriser vos informations personnelles contre toute perte accidentelle ainsi que contre tout accès, utilisation, altération ou divulgation non autorisée. </font></font></font>
    </p>
    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a292411"></a>
    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">La sécurité de vos informations dépend également de vous. Les utilisateurs sont responsables d'obtenir leur propre accès au Site Web. Les utilisateurs doivent veiller à ce que toutes les personnes qui accèdent au Site Web par leur connexion Internet soient informées de ces Conditions d'utilisation et y adhèrent. Le Site Web, y compris son contenu ou ses sections, peut nécessiter une inscription de l'utilisateur. Il est conditionné par votre utilisation du Site Web que toutes les informations que vous fournissez sur le Site Web soient exactes, actuelles et complètes. </font></font></font>
    </p>
    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a611263"></a>
    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Malheureusement, la transmission d'informations via Internet n'est pas complètement sécurisée. Bien que nous fassions de notre mieux pour protéger vos informations personnelles, nous ne pouvons pas garantir la sécurité de vos informations personnelles transmises à notre Site Web. Toute transmission d'informations personnelles se fait à vos risques et périls. Nous ne sommes pas responsables de la contournement de toute protection de confidentialité ou des mesures de sécurité contenues sur le Site Web.</font></font></font></p>
    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051511"></a>
    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">La fourniture de vos informations d'inscription et de toute soumission que vous effectuez sur le Site Web par l'intermédiaire de toute fonctionnalité (collectivement, les "<b>Fonctions interactives</b>") constitue votre consentement à toutes les actions que nous entreprenons concernant ces informations, conformément à notre Politique de confidentialité, disponible sur Climative.ai.</font></font></font></p>
    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051415"></a>
    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Tout nom d'utilisateur, mot de passe ou toute autre information choisie par vous, ou fournie par nous dans le cadre de nos procédures de sécurité, doit être traitée comme confidentielle, et vous ne devez pas la divulguer à toute autre personne ou entité. Vous devez faire preuve de prudence lorsque vous accédez à votre compte depuis un ordinateur public ou partagé afin que d'autres personnes ne puissent pas voir ou enregistrer votre mot de passe ou d'autres informations personnelles. Vous comprenez et acceptez que, si vous avez un compte, celui-ci est personnel et vous vous engagez à ne pas fournir d'accès à ce Site Web ou à certaines sections de celui-ci à toute autre personne en utilisant votre nom d'utilisateur, mot de passe ou autres informations de sécurité. Vous vous engagez à nous informer immédiatement de tout accès ou utilisation non autorisé de votre nom d'utilisateur ou mot de passe ou de toute autre violation de sécurité. Vous vous engagez également à vous déconnecter de votre compte à la fin de chaque session. Vous êtes responsable de tout abus de mot de passe ou de tout accès non autorisé.</font></font></font>
    </p>
    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051416"></a>
        <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Nous
        nous réservons le droit, à tout moment et de temps à autre, de désactiver ou
        de résilier votre compte, tout nom d'utilisateur, mot de passe ou autre identifiant,
        qu'il soit choisi par vous ou fourni par nous, à notre seule discrétion, pour
        toute raison ou aucune, y compris toute violation de toute disposition de ces
        Conditions d'utilisation.</font></font></font></p>
        
        <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051485"></a>
        <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Il
        est interdit de tenter de contourner et de violer la sécurité de ce site Web,
        y compris, sans limitation : (a) accéder à des contenus et des données qui ne
        sont pas destinés à vous ; (b) tenter de contourner ou de violer la sécurité
        et/ou les mesures d'authentification qui ne sont pas autorisées ; (c) restreindre,
        perturber ou désactiver le service aux utilisateurs, hôtes, serveurs ou réseaux ;
        (d) reproduire illicitement l'en-tête du paquet TCP/IP ; (e) perturber les
        services réseau et interférer autrement avec la capacité du propriétaire du site Web
        de surveiller le site Web ; (f) utiliser un robot, une araignée ou tout autre
        dispositif, processus ou moyen automatique pour accéder au site Web à toute fin,
        y compris pour surveiller ou copier tout matériel du site Web ; (g) introduire
        des virus, chevaux de Troie, vers, bombes logiques ou tout autre matériel malveillant
        ou technologiquement nuisible ; (h) attaquer le site Web via une attaque par déni
        de service, une attaque par déni de service distribué, un inondation, une attaque par
        mailbombing ou un crash ; et (i) tenter autrement d'interférer avec le bon
        fonctionnement du site Web.</font></font></font></p>
        
        <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051401"></a>
        <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Droits
        de propriété intellectuelle et propriété</b></u></font></font></font></p>
        
        <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051417"></a>
        <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Vous
        comprenez et acceptez que le site Web et l'intégralité de son contenu,
        ses caractéristiques et sa fonctionnalité, y compris, mais sans s'y limiter, toutes
        les informations, logiciels, codes, données, textes, affichages, graphiques,
        photographies, images, vidéos, audio, musique, diffusion, conception,
        présentation, mise en page du site Web, sélection et agencement, sont la propriété
        de la Société, de ses concédants ou d'autres fournisseurs de tels matériels
        et sont protégés sous toutes leurs formes par les lois sur la propriété intellectuelle,
        y compris, mais sans s'y limiter, les droits d'auteur, les marques de commerce, les brevets,
        les secrets commerciaux et tout autre droit de propriété.</font></font></font></p>
        
        <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051487"></a>
        <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Climative
        et tous les noms associés, logos, noms de produits et services, conceptions,
        images et slogans sont des marques de commerce de Climative ou de ses affiliés ou
        concédants. Vous ne devez pas utiliser de telles marques sans l'autorisation préalable écrite
        de la Société. D'autres noms, logos, noms de produits et services, conceptions, images
        et slogans mentionnés, ou apparaissant sur ce site Web, sont les marques de commerce de
        leurs propriétaires respectifs. L'utilisation de toute propriété de ce type, sauf si elle est
        expressément autorisée, constituera une violation des droits du propriétaire et pourrait
        être une violation des lois fédérales ou autres et exposer l'infracteur à une action légale.</font></font></font></p>
        
        <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051418"></a>
        <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Vous
        ne pouvez utiliser le site Web que pour votre usage personnel et non commercial.
        Vous ne devez pas reproduire, compiler pour une base de données interne, distribuer,
        modifier, créer des œuvres dérivées, afficher publiquement, exécuter publiquement,
        republier, télécharger, stocker ou transmettre tout matériel de notre site Web, sous
        quelque forme ou support que ce soit, sauf dans les cas suivants :</font></font></font></p>
        
        <ol>
            <ol type="a">
                <li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a667288"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">votre
                ordinateur et votre navigateur peuvent temporairement stocker ou mettre en cache
                des copies des matériaux consultés et visualisés ;</font></font></font></p></li>
                <li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a508401"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">un
                quantité raisonnable de copies pour un usage personnel uniquement peut être imprimée
                en conservant les mentions de droits de propriété, et ne peut être utilisée que pour
                un usage personnel, non commercial et licite, et non pour une reproduction, publication,
                ou distribution ultérieure sous quelque forme ou support que ce soit ; et</font></font></font></p></li>
                <li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a625522"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">une
                seule copie utilisateur peut être téléchargée avec les mentions de droits de propriété
                intactes, pour votre usage personnel, non commercial, sous réserve de votre accord pour
                être lié par notre contrat de licence utilisateur final pour de tels téléchargements.</font></font></font></p></li>
            </ol>
        </ol>
        
        <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051419"></a>
        <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Les
        utilisateurs ne sont <b>pas</b> autorisés à modifier les copies de tout matériel de ce
        site Web ni à supprimer ou altérer les mentions de droits d'auteur, de marque de
        commerce ou d'autres droits de propriété sur les copies de matériaux de ce site.
        Vous ne devez pas accéder ou utiliser à des fins commerciales une partie du site Web ou
        des services ou matériaux disponibles via le site Web.</font></font></font></p>
        
        <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051422"></a>
        <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Si
        vous imprimez, copiez ou téléchargez une partie de notre site Web en violation de ces
        Conditions d'utilisation, votre droit d'utiliser le site Web cessera immédiatement et vous
        devez, à notre choix, retourner ou détruire toutes les copies des matériaux que vous avez
        faites. Vous n'avez aucun droit, titre ou intérêt sur ou envers le site Web ou son contenu,
        et tous les droits non expressément accordés sont réservés par la Société. Toute utilisation du
        site Web non expressément autorisée par ces Conditions d'utilisation constitue une violation de
        ces Conditions d'utilisation et peut enfreindre ou violer les droits d'auteur, de marque de commerce
        et d'autres droits de propriété intellectuelle ou d'autres lois de propriété.</font></font></font></p>
        
        <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051488"></a>
            <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Conditions d'utilisation et soumissions des utilisateurs et normes de contenu du site</b></u></font></font></font></p>
            <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051424"></a>
            <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Comme condition d'accès et d'utilisation du site Web, vous acceptez de n'utiliser le site Web que dans des buts légaux et conformément à ces conditions générales.</font></font></font>
            </p>
            <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051516"></a>
            <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Les normes suivantes de contenu du site s'appliquent à tout contenu, matériel et information qu'un utilisateur soumet, publie, affiche ou transmet (collectivement, "<b>soumettre</b>") sur le site Web ou à d'autres utilisateurs ou personnes (collectivement, "<b>soumissions des utilisateurs</b>") et à toutes les fonctions interactives. Toutes les soumissions des utilisateurs doivent également respecter toutes les lois, règlements et conditions de service fédéraux, provinciaux, locaux et internationaux applicables.</font></font></font>
            </p>
            <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051489"></a>
            <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Sans limiter ce qui précède, vous garantissez et acceptez que votre utilisation du site Web et toute soumission des utilisateurs ne doivent pas :</font></font></font></p>
            <ol>
                <ol type="a">
                    <li value="1"><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051517"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">En aucune manière violer toute loi ou règlement fédéral, provincial, local ou international applicable, y compris, mais sans s'y limiter, toute loi concernant l'exportation de données ou de logiciels, brevet, marque de commerce, secret commercial, droit d'auteur ou autre propriété intellectuelle, droits légaux (y compris les droits de publicité et de confidentialité d'autrui) ou contenir tout matériel qui pourrait entraîner une responsabilité civile ou criminelle en vertu des lois ou règlements applicables ou qui pourrait être en conflit avec ces conditions d'utilisation et notre politique de confidentialité trouvée sur Climative.ai.</font></font></font></p></li>
                </ol>
            </ol>
            <ol>
                <ol type="a" start="2">
                    <li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a459036"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">En aucune manière violer les conditions d'utilisation de tout site tiers lié au site Web, y compris, mais sans s'y limiter, tout site Web de médias sociaux tiers.</font></font></font></p></li>
                    <li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051518"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Inclure ou contenir tout matériel qui est exploiteur, obscène, nuisible, menaçant, abusif, harcelant, haineux, diffamatoire, sexuellement explicite ou pornographique, violent, inflammatoire ou discriminatoire basé sur la race, le sexe, la religion, la nationalité, le handicap, l'orientation sexuelle ou l'âge, ou toute autre base légalement interdite, ou être autrement objectionnable, cette détermination devant être faite à la seule discrétion de l'entreprise.</font></font></font></p></li>
                    <li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051519"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Impliquer du harcèlement, tenter d'exploiter ou de nuire à toute personne (y compris les mineurs) de quelque manière que ce soit en les exposant à un contenu inapproprié ou autrement, ou demander des informations personnelles comme interdit par les lois, règlements ou codes applicables.</font></font></font></p></li>
                    <li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051520"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Impliquer, fournir ou contribuer des informations fausses, inexactes ou trompeuses.</font></font></font>
                    </p></li>
                    <li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051521"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Inclure l'envoi, la réception consciente, le téléchargement, l'utilisation ou la réutilisation de tout matériel qui ne respecte pas les conditions d'utilisation et les normes de soumissions des utilisateurs et de contenu du site.</font></font></font>
                    </p></li>
                    <li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a112516"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Impersonner ou tenter d'impliquer l'entreprise, un employé de l'entreprise, un autre utilisateur ou toute autre personne ou entité (y compris, sans s'y limiter, en utilisant des adresses e-mail ou des noms d'utilisateur associés à l'un des précédents).</font></font></font></p></li>
                    <li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051523"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Transmettre, ou obtenir l'envoi de, toute publicité ou promotion sans notre consentement écrit préalable, ventes, ou encourager toute autre activité commerciale, y compris, mais sans s'y limiter, tout "spam", "courrier indésirable", "chaîne de lettres", concours, loteries et autres promotions de vente, troc ou publicité ou toute autre sollicitation similaire.</font></font></font>
                    </p></li>
                    <li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051524"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Encourager toute autre conduite qui restreint ou inhibe l'utilisation ou le plaisir de quiconque sur le site Web, ou qui, selon nous, pourrait nuire à Climative ou aux utilisateurs du site Web ou les exposer à une responsabilité.</font></font></font></p></li>
                    <li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051525"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Causer des désagréments, des inconvénients ou une anxiété inutile, ou être susceptible de contrarier, embarrasser ou alarmer toute autre personne.</font></font></font></p></li>
                    <li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051526"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Promouvoir toute activité illégale, ou plaider, promouvoir ou aider à toute action illégale.</font></font></font></p></li>
                    <li><p align="left" style="line-height: 100%; margin-bottom: 0.17in"><a name="a1051527"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Donner l'impression qu'elles proviennent de nous ou qu'elles sont approuvées par nous ou toute autre personne ou entité, si tel n'est pas le cas.</font></font></font></p></li>
                </ol>
            </ol>
            <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a583166"></a>
            <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Soumissions des utilisateurs : concession de licence</b></u></font></font></font></p>
            <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051427"></a>
            <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Le site Web peut contenir des fonctions interactives permettant des soumissions des utilisateurs sur ou via le site Web.</font></font></font></p>
            <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051490"></a>
            <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Aucune des soumissions des utilisateurs que vous soumettez au site Web ne sera soumise à une confidentialité par l'entreprise. En fournissant toute soumission des utilisateurs au site Web, vous nous accordez ainsi qu'à nos concédants de licence, successeurs et ayants droit le droit à une licence mondiale, gratuite, perpétuelle, irrévocable, non exclusive d'utiliser, reproduire, modifier, exécuter, afficher, distribuer et autrement divulguer à des tiers tout ce matériel à toute fin et conformément à vos paramètres de compte et/ou d'intégrer ce matériel dans toute forme, support ou technologie dans le monde entier sans compensation pour vous. Vous renoncez également à tout droit moral ou autre droit d'auteur comme condition de soumettre toute soumission des utilisateurs.</font></font></font></p>
            <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051430"></a>
            <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">En soumettant des soumissions des utilisateurs, vous déclarez et garantissez que vous possédez ou avez les droits nécessaires pour soumettre les soumissions des utilisateurs et avez le droit de concéder cette licence à nous et à nos affiliés et fournisseurs de services, ainsi qu'à leurs et nos respectifs concédants de licence, successeurs et ayants droit des soumissions des utilisateurs et de respecter ces conditions d'utilisation. Vous déclarez et garantissez que toutes les soumissions des utilisateurs respectent les lois et règlements applicables ainsi que les conditions d'utilisation et les normes des soumissions des utilisateurs et du contenu du site définies dans ces conditions d'utilisation.</font></font></font></p>
            <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051494"></a>
            <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Toute soumission des utilisateurs sera traitée comme non confidentielle et non protégée par le secret, sauf mention expresse du contraire. Nous nous réservons le droit de surveiller, modifier ou supprimer toute soumission des utilisateurs, à notre seule discrétion.</font></font></font></p>
            <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051528"></a>
            <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Si vous avez des questions ou souhaitez obtenir plus d'informations sur nos conditions générales ou notre politique de confidentialité, n'hésitez pas à nous contacter.</font></font></font></p>
            
            <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a454764"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Surveillance et application du site, suspension et résiliation</b></u></font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051433"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">L'entreprise a le droit, sans préavis, de :</font></font></font></p>
                <ul class="terms-html">
                    <li><p align="left" style="line-height: 100%; margin-bottom: 0.08in">
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Supprimer ou refuser de publier sur le site Web toute soumission d'utilisateur pour toute raison ou sans raison, à notre seule discrétion.</font></font></font></p></li>
                    <li><p align="left" style="line-height: 100%; margin-bottom: 0.08in">
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Prendre toutes les mesures jugées nécessaires ou appropriées à l'égard de toute soumission d'utilisateur, y compris, mais sans s'y limiter, pour violation de ces conditions générales.</font></font></font></p></li>
                    <li><p align="left" style="line-height: 100%; margin-bottom: 0.08in">
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Prendre les mesures légales appropriées, y compris, sans s'y limiter, le renvoi aux autorités de régulation ou d'application de la loi, ou informer la partie lésée de toute utilisation illégale ou non autorisée du site Web. Sans limiter ce qui précède, nous avons le droit de coopérer pleinement avec toute autorité chargée de l'application de la loi ou ordonnance du tribunal demandant ou nous dirigeant à divulguer l'identité ou toute autre information concernant toute personne publiant des matériaux sur ou via le site Web.</font></font></font></p></li>
                    <li><p align="left" style="line-height: 100%; margin-bottom: 0.08in">
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Résilier ou suspendre votre accès à tout ou partie du site Web pour toute raison ou sans raison, y compris, sans s'y limiter, toute violation de ces conditions générales.</font></font></font></p></li>
                </ul>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051434"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">VOUS RENONCEZ ET DÉGAGEZ CLIMATIVE DE TOUTE RESPONSABILITÉ POUR TOUTE RÉCLAMATION DÉCOULANT DE TOUTE ACTION ENTREPRISE PAR CLIMATIVE RELATIVE À TOUTE ENQUÊTE MENÉE PAR CLIMATIVE OU PAR LES AUTORITÉS COMPÉTENTES.</font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051435"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Nous n’avons aucune obligation, ni aucune responsabilité envers toute partie pour surveiller le site Web ou son utilisation, et nous ne pouvons ni entreprendre ni garantir de révision des matériaux que vous ou d'autres utilisateurs soumettez au site Web. Nous ne pouvons pas garantir la suppression rapide des matériaux répréhensibles après leur publication et nous déclinons toute responsabilité quant à toute action ou inaction concernant les transmissions, communications ou contenus fournis par un utilisateur ou un tiers, sous réserve des lois applicables.</font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051491"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Aucune confiance</b></u></font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051438"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Le contenu de notre site Web est fourni à titre d'information générale uniquement. Il n'est pas destiné à constituer un conseil sur lequel vous devez vous appuyer. Vous devez obtenir des conseils plus spécifiques ou professionnels avant de prendre ou de vous abstenir de prendre toute action ou inaction sur la base du contenu de notre site.</font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051492"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Bien que nous fassions des efforts raisonnables pour mettre à jour l'information sur notre site Web, nous ne faisons aucune déclaration, garantie ou assurance, expresse ou implicite, que le contenu de notre site Web est exact, complet ou à jour. Votre utilisation du site Web se fait à vos propres risques et Climative n'a aucune responsabilité ou obligation à cet égard.</font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051493"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Ce site Web peut inclure du contenu fourni par des tiers, y compris d'autres utilisateurs et des concédants tiers. Toutes les déclarations et/ou opinions exprimées dans ce contenu de tiers, autres que le contenu fourni par l'entreprise, sont uniquement les opinions et la responsabilité de la personne ou de l'entité fournissant ces matériaux. Ces matériaux ne reflètent pas nécessairement l'opinion de l'entreprise. Climative n'a aucune responsabilité ou obligation envers vous ou tout tiers pour le contenu ou l'exactitude des matériaux de tiers.</font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a212305"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Confidentialité</b></u></font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051441"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">En soumettant vos informations personnelles et en utilisant notre site Web, vous consentez à la collecte, l'utilisation, la reproduction, l'hébergement, la transmission et la divulgation de toute soumission de contenu utilisateur conformément à notre politique de confidentialité, disponible sur Climative.ai, comme nous le jugeons nécessaire pour l'utilisation du site Web et la fourniture de services.</font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Accès aux agents de support</b></u></font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Pour fournir de l'aide lorsque vous rencontrez des problèmes, les utilisateurs disposant des autorisations d'agent de support pour l'expérience spécifique que vous utilisez auront accès pour voir et modifier les mêmes informations visibles par vous dans l'application.</font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Chaque « expérience » dans notre application est configurée séparément pour différentes organisations partenaires. Par exemple, un État/province/municipalité peut parrainer une expérience de marque.</font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Seuls les utilisateurs disposant des autorisations d'agent de support pour une expérience particulière sont :</font></font></font></p>
                <ul class="terms-html">
                    <li><p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Des représentants autorisés de <b>Climative</b>, le développeur de l'application.</font></font></font></p></li>
                    <li><p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Des membres autorisés de l'<b>organisation parrain</b> qui a acheté et configuré l'expérience que vous utilisez.</font></font></font></p></li>
                </ul>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">En utilisant l'application, vous reconnaissez et consentez à ce niveau d'accès pour les fins de support et de dépannage.</font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a452006"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Sites web tiers</b></u></font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051495"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Pour votre commodité, ce site Web peut fournir des liens ou des pointeurs vers des sites tiers. Nous ne faisons aucune déclaration concernant d'autres sites web qui peuvent être accessibles depuis ce site. Si vous choisissez d'accéder à ces sites, vous le faites à vos propres risques. Nous n'avons aucun contrôle sur le contenu de ces sites tiers et déclinons toute responsabilité pour ces sites ou toute perte ou dommage pouvant résulter de leur utilisation. Vous êtes soumis aux conditions d'utilisation de ces sites tiers.</font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051530"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Restrictions géographiques</b></u></font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051451"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Nous fournissons ce site Web uniquement pour une utilisation par les personnes situées au Canada. Ce site Web n'est pas destiné à être utilisé dans toute juridiction où son utilisation est interdite. Si vous accédez au site Web depuis l'extérieur du Canada, vous le faites à vos propres risques et êtes responsable de respecter les lois locales de votre juridiction.</font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a107208"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Indemnité</b></u></font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051465"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Vous acceptez d'indemniser, défendre et dégager de toute responsabilité Climative, ses affiliés, ses partenaires, ses prestataires de services, ses directeurs, ses employés, ses agents et ses licenciés contre toute réclamation, perte, dommage, responsabilité, frais (y compris les honoraires d'avocats raisonnables), coûts et dépenses découlant de :</font></font></font></p>
                <ul class="terms-html">
                    <li><p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Votre utilisation du site Web ou de ses services,</font></font></font></p></li>
                    <li><p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Votre soumission ou publication d'un contenu utilisateur sur le site Web,</font></font></font></p></li>
                    <li><p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Votre violation de ces conditions générales.</font></font></font></p></li>
                </ul>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051499"></a>
                <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Climative se réserve le droit d'assumer la défense et le contrôle exclusifs de toute affaire autrement soumise à indemnisation par vous, auquel cas vous coopérerez pleinement avec Climative pour faire valoir toutes les défenses disponibles. Cette indemnité survivra à la résiliation ou à l'expiration de ces conditions générales et à votre utilisation du site Web.</font></font></font></p>
                <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051459"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Indemnisation</b></u></font></font></font></p>
                    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051460"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Dans la mesure maximale permise par la loi applicable, vous acceptez de défendre, indemniser et dégager de toute responsabilité l'Entreprise, sa société mère, ses filiales, ses affiliés et leurs respectifs directeurs, dirigeants, employés, agents, prestataires de services, sous-traitants, concédants de licence, fournisseurs, successeurs et ayants droit contre toute réclamation, responsabilité, dommage, jugement, récompense, perte, coût, dépense ou frais (y compris les frais juridiques raisonnables) découlant de ou liés à votre violation de ces Conditions générales ou à votre utilisation du site Web, y compris, mais sans s'y limiter, vos Soumissions d'utilisateur, les sites tiers, toute utilisation du contenu, des services et des produits du site Web autrement que comme expressément autorisé dans ces Conditions générales.</font></font></font></p>
                    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a699054"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Droit applicable et choix du forum</b></u></font></font></font></p>
                    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051462"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Le site Web et ces Conditions générales seront régis et interprétés conformément aux lois de la province du Nouveau-Brunswick et aux lois fédérales du Canada applicables dans cette province, sans tenir compte de toute disposition, principe ou règle de conflit de lois (que ce soit les lois de la province de l'Ontario ou d'une autre juridiction), et ce, indépendamment de votre domicile, résidence ou emplacement physique.</font></font></font></p>
                    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051499"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Toute action ou procédure découlant de ou liée à ce site Web et sous ces Conditions générales sera engagée devant les tribunaux de la province de l'Ontario et/ou la Cour fédérale du Canada, et chaque partie se soumet irrévocablement à la compétence exclusive de ces tribunaux dans toute action ou procédure. Vous renoncez à toute objection à l'exercice de la compétence sur vous par ces tribunaux et à la compétence territoriale de ces tribunaux.</font></font></font></p>
                    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051531"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Renonciation</b></u></font></font></font></p>
                    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a406642"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Le fait de ne pas exercer, ou de retarder l'exercice, de tout droit, recours, pouvoir ou privilège découlant de ces Conditions générales n'opère pas, et ne peut être interprété comme une renonciation à celui-ci ; et aucun exercice unique ou partiel de tout droit, recours, pouvoir ou privilège en vertu des présentes ne préclut tout autre ou nouveau exercice de celui-ci ou l'exercice de tout autre droit, recours, pouvoir ou privilège.</font></font></font></p>
                    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051507"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Divisibilité</b></u></font></font></font></p>
                    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051508"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Si une disposition de ces Conditions générales est invalide, illégale ou inapplicable dans une juridiction, cette invalidité, illégalité ou inapplicabilité n'affectera aucune autre disposition des présentes Conditions générales ni n'invalidera ou ne rendra inapplicable cette disposition dans toute autre juridiction.</font></font></font></p>
                    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a361445"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Accord complet</b></u></font></font></font></p>
                    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051469"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Les Conditions générales et notre Politique de confidentialité constituent l'accord complet et exclusif entre vous et Climative concernant le site Web et remplacent tous les accords, compréhensions, déclarations et garanties antérieures et contemporaines, tant écrites qu'orales, concernant ce sujet.</font></font></font></p>
                    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051470"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt"><u><b>Rapports et Contact</b></u></font></font></font></p>
                    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051471"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Ce site Web est exploité par Climative.</font></font></font></p>
                    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051510"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Si vous êtes témoin d'une utilisation abusive du site Web, y compris des comportements diffamatoires ou calomnieux, vous devez le signaler à Climative à l'adresse info@climative.ai.</font></font></font></p>
                    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in"><a name="a1051473"></a>
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">Tous les autres commentaires, demandes de support technique ou autres communications relatives au site Web doivent être adressés à info@climative.ai.</font></font></font></p>
                    <p style="line-height: 100%; margin-top: 0.17in; margin-bottom: 0.17in">
                    <font color="#000000"><font face="Calibri, serif"><font size="3" style="font-size: 12pt">2025-03-17</font></font></font></p>
                    <p lang="en-US" align="left" style="line-height: 100%; margin-top: 0.08in; margin-bottom: 0in">
                    <br/>
                    </p>
                    <p class="western" align="left" style="line-height: 100%; margin-top: 0.03in; margin-bottom: 0.03in; background: #ffffff">
                    <br/>
                    <br/>
                    </p>                    
</div>
`;

class CustomerTerms extends PureComponent {
	constructor(props) {
		super(props);

		this.T = props.t;

		this.state = {
			rand: 0
		};

		this.theme = Themes.autodetect();
	}

	static propTypes = {
	};

	static defaultProps = {
	};

	componentDidMount() {
	}

	componentDidUpdate(prevProps) {
	}

    onRadioKeydown(e){
		if(e.key == 'ArrowDown'){
		  e.preventDefault();
		  const next = e.target.nextElementSibling;
		  if(next){
			next.focus();
			next.click();
		  }
		} else if(e.key == 'ArrowUp'){
		  e.preventDefault();
		  const prev = e.target.previousElementSibling;
		  if(prev){
			prev.focus();
			prev.click();
		  }
		}
	}

	switchLanguage(lang) {
		i18next.changeLanguage(lang);
        this.setState({ rand: Math.random() });
	}

	render() {

        if (!this.props.currentUser) {
			window.location.href = "#/app/customer/view-report";
			return (<div/>)
		}

		document.body.className = "customer-page " + this.theme.className;

        let termsHtml = '';
        if (this.T('hmp-lang-code') === 'EN') {
            termsHtml = window?.ORG_INFO?.customer_terms_html || CUSTOMER_TERMS_HTML;
        }
        else if (this.T('hmp-lang-code') === 'FR') {
            termsHtml = window?.ORG_INFO?.customer_terms_fr_html || CUSTOMER_TERMS_HTML_FR;
        }

        return (
			<div role="main" className="customer-terms-page">
                <div className='ph-lang-switcher' onBlur={(e) => onBlurOutside(e, () => this.setState({ langShow: false }))}>
                    <button className='phls-btn' aria-label={this.T('Change Language') + ' (' + (this.T('hmp-lang-code') === 'EN' ? 'English Selected' : 'French Selected') + ')'} aria-expanded={this.state.langShow} onClick={() => { if (!this.theme.langSwitchDisabled) { this.setState({langShow: !this.state.langShow}) } }}><img alt="" src="/images/lang-icon.svg"/>{this.T('hmp-lang-code') === 'EN' ? 'English' : 'Français'}</button>
                    <div role='radiogroup' className={'phls-dropdown ' + (this.state.langShow ? 'phls-dd-show' : '')}>
                        <button role="radio" tabIndex={this.T('hmp-lang-code') === 'EN' ? 0 : -1} onKeyDown={(e) => this.onRadioKeydown(e)} aria-checked={this.T('hmp-lang-code') === 'EN' ? "true" : "false"} className={'phlsdd-lang-option ' + (this.T('hmp-lang-code') === 'EN' ? 'phlsdd-lang-selected' : '')} onClick={() => this.switchLanguage('en')}><img alt={''} src={this.T('hmp-lang-code') === 'EN' ? "/images/hmp-radio-checked.svg" : "/images/hmp-radio-unchecked.svg" }/> English (EN)</button>
                        <button role="radio" tabIndex={this.T('hmp-lang-code') === 'FR' ? 0 : -1} onKeyDown={(e) => this.onRadioKeydown(e)} aria-checked={this.T('hmp-lang-code') === 'EN' ? "false" : "true"} className={'phlsdd-lang-option ' + (this.T('hmp-lang-code') === 'FR' ? 'phlsdd-lang-selected' : '')} onClick={() => this.switchLanguage('fr')}><img alt={''} src={this.T('hmp-lang-code') === 'FR' ? "/images/hmp-radio-checked.svg" : "/images/hmp-radio-unchecked.svg" }/> Français (FR)</button>
                    </div>
                </div>
                <div className="ctc-terms" dangerouslySetInnerHTML={{__html: termsHtml }}>
                </div>
                <div className="ctc-terms-footer">
                    <button role="checkbox" aria-checked={this.state.termsAccepted} className={"ctc-check " + (this.state.termsAccepted ? "ctc-check-checked" : "ctc-check-unchecked")} onClick={() => this.setState({ termsAccepted: !this.state.termsAccepted, rand: Math.random() })}>
                        <img alt="" src={this.state.termsAccepted ? "/images/ctc-checkbox-checked.svg" : "/images/ctc-checkbox-unchecked.svg"} />
                        <div className="ctc-checkbox-label">{this.T("I agree to the terms and conditions stated above")}</div>
                    </button><br/>
                    <button disabled={!this.state.termsAccepted} className={"ctc-save-and-continue"} onClick={() => this.props.dispatch(acceptTermsAndConditions())}>{this.T("Save and Continue")}</button>
                </div>
			</div>
		);
	}
}

////

function mapStateToProps(state) {
	return {
		currentUser: state.auth.currentUser,
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CustomerTerms)));
