import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import themes from '../../CustomerPages/Themes';
import i18next from 'i18next';
import { loginUser, logoutCustomer, getCustomerBuildings } from '../../../actions/auth';
import { Trans, withTranslation } from "react-i18next";
import '../Common.scss';
import FocusLock from 'react-focus-lock';
import { toast } from 'react-toastify';
import config from '../../../config';
import { clearBuildingRequest, getBuildingRequest, getBuildingRequestLM } from '../../../actions/buildings';
import SupportAgentPage from '../../CustomerPages/SupportAgentPage';

const { RandomKey, onBlurOutside, getCurrentPage, isLoggedIn, setCurrentPage } = require('../../../helpers/utils');
const E1Statuses = require('../../../helpers/audit-status')({advisorExperience: true});

class PublicHeader extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();

    this.theme = themes.autodetect();

    let startPage = getCurrentPage();
    if (startPage === 'dashboard' && !this.theme.advisorExperience) {
      startPage = 'report';
      setCurrentPage(startPage);
    }
    if (!startPage) {
      startPage = 'map';
      setCurrentPage(startPage);
    }

    this.state = {
        page: startPage,
        langShow: false,
        hideSkipLink: true
    };

    this.T = props.t;

    this.onSwitchLanguage = this.props.onSwitchLanguage || function(lang) {};
    this._onPageChange = this.props.onPageChange || function(page) {};

    this.onPageChange = (page) => {
      if (getCurrentPage() !== page) {
        setCurrentPage(page);
        document.body.className = "customer-page " + this.theme.className;
      }
      this.setState({ mobileShowSidebar: false, page });
      this._onPageChange(page);
    }

    window.gotoPage = (page) => {
      this.onPageChange(page);
    }

    if (isLoggedIn()) {
      props.dispatch(getCustomerBuildings(true));
    }
    else {
      props.dispatch(getBuildingRequest());
      props.dispatch(getBuildingRequestLM());
    }
  }

  componentDidMount() {
    if (this.props.getCallbackRefs) {
      this.props.getCallbackRefs({
        openLoginModal: () => { this.b2cLoginSignIn(); }
      });
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps) {
    document.body.className = "customer-page " + this.theme.className;
    const lang = `${this.T('hmp-lang-code')}`.toLowerCase();
    if (this.theme.langSwitchDisabled && lang !== 'en') {
      this.switchLanguage('en');
    }
      
    if (this.props.isAuthenticated && !prevProps.isAuthenticated) {
      if (!window.HOLD_LOGIN_REDIRECT) {
        setTimeout(() => {
          this.setState({ showSignin: false, mobileShowSidebar: false });
          if (!this.props.showReportAndNotif || !this.props.showDashboard) {
            this.setState({ page: 'map', rand: Math.random() });
            window.location.href = '#/public/homeowner-map';
          }
          else if (this.theme.advisorExperience) {
            window.location.href = "#/app/customer/dashboard";
            this.setState({ page: 'dashboard' });
          }
          else {
            window.location.href = "#/app/customer/view-report";
            this.setState({ page: 'report' });
          }
        }, 1);
      }
    }
    if (this.props.getCallbackRefs) {
      this.props.getCallbackRefs({
        openLoginModal: () => { this.b2cLoginSignIn(); }
      });
    }
  }

  doLogin() {
    this.props.dispatch(loginUser({ phone: this.state.phone, pin: this.state.pin, is_customer: true }));
  }

  async doLogout() {
    this.setState({mobileShowSidebar: false});
    //if (this.theme.advisorExperience) {
      this.setState({ page: 'map', rand: Math.random() });
      setCurrentPage('map');
    //}
    await this.props.dispatch(logoutCustomer());
    await this.props.dispatch(clearBuildingRequest());
  }

  b2cLoginSignIn() {
    localStorage.setItem('last_login_start_from', 'normal');
    const langCode = `${this.T('hmp-lang-code')}`.toLowerCase();
	  let tok = `${window.location.href}`.split("#")[0].split('/');
    tok.length = Math.min(tok.length, 3);
    const redirect_uri = tok.join('/');
    const url = `https://${config.b2cDomain}/climativetest.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_SIGNIN&client_id=c37bab30-9b29-41a7-972b-e09ec1a0e7e6&nonce=defaultNonce&redirect_uri=${encodeURIComponent(redirect_uri)}&scope=openid&response_type=id_token&prompt=login&theme=${this.theme.b2cThemeId||''}&ui_locales=${langCode}`;
    window.open(url, '_blank').focus();
    this.setState({ b2cLoginWait: true });

    window.B2C_LOGIN_WAIT_INTERVAL = window.setInterval(async () => {
      if (window.localStorage.getItem('b2c_token')) {
        window.clearInterval(window.B2C_LOGIN_WAIT_INTERVAL);
        let token = window.localStorage.getItem('b2c_token');
        window.localStorage.removeItem('b2c_token');
        window.localStorage.removeItem('b2c_error');
        await this.props.dispatch(loginUser({ token, is_b2c: true, is_customer: true }));
        await this.props.dispatch(getCustomerBuildings(true));
        this.setState({ b2cLoginWait: false, rand: Math.random(), showSignin: false, mobileShowSidebar: false });
        if (this.props.showDashboard && this.theme.advisorExperience) {
          window.gotoPage('dashboard');
        }
        else if (!this.theme.advisorExperience && this.props.showReport) {
          window.gotoPage('report');
        }
      }
      else if (window.localStorage.getItem('b2c_error')) {
        window.clearInterval(window.B2C_LOGIN_WAIT_INTERVAL);
        window.localStorage.removeItem('b2c_token');
        window.localStorage.removeItem('b2c_error');
        toast.error(this.T('Unable to login with 3rd party service.'));
        this.setState({ b2cLoginWait: false, rand: Math.random() });
      }
    }, 1000);
  }

  switchLanguage(lang) {
    i18next.changeLanguage(lang);
    this.onSwitchLanguage(lang);
  }

  onRadioKeydown(e){
    if(e.key == 'ArrowDown'){
      e.preventDefault();
      const next = e.target.nextElementSibling;
      if(next){
        next.focus();
        next.click();
      }
    } else if(e.key == 'ArrowUp'){
      e.preventDefault();
      const prev = e.target.previousElementSibling;
      if(prev){
        prev.focus();
        prev.click();
      }
    }
  }

  render() {

    const lang = `${this.T('hmp-lang-code')}`.toLowerCase();
    const advisorExperience = this.theme.advisorExperience;

    let eaShowReport = !advisorExperience || this.props.hasPublishedReport;

    // Redirect to report page if there is an un-viewed report ready
    if (eaShowReport && (this.props.showPublishedPreReport || this.props.showPublishedPostReport) && this.state.page !== 'report' && !window.VIEWED_REPORT) {
      setTimeout(() => {
        this.setState({ page: 'report', rand: Math.random() });
        window.location.href = "#/app/customer/view-report";
        window.VIEWED_REPORT = true;
      }, 1);
    }

    document.documentElement.lang = `${lang}-ca`;

    return (
      <div className={'public-header-cont ' + (this.props.currentUserIsSupportAgent ? 'has-sa-tools' : '')} id='ph-header-cont' onClick={(e) => {
        e = e || window.event;
        let found = false;
        let node = e.target;
        while (node) {
          if (node.className && node.className.indexOf('view-cont') >= 0) {
            found = true;
            break;
          }
          node = node.parentNode;
        }
        if (found) {
          this.setState({ mobileShowSidebar: false });
        }
        if (this.props.onClick) {
          return this.props.onClick(e);
        }
      }}>
        <div className={'loading ' + (this.state.b2cLoginWait ? 'loading-show' : 'loading-hide')}><img alt={this.T("Loading Animation")} src='/images/loading-transparent.gif'/><div className="loading-claiming-msg">{this.T("Waiting for verification ...")}</div></div>
        {this.props.currentUserIsSupportAgent ? <div className='sa-tools-header'>
          <SupportAgentPage headerVersion={true} />
        </div> : undefined}
        <header className='public-header'>

          <button
          onClick={() => {
            document.getElementById('ph-view-cont').setAttribute('tabindex', '-1');
            document.getElementById('ph-view-cont').focus();
          }}
          onFocus={() => this.setState({ hideSkipLink: false })}
          onBlur={() => this.setState({ hideSkipLink: true })}
          className={this.state.hideSkipLink?'sr-only':'ph-text'}>{this.T("Skip to main Content")}</button>

          <img className='ph-org-logo' alt={this.theme.header.alt[lang]} src={this.theme.header.image} />
          <div className='ph-text'>{this.theme.header.title ? this.theme.header.title[lang] : this.T("Energy Navigator")}</div>
          <div className='ph-right'>
          {/*this.props.currentUserIsSupportAgent && <button className={'ph-btn ' + (this.state.page === 'support-agent' ? 'phb-selected' : '')} onClick={() => { this.setState({ page: 'support-agent', mobileShowSidebar: false }); this.onPageChange('support-agent')}}><img alt={this.state.page === 'support-agent' ? this.T('Selected support agent tools') : this.T('Unselected support agent tools')} src={this.state.page === 'support-agent' ? "/images/ph-support-agent-icon-sel.svg" : "/images/ph-support-agent-icon.svg"}/> {this.T('SA Tools')} {this.props.impersonateeInitials}</button>*/}
            <button role='link' aria-current={this.state.page === 'map'? 'page' : 'false'} className={'ph-btn ' + (this.state.page === 'map' ? 'phb-selected' : '')} onClick={() => { this.setState({ page: 'map', mobileShowSidebar: false  }); this.onPageChange('map')}}><img alt={''} src={this.state.page === 'map' ? "/images/ph-map-icon-sel.svg" : "/images/ph-map-icon.svg"}/> {this.T("Map")}</button>
            {advisorExperience && this.props.showDashboard ? <button role='link' aria-current={this.state.page === 'dashboard'? 'page' : 'false'} className={'ph-btn phm-btn-wide ' + (this.state.page === 'dashboard' ? 'phb-selected' : '')} onClick={() => { this.setState({ page: 'dashboard', mobileShowSidebar: false  }); this.onPageChange('dashboard')}}><img alt={this.state.page === 'dashboard' ? this.T('Dashboard Selected') : this.T('Dashboard Unselected')} src={this.state.page === 'dashboard' ? "/images/ph-dashboard-icon-sel.svg" : "/images/ph-dashboard-icon.svg"}/> {this.T("Dashboard")}</button> : undefined}
            {(!advisorExperience || eaShowReport) && this.props.showReport ? <button role='link' aria-current={this.state.page === 'report'? 'page' : 'false'} className={'ph-btn ' + (this.state.page === 'report' ? 'phb-selected' : '')} onClick={() => { this.setState({ page: 'report', mobileShowSidebar: false  }); this.onPageChange('report')}}><img alt={this.state.page === 'report' ? this.T('Report Selected') : this.T('Report Unselected')} src={this.state.page === 'report' ? "/images/ph-report-icon-sel.svg" : "/images/ph-report-icon.svg"}/> {this.T("Report")}</button> : undefined}
            {this.props.isAuthenticated && this.props.showNotif && <button className={'ph-btn ph-btn-narrow ' + (this.state.page === 'notif' ? 'phb-selected' : '')} onClick={() => { this.setState({ page: 'notif', mobileShowSidebar: false }); this.onPageChange('notif')}}><img alt={this.state.page === 'map' ? this.T('Selected Notifications') : this.T('Unselected Notifications')} src={this.state.page === 'notif' ? "/images/ph-notif-icon-sel.svg" : "/images/ph-notif-icon.svg"}/></button>}
            {this.props.isAuthenticated && <button className={'ph-btn ph-btn-narrow ' + (this.state.page === 'settings' ? 'phb-selected' : '')} onClick={() => { this.setState({ page: 'settings', mobileShowSidebar: false }); this.onPageChange('settings')}}><img alt={this.state.page === 'map' ? this.T('Selected Settings') : this.T('Unselected Settings')} src={this.state.page === 'settings' ? "/images/ph-settings-icon-sel.svg" : "/images/ph-settings-icon.svg"}/></button>}
            {!this.props.isAuthenticated && <button className='ph-signin-btn' onClick={() => this.b2cLoginSignIn()}><img alt="" src="/images/ph-signin-icon.svg"/> {this.T("Sign  In")}</button>}
            {this.props.isAuthenticated && <button className='ph-signin-btn' onClick={() => this.doLogout()}><img alt="" src="/images/ph-signout-icon.svg"/> {this.T("Sign Out")}</button>}
            <div className='ph-lang-switcher' onBlur={(e) => onBlurOutside(e, () => this.setState({ langShow: false }))}>
              <button className='phls-btn' aria-label={this.T('Change Language') + ' (' + (this.T('hmp-lang-code') === 'EN' ? 'English Selected' : 'French Selected') + ')'} aria-expanded={this.state.langShow} onClick={() => { if (!this.theme.langSwitchDisabled) { this.setState({langShow: !this.state.langShow}) } }}><img alt="" src="/images/lang-icon.svg"/>{this.T('hmp-lang-code')}</button>
              <div role='radiogroup' className={'phls-dropdown ' + (this.state.langShow ? 'phls-dd-show' : '')}>
                <button role="radio" tabIndex={this.T('hmp-lang-code') === 'EN' ? 0 : -1} onKeyDown={(e) => this.onRadioKeydown(e)} aria-checked={this.T('hmp-lang-code') === 'EN' ? "true" : "false"} className={'phlsdd-lang-option ' + (this.T('hmp-lang-code') === 'EN' ? 'phlsdd-lang-selected' : '')} onClick={() => this.switchLanguage('en')}><img alt={''} src={this.T('hmp-lang-code') === 'EN' ? "/images/hmp-radio-checked.svg" : "/images/hmp-radio-unchecked.svg" }/> English (EN)</button>
                <button role="radio" tabIndex={this.T('hmp-lang-code') === 'FR' ? 0 : -1} onKeyDown={(e) => this.onRadioKeydown(e)} aria-checked={this.T('hmp-lang-code') === 'EN' ? "false" : "true"} className={'phlsdd-lang-option ' + (this.T('hmp-lang-code') === 'FR' ? 'phlsdd-lang-selected' : '')} onClick={() => this.switchLanguage('fr')}><img alt={''} src={this.T('hmp-lang-code') === 'FR' ? "/images/hmp-radio-checked.svg" : "/images/hmp-radio-unchecked.svg" }/> Français (FR)</button>
              </div>
            </div>
          </div>
        </header>
        <div className={'public-header-mobile ' + (this.state.mobileShowSidebar ? 'psb-show' : '')}>
          <button aria-expanded={this.state.mobileShowSidebar ? "true" : "false"} className="phm-menu-btn" onClick={() => this.setState({mobileShowSidebar: !this.state.mobileShowSidebar})}><img alt={this.T("Side Menu")} src="/images/phm-menu-btn.svg" /></button>
          <img className='phm-org-logo' alt={this.theme.header.alt[lang]} src={this.theme.header.image} />
          <div className='phm-text'>{this.T("Energy Navigator")}</div>
        </div>
        <div role='dialog' aria-modal='true' tabIndex={-1} className={'public-sidebar-mobile ' + (this.state.mobileShowSidebar ? 'psb-show' : '')}>
          <button role='link' aria-current={this.state.page === 'map' ? "page" : "false"} tabIndex={this.state.mobileShowSidebar ? 0 : -1} className={'phm-btn ' + (this.state.page === 'map' ? 'phmb-selected' : '')} onClick={() => { this.setState({ page: 'map', mobileShowSidebar: false  }); this.onPageChange('map')}}><img alt={this.state.page === 'map' ? this.T('Selected Map') : this.T('Unselected Map')} src={this.state.page === 'map' ? "/images/ph-map-icon-sel.svg" : "/images/ph-map-icon.svg"}/> {this.T("Map")}</button>
          {advisorExperience && this.props.showDashboard ? <button role='link' aria-current={this.state.page === 'dashboard' ? "page" : "false"} tabIndex={this.state.mobileShowSidebar ? 0 : -1} className={'phm-btn ' + (this.state.page === 'dashboard' ? 'phmb-selected' : '')} onClick={() => { this.setState({ page: 'dashboard', mobileShowSidebar: false  }); this.onPageChange('dashboard') }}><img alt={this.state.page === 'dashboard' ? this.T('Selected Dashboard') : this.T('Unselected Dashboard')} src={this.state.page === 'dashboard' ? "/images/ph-dashboard-icon-sel.svg" : "/images/ph-dashboard-icon.svg"}/> {this.T("Dashboard")}</button> : undefined}
          {(!advisorExperience || eaShowReport) && this.props.showReport ? <button role='link' aria-current={this.state.page === 'report' ? "page" : "false"} tabIndex={this.state.mobileShowSidebar ? 0 : -1} className={'phm-btn ' + (this.state.page === 'report' ? 'phmb-selected' : '')} onClick={() => { this.setState({ page: 'report', mobileShowSidebar: false  }); this.onPageChange('report') }}><img alt={this.state.page === 'report' ? this.T('Selected Report') : this.T('Unselected Report')} src={this.state.page === 'report' ? "/images/ph-report-icon-sel.svg" : "/images/ph-report-icon.svg"}/> {this.T("Report")}</button> : undefined}
          {this.props.isAuthenticated && this.props.showNotif && <button tabIndex={this.state.mobileShowSidebar ? 0 : -1} className={'phm-btn ' + (this.state.page === 'notif' ? 'phmb-selected' : '')} onClick={() => { this.setState({ page: 'notif', mobileShowSidebar: false  }); this.onPageChange('notif') }}><img alt={this.state.page === 'map' ? this.T('Selected Notifications') : this.T('Unselected Notifications')} src={this.state.page === 'notif' ? "/images/ph-notif-icon-sel.svg" : "/images/ph-notif-icon.svg"}/> {this.T("Notifications")}</button>}
          {this.props.isAuthenticated && <button tabIndex={this.state.mobileShowSidebar ? 0 : -1} className={'phm-btn ' + (this.state.page === 'settings' ? 'phmb-selected' : '')} onClick={() => { this.setState({ page: 'settings', mobileShowSidebar: false  }); this.onPageChange('settings') }}><img alt={this.state.page === 'map' ? this.T('Selected Settings') : this.T('Unselected Settings')} src={this.state.page === 'settings' ? "/images/ph-settings-icon-sel.svg" : "/images/ph-settings-icon.svg"}/> {this.T("Settings")}</button>}
          {/*this.props.currentUserIsSupportAgent && <button tabIndex={this.state.mobileShowSidebar ? 0 : -1} className={'phm-btn ' + (this.state.page === 'support-agent' ? 'phmb-selected' : '')} onClick={() => { this.setState({ page: 'support-agent', mobileShowSidebar: false  }); this.onPageChange('support-agent') }}><img alt={this.state.page === 'map' ? this.T('Selected support agent') : this.T('Unselected support agent')} src={this.state.page === 'support-agent' ? "/images/ph-support-agent-icon-sel.svg" : "/images/ph-support-agent-icon.svg"}/> {this.T("SA Tools")} {this.props.impersonateeInitials}</button>*/}
          <div className='phm-sb-bottom'>
            <div className='phm-sb-sep'></div>
            {!this.props.isAuthenticated && <button tabIndex={this.state.mobileShowSidebar ? 0 : -1} className='phm-btn' onClick={() => this.b2cLoginSignIn()}><img alt="" src="/images/ph-signin-icon.svg"/> {this.T("Sign In")}</button>}
            {this.props.isAuthenticated && <button className='phm-btn' onClick={() => this.doLogout()}><img alt="" src="/images/ph-signout-icon.svg"/> {this.T("Sign Out")}</button>}
            <div className='phm-lang-switcher' onFocus={() => {if (!this.theme.langSwitchDisabled) this.setState({langShow: true})}} onBlur={(e) => onBlurOutside(e, () => this.setState({ langShow: false }))}>
              <button tabIndex={this.state.mobileShowSidebar ? 0 : -1} className='phls-btn' onClick={() => {if (!this.theme.langSwitchDisabled) this.setState({langShow: true})}}><img alt="" className='lang-icon' src="/images/lang-icon.svg"/>{this.T('hmp-lang-code') === 'EN' ? 'ENGLISH' : 'FRANÇAIS' }<img className='arrow-right' src='/images/arrow-right-white.svg' /></button>
              <div className={'phls-dropdown ' + (this.state.langShow ? 'phls-dd-show' : '')}>
                <button tabIndex={this.state.mobileShowSidebar ? 0 : -1} role="radio" aria-checked={this.T('hmp-lang-code') === 'EN' ? "true" : "false"} className={'phlsdd-lang-option ' + (this.T('hmp-lang-code') === 'EN' ? 'phlsdd-lang-selected' : '')} onClick={() => this.switchLanguage('en')}><img alt={this.T('hmp-lang-code') === 'EN' ? this.T("Selected") : this.T("Unselected")} src={this.T('hmp-lang-code') === 'EN' ? "/images/hmp-radio-checked.svg" : "/images/hmp-radio-unchecked.svg" }/> English (EN)</button>
                <button tabIndex={this.state.mobileShowSidebar ? 0 : -1} role="radio" aria-checked={this.T('hmp-lang-code') === 'EN' ? "false" : "true"} className={'phlsdd-lang-option ' + (this.T('hmp-lang-code') === 'FR' ? 'phlsdd-lang-selected' : '')} onClick={() => this.switchLanguage('fr')}><img alt={this.T('hmp-lang-code') === 'FR' ? this.T("Selected") : this.T("Unselected")} src={this.T('hmp-lang-code') === 'FR' ? "/images/hmp-radio-checked.svg" : "/images/hmp-radio-unchecked.svg" }/> Français (FR)</button>
              </div>
            </div>
          </div>
        </div>
        <main id='ph-view-cont' className={this.theme.className + ' view-cont ' + (this.state.mobileShowSidebar ? 'psb-show' : '')}>{this.props.children}</main>
      </div>
    )
  }
}

////

function mapStateToProps(state) {
  let building = state.buildings.curBuilding.building || {};
  let status = { main: building.highestAuditStatus || 1, sub: building.highestAuditSubStatus || 0 };
  let hasPublishedReport = E1Statuses.statusGtEqual(status, E1Statuses.STATUSES.PRE_PREVIEW_PUBLISHED);
  let showPublishedPreReport = E1Statuses.statusGtEqual(status, E1Statuses.STATUSES.PRE_PREVIEW_PUBLISHED) && !building.homeownerViewedPrePublishedReport;
  let showPublishedPostReport = !showPublishedPreReport && E1Statuses.statusGtEqual(status, E1Statuses.STATUSES.POST_PREVIEW_PUBLISHED) && !building.homeownerViewedPostPublishedReport;
	return {
    showDashboard: (state.auth.customerBuildings && state.auth.customerBuildings.length > 0) && isLoggedIn(),
    showNotif: (state.auth.customerBuildings && state.auth.customerBuildings.length > 0) && isLoggedIn(),
    showReport: true,
    isAuthenticated: !!state.auth?.currentUser,
    authError: state.auth?.errorMessage,
    hasPublishedReport,
    status,
    showPublishedPostReport,
    showPublishedPreReport,
    hasBuilding: !!state.auth?.currentUser?.primaryBuildingId,
    currentUserIsSupportAgent: state.auth.currentUser?.impersonatorAccess,
    impersonateeInitials: state.auth.currentUser?.impersonatorToken ? '(' + state.auth.currentUser.firstName.charAt(0) + state.auth.currentUser.lastName.charAt(0) + ')' : ''
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(PublicHeader)));
